// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateSelector-module-templateSelector {
	min-width: 70vw;
	max-width: 80vw;
	margin: 0 auto;
	padding: 20px;
}

.TemplateSelector-module-mainTitle {
	text-align: left;
	font-size: 2rem;
	font-weight: bold;
	margin-top: 6rem;
	margin-bottom: 2rem;
	font-family: "BoldFont";
	color: #16013e;
}

.TemplateSelector-module-subtitle {
	text-align: center;
	font-size: 1.5rem;
	margin-bottom: 2rem;
}

.TemplateSelector-module-templateGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, 325px);
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 1rem;
}

.TemplateSelector-module-templateItem {
	/* border: 1px solid #ddd; */

	border: none;
	border-radius: 8px;
	padding: 4px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.TemplateSelector-module-templateItem:hover {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.TemplateSelector-module-templateImage {
	width: 100%;
	height: auto;
	border-radius: 8px;
}

.TemplateSelector-module-templateName {
	margin-top: 10px;
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styleModules/TemplateSelector.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,eAAe;CACf,cAAc;CACd,aAAa;AACd;;AAEA;CACC,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,gBAAgB;CAChB,mBAAmB;CACnB,uBAAuB;CACvB,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,8CAA8C;CAC9C,uBAAuB;CACvB,mBAAmB;CACnB,SAAS;CACT,gBAAgB;AACjB;;AAEA;CACC,4BAA4B;;CAE5B,YAAY;CACZ,kBAAkB;CAClB,YAAY;CACZ,eAAe;CACf,yBAAyB;AAC1B;;AAEA;CACC,uCAAuC;AACxC;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;AACnB","sourcesContent":[".templateSelector {\n\tmin-width: 70vw;\n\tmax-width: 80vw;\n\tmargin: 0 auto;\n\tpadding: 20px;\n}\n\n.mainTitle {\n\ttext-align: left;\n\tfont-size: 2rem;\n\tfont-weight: bold;\n\tmargin-top: 6rem;\n\tmargin-bottom: 2rem;\n\tfont-family: \"BoldFont\";\n\tcolor: #16013e;\n}\n\n.subtitle {\n\ttext-align: center;\n\tfont-size: 1.5rem;\n\tmargin-bottom: 2rem;\n}\n\n.templateGrid {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(auto-fit, 325px);\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 20px;\n\tmargin-top: 1rem;\n}\n\n.templateItem {\n\t/* border: 1px solid #ddd; */\n\n\tborder: none;\n\tborder-radius: 8px;\n\tpadding: 4px;\n\tcursor: pointer;\n\ttransition: all 0.3s ease;\n}\n\n.templateItem:hover {\n\tbox-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.templateImage {\n\twidth: 100%;\n\theight: auto;\n\tborder-radius: 8px;\n}\n\n.templateName {\n\tmargin-top: 10px;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateSelector": `TemplateSelector-module-templateSelector`,
	"mainTitle": `TemplateSelector-module-mainTitle`,
	"subtitle": `TemplateSelector-module-subtitle`,
	"templateGrid": `TemplateSelector-module-templateGrid`,
	"templateItem": `TemplateSelector-module-templateItem`,
	"templateImage": `TemplateSelector-module-templateImage`,
	"templateName": `TemplateSelector-module-templateName`
};
export default ___CSS_LOADER_EXPORT___;
