import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { BsStars } from "react-icons/bs";
import { FiCheck, FiTrash2, FiUploadCloud } from "react-icons/fi/index.esm.js";
import { IoClose } from "react-icons/io5";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { MdAttachFile, MdOutlineAssignment } from "react-icons/md";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import styles from "../../styleModules/generateStyles.module.css";
import s from "../../styleModules/setupStyles.module.css";
import s2 from "../../styleModules/modalStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import ModalWrapper from "../SecondaryComponents/ModalWrapper.js";
import circles from "../../images/circles.png";
import { ProductTourModal } from "./SetupElements/SetupModals.js";
import { getAssistTourContent } from "../UtilityFunctions/getProductTourContent.js";
import { useNavigate } from "react-router-dom";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function ResumeUpload({ type, skipModal, setup_id }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const {
		user,
		resumeData,
		setResumeData,
		coverLetterData,
		setCoverLetterData,
		setupData,
		setSetupData,
		userLanguage,
		translateDictText,
		performTranslation
	} = useContext(AppContext);

	const navigate = useNavigate();

	const resumeCreatorFlag = useFeatureFlagEnabled("resume-creator");

	const LocalLanguageDict = {
		ClickToUpload: "Click to upload",
		DragAndDropHintText: " or drag and drop file here",
		SupportedFileTypes: "Supported file types are ",
		Or: "OR",
		Resume: "Resume",
		CoverLetter: "Cover Letter",
		Create: "Create",
		ResumeSummaryPlaceholder:
			"Motivated Sales Associate with over 3 years of experience in retail environments. Proven track record of exceeding sales targets and delivering exceptional customer service. Seeking to leverage my skills and experience to contribute to the success of ABC Retail in Toronto.",
		ResumeExperiencePlaceholder:
			"Sales Associate at XYZ Store, Toronto (2019-Present):\n- Increased sales by 15% through personalized customer service and product recommendations.\n- Trained and mentored new employees, improving team performance by 20%.\n- Managed inventory and restocked shelves, ensuring product availability.",
		ResumeExperience2Placeholder:
			"Retail Assistant at DEF Shop, Vancouver (2017-2019):\n- Assisted in achieving monthly sales goals by providing excellent customer service.\n- Maintained store cleanliness and organized merchandise displays.\n- Handled cash transactions and processed returns efficiently.",
		ResumeSkillsPlaceholder: ["- Customer service\n- Sales techniques\n- Team collaboration\n- Inventory management"],
		ResumeEducationPlaceholder: "Diploma in Business Administration, University of British Columbia, Vancouver (2015-2017)",
		ResumeVolunteerPlaceholder:
			"Volunteer at Toronto Food Bank (2020-Present):\n- Organized and distributed food supplies to over 100 families weekly.\n- Coordinated volunteer schedules and tasks, ensuring smooth operations.",
		ResumeExtracurricularPlaceholder:
			"Member of University of British Columbia Debate Team (2016-2017):\n- Participated in national debate competitions, achieving top 10 rankings.\n- Led team practices and mentored new members.",
		ResumeCoverLetterIntroPlaceholder:
			"Dear Hiring Manager,\n\nI am writing to express my interest in the Sales Associate position at ABC Retail in Toronto. With over three years of experience in retail sales and a proven ability to exceed targets, I am excited about the opportunity to contribute to your team.",
		ResumeCoverLetterBody1Placeholder:
			"In my current role at XYZ Store in Toronto, I have consistently exceeded sales targets by leveraging my strong customer service skills and deep product knowledge. For example, I increased sales by 15% in the last quarter by tailoring my approach to meet individual customer needs.",
		ResumeCoverLetterBody2Placeholder:
			"I am particularly impressed by ABC Retail's commitment to providing exceptional customer experiences and believe my skills in personalized service and sales techniques would be a great fit for your team. Additionally, my experience in inventory management and team collaboration will enable me to contribute effectively to your operations.",
		ResumeSummaryTips1: "Be concise: Summarize career goals, key skills in 2-3 sentences.",
		ResumeSummaryTips2: "Highlight achievements: Mention notable accomplishments.",
		ResumeSummaryTips3: "Tailor to job: Customize to job description and company values."
	};

	const LocalLanguageDict2 = {
		ResumeExperienceTips1: "Use action verbs: Start with strong verbs (e.g., managed, developed).",
		ResumeExperienceTips2: "Quantify achievements: Use numbers to show impact (e.g., increased sales by 20%).",
		ResumeExperienceTips3: "Focus on relevance: Include job-relevant experiences.",
		ResumeSkillsTips1: "Mix hard and soft skills: List technical and interpersonal skills.",
		ResumeSkillsTips2: "Prioritize relevance: Focus on skills in the job description.",
		ResumeSkillsTips3: "Use bullet points: Keep it clear and easy to read.",
		ResumeEducationTips1: "List in reverse order: Start with most recent education.",
		ResumeEducationTips2: "Include details: Degree, major, institution, date.",
		ResumeEducationTips3: "Highlight: Include honors and relevant coursework.",
		ResumeVolunteerTips1: "Include roles: Mention your volunteer positions.",
		ResumeVolunteerTips2: "Highlight impact: Describe your contributions and achievements.",
		ResumeVolunteerTips3: "Be relevant: Focus on volunteer work related to the job.",
		ResumeExtracurricularTips1: "Mention activities: List clubs, sports, and organizations.",
		ResumeExtracurricularTips2: "Show leadership: Highlight any leadership roles.",
		ResumeExtracurricularTips3: "Be concise: Keep descriptions brief and focused.",
		ResumeCoverLetterIntroTips1: "State your purpose: Clearly mention the job you're applying for.",
		ResumeCoverLetterIntroTips2: "Engage the reader: Start with a strong, compelling sentence.",
		ResumeCoverLetterIntroTips3: "Show enthusiasm: Express genuine interest in the role and company.",
		ResumeCoverLetterBodyTips1: "Match your skills: Align your skills and experiences with the job requirements.",
		ResumeCoverLetterBodyTips2: "Provide examples: Use specific examples to demonstrate your qualifications.",
		ResumeCoverLetterBodyTips3: "Be concise: Keep paragraphs short and focused on key points.",
		ResumeUploadModalNote: "Fill in the details below and click 'Confirm' to create a",
		ResumeSummary: "Summary",
		ResumeExperience: "Experience",
		ResumeSkills: "Skills",
		ResumeEducation: "Education",
		ResumeVolunteer: "Volunteer",
		ResumeExtracurricular: "Extracurricular",
		Confirm: "Confirm",
		CoverLetterIntro: "Intro",
		CoverLetterBody: "Body",
		ResumeUploadModalNote2: "Please wait a moment while we process your file. Do not close this window.",
		CVIntro: "Intro",
		CVBody: "Body",
		Preparing: "Preparing",
		Analyzing: "Analyzing",
		Generating: "Generating",
		Uploading: "Uploading",
		ResumeDetails: "Resume Details",
		CoverLetterDetails: "Cover Letter Details",
		ResumeCreator: "Resume Creator",
		QuickStart: "Quick Start",
		ChooseOption: "Choose Option"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [pageText2, setPageText2] = useState({
		...LocalLanguageDict2
	});

	const [file, setFile] = useState(null);
	const [showUploadModal, setShowUploadModal] = useState(false);

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
			await performTranslation(pageText2, setPageText2);
		};
		translateText();
		// console.log("Translating");

		// console.log("Changes");
	}, [userLanguage, user, file, resumeData, showUploadModal, setupData]);

	// useEffect(() => {}, [pageText, pageText2]);

	const posthog = usePostHog();

	const queryParams = new URLSearchParams(window.location.search); // Search for any user_id query parameters in the URL
	const user_id = queryParams.get("user_id");
	const resume_user = user_id || user?.id; // If no user_id query parameter is found, use the current user's ID
	const fileInputId = `file-input-${type}`;

	const [tempResumeData, setTempResumeData] = useState({
		[pageText.ResumeSummaryPlaceholder]: "",
		[pageText.ResumeExperiencePlaceholder]: "",
		[pageText.ResumeSkillsPlaceholder]: "",
		[pageText.ResumeEducationPlaceholder]: ""
	});
	const [tempCoverLetterData, setTempCoverLetterData] = useState({ intro: "", body: "" });
	const resumePlaceholders = {
		[pageText.ResumeSummary]: pageText.ResumeSummaryPlaceholder,
		[pageText.ResumeExperience]: [pageText.ResumeExperiencePlaceholder, pageText.ResumeExperience2Placeholder],
		[pageText.ResumeSkills]: pageText.ResumeSkillsPlaceholder,
		[pageText.ResumeEducation]: pageText.ResumeEducationPlaceholder,
		[pageText.ResumeVolunteer]: pageText.ResumeVolunteerPlaceholder,
		[pageText.ResumeExtracurricular]: pageText.ResumeExtracurricularPlaceholder
	};
	const coverLetterPlaceholders = {
		intro: pageText.ResumeCoverLetterIntroPlaceholder,
		body: [pageText.ResumeCoverLetterBody1Placeholder, pageText.ResumeCoverLetterBody2Placeholder]
	};
	const resumeTips = {
		summary: [pageText.ResumeSummaryTips1, pageText.ResumeSummaryTips2, pageText.ResumeSummaryTips3],
		experience: [pageText2.ResumeExperienceTips1, pageText2.ResumeExperienceTips2, pageText2.ResumeExperienceTips3],
		skills: [pageText2.ResumeSkillsTips1, pageText2.ResumeSkillsTips2, pageText2.ResumeSkillsTips3],
		education: [pageText2.ResumeEducationTips1, pageText2.ResumeEducationTips2, pageText2.ResumeEducationTips3],
		volunteer: [pageText2.ResumeVolunteerTips1, pageText2.ResumeVolunteerTips2, pageText2.ResumeVolunteerTips3],
		extracurricular: [pageText2.ResumeExtracurricularTips1, pageText2.ResumeExtracurricularTips2, pageText2.ResumeExtracurricularTips3]
	};
	const coverLetterTips = {
		intro: [pageText2.ResumeCoverLetterIntroTips1, pageText2.ResumeCoverLetterIntroTips2, pageText2.ResumeCoverLetterIntroTips3],
		body: [pageText2.ResumeCoverLetterBodyTips1, pageText2.ResumeCoverLetterBodyTips2, pageText2.ResumeCoverLetterBodyTips3]
	};

	const [showGenerateModal, setShowGenerateModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadError, setUploadError] = useState(false);
	const [uploadErrorMessage, setUploadErrorMessage] = useState("");
	const [uploadProgressText, setUploadProgressText] = useState(pageText2.Uploading);
	const [showPTModal, setShowPTModal] = useState(false);
	const [showResumeCreatorOptions, setShowResumeCreatorOptions] = useState(false);

	const saveDetailsToDB = async (data) => {
		const saveData = {
			instage_id: resume_user,
			setup_id,
			resume_details: type === "resume" ? data : resumeData,
			cover_letter_details: type === "cover" ? data : coverLetterData
		};

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/create`, saveData, 1);

			if (type === "resume") {
				posthog?.capture("instage_resume_save", { setup_id, instage_id: resume_user });
			} else {
				posthog?.capture("instage_cover_letter_save", { setup_id, instage_id: resume_user });
			}

			setResumeData(response.data.resume_details);
			setCoverLetterData(response.data.cover_letter_details);
		} catch (error) {
			console.error("Error saving resume details:", error);
		}
	};

	const updateDetailsInDB = async (data) => {
		const updateData = {
			setup_id,
			instage_id: resume_user,
			resume_details: type === "resume" ? data : null,
			cover_letter_details: type === "cover" ? data : null
		};

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/update`, updateData, 1);
			console.log("response", response);
			if (type === "resume") {
				posthog?.capture("instage_resume_update", { setup_id, instage_id: resume_user });
				setResumeData(updateData.resume_details);
			} else {
				posthog?.capture("instage_cover_letter_update", { setup_id, instage_id: resume_user });
				setCoverLetterData(updateData.cover_letter_details);
			}
		} catch (error) {
			console.error("Error updating details:", error);
		}
	};

	const handleFileUpload = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		const upload = event.target.files ? event.target.files[0] : event.dataTransfer.files[0];

		// Check if the file is a PDF, DOCX, JPEG, PNG, or TIFF file
		if (
			upload.type !== "application/pdf" &&
			upload.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
			upload.type !== "image/jpeg" &&
			upload.type !== "image/png" &&
			upload.type !== "image/tiff"
		) {
			alert("Only PDF, DOCX, JPEG, PNG, and TIFF files are allowed. Please select a valid file to upload.");
			return; // Abort the upload process
		}
		if (file !== null) {
			setShowUploadModal(false);
			return;
		}

		setFile(upload);

		if (!skipModal) {
			setShowUploadModal(true);
		}

		const formData = new FormData();
		formData.append("file", upload);
		formData.append("type", type);

		// Send formData to the backend
		try {
			const response = await axiosLimitedPost(
				`${url}/api/resume-assist/upload`,
				formData,
				1,
				{
					headers: { "Content-Type": "multipart/form-data" }
				},
				60000
			);

			if (type === "resume") {
				posthog?.capture("instage_resume_upload", { setup_id, instage_id: resume_user });
				// Set the local state
				setTempResumeData(response.data);
				if (!skipModal) {
					// Set the global state
					setResumeData(response.data);
				}
			} else {
				posthog?.capture("instage_cover_letter_upload", { setup_id, instage_id: resume_user });
				// Set the local state
				setTempCoverLetterData(response.data);
				if (!skipModal) {
					// Set the global state
					setCoverLetterData(response.data);
				}
			}

			if (skipModal) {
				saveDetailsToDB(response.data);
			}
		} catch (error) {
			console.error("Error uploading file:", error);
			setFile(null);
			setUploadError(true);
			setUploadErrorMessage("An error occurred while uploading your file. Please try again.");
			setShowUploadModal(true);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleInputChange = (fieldName, value) => {
		if (type === "resume") {
			setTempResumeData((prevResumeData) => ({
				...prevResumeData,
				[fieldName]: value
			}));
		} else {
			setTempCoverLetterData((prevCoverLetterData) => ({
				...prevCoverLetterData,
				[fieldName]: value
			}));
		}
	};

	const handleConfirm = async () => {
		const hasChanges =
			type === "resume"
				? JSON.stringify(tempResumeData) !== JSON.stringify(resumeData)
				: JSON.stringify(tempCoverLetterData) !== JSON.stringify(coverLetterData);
		if (hasChanges) {
			updateDetailsInDB(type === "resume" ? tempResumeData : tempCoverLetterData);
		}
	};

	// const enhanceResume = async (section) => {
	// 	const assistData = {
	// 		section,
	// 		draft: type === "resume" ? tempResumeData[section] : tempCoverLetterData[section]
	// 	};

	// 	try {
	// 		const response = await axiosLimitedPost(`${url}/api/resume-assist/resumeEnhanceSection`, assistData, 1, {}, 60000);
	// 		console.log("response.data", response.data);

	// 		setGptAssistData((prevData) => ({
	// 			...prevData,
	// 			[section]: response.data
	// 		}));
	// 	} catch (error) {
	// 		console.error("Error running GPT enhance:", error);
	// 	} finally {
	// 		// Set loading false for the specific section
	// 		setSectionRewriteLoading((prev) => ({ ...prev, [section]: false }));
	// 	}
	// };

	useEffect(() => {
		if (uploadError && !showUploadModal) {
			setUploadError(false);
			setUploadErrorMessage("");
		}
	}, [showUploadModal]);
	useEffect(() => {
		let timer1, timer2;
		if (isLoading) {
			timer1 = setTimeout(() => {
				setUploadProgressText(pageText2.Analyzing);
			}, 3000);
			timer2 = setTimeout(() => {
				setUploadProgressText(pageText2.Preparing);
			}, 6000);
		} else {
			setUploadProgressText(pageText2.Uploading);
		}
		// console.log(pageText);
		// console.log("uploadingProgressText", uploadProgressText);
		return () => {
			clearTimeout(timer1);
			clearTimeout(timer2);
		};
	}, [isLoading, pageText]);

	return (
		<>
			{showUploadModal && (
				<ModalWrapper closeModal={() => setShowUploadModal(false)}>
					<div className={`${s2.modal} ${s2.modalMaxWidth}`}>
						<img src={circles} alt="Circles" className={s2.modalCircles} />
						<div className={s2.modalHeaderIconContainer}>
							<MdOutlineAssignment className={s2.modalHeaderIcon} />
						</div>
						<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowUploadModal(false)} />
						<div className={s2.modalTextContainerFixed}>
							{!uploadError ? (
								<p className={s2.modalTitle}>{type === "resume" ? pageText2.ResumeDetails : pageText2.CoverLetterDetails}</p>
							) : (
								<p className={s2.modalTitle} style={{ color: "red" }}>
									{uploadErrorMessage}
								</p>
							)}
							{isLoading && (
								<>
									<div className={s2.modalSpinnerContainer}>
										<LoadingSpinner height={150} width={150} thickness={8} color="#00a9af" />
										<p className={s2.modalSpinnerText}>{uploadProgressText}...</p>
									</div>
									<p className={s2.modalText} style={{ margin: "1rem", textAlign: "center" }}>
										{pageText2.ResumeUploadModalNote2}
									</p>
								</>
							)}
							{!isLoading && type === "resume" && (
								<>
									<TextAreaField
										label={pageText2.ResumeSummary}
										value={tempResumeData.summary}
										onChange={(e) => handleInputChange(pageText.SummarySection, e.target.value)}
									/>
									<TextAreaField
										label={pageText2.ResumeExperience}
										value={tempResumeData.experience}
										onChange={(e) => handleInputChange("experience", e.target.value)}
									/>
									<TextAreaField
										label={pageText2.ResumeSkills}
										value={tempResumeData.skills}
										onChange={(e) => handleInputChange("skills", e.target.value)}
									/>
									<TextAreaField
										label={pageText2.ResumeEducation}
										value={tempResumeData.education}
										onChange={(e) => handleInputChange("education", e.target.value)}
									/>
									<TextAreaField
										label={pageText2.ResumeVolunteer}
										value={tempResumeData.volunteer}
										onChange={(e) => handleInputChange("volunteer", e.target.value)}
									/>
									<TextAreaField
										label={pageText2.ResumeExtracurricular}
										value={tempResumeData.extracurricular}
										onChange={(e) => handleInputChange("extracurricular", e.target.value)}
									/>
								</>
							)}
							{!isLoading && type === "cover" && (
								<>
									<TextAreaField
										label={pageText2.CoverLetterIntro}
										value={tempCoverLetterData.intro}
										onChange={(e) => handleInputChange("intro", e.target.value)}
									/>
									<TextAreaField
										label={pageText2.CoverLetterBody}
										value={tempCoverLetterData.body}
										onChange={(e) => handleInputChange("body", e.target.value)}
									/>
								</>
							)}
						</div>
						{!isLoading && (tempResumeData || tempCoverLetterData) && (
							<div className={s2.modalBtnContainer} style={{ padding: "0 25%" }}>
								<button
									type="button"
									className={s2.modalConfirmBtn}
									onClick={() => {
										handleConfirm();
										setShowUploadModal(false);
									}}
								>
									{pageText2.Confirm}
								</button>
							</div>
						)}
					</div>
				</ModalWrapper>
			)}

			{showGenerateModal && (
				<ModalWrapper closeModal={() => setShowGenerateModal(false)}>
					<div className={`${s2.modal} ${s2.modalMaxWidth}`}>
						<img src={circles} alt="Circles" className={s2.modalCircles} />
						<div className={s2.modalHeaderIconContainer}>
							<MdOutlineAssignment className={s2.modalHeaderIcon} />
						</div>
						<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowGenerateModal(false)} />
						<div className={s2.modalTextContainerFixed}>
							<p className={s2.modalTitle}>
								{pageText.Create} {type === "resume" ? pageText.Resume : pageText.CoverLetter}
							</p>
							<p className={s2.modalNote} style={{ marginBottom: "1rem" }}>
								{pageText2.ResumeUploadModalNote} {type === "resume" ? pageText.Resume : pageText.CoverLetter}
							</p>
							{!isLoading && type === "resume" && (
								<>
									<ResumeSection
										label={pageText2.ResumeSummary}
										value={tempResumeData.summary}
										onChange={(e) => handleInputChange("summary", e.target.value)}
										placeholder={pageText.ResumeSummaryPlaceholder}
										tips={resumeTips.summary}
									/>
									<ResumeSection
										label={pageText2.ResumeExperience}
										value={tempResumeData.experience}
										onChange={(e) => handleInputChange("experience", e.target.value)}
										placeholder={pageText.ResumeExperiencePlaceholder}
										tips={resumeTips.experience}
									/>
									<ResumeSection
										label={pageText2.ResumeSkills}
										value={tempResumeData.skills}
										onChange={(e) => handleInputChange("skills", e.target.value)}
										placeholder={pageText.ResumeSkillsPlaceholder}
										tips={resumeTips.skills}
									/>
									<ResumeSection
										label={pageText2.ResumeEducation}
										value={tempResumeData.education}
										onChange={(e) => handleInputChange("education", e.target.value)}
										placeholder={pageText.ResumeEducationPlaceholder}
										tips={resumeTips.education}
									/>
									<ResumeSection
										label={pageText2.ResumeVolunteer}
										value={tempResumeData.volunteer}
										onChange={(e) => handleInputChange("volunteer", e.target.value)}
										placeholder={pageText.ResumeVolunteerPlaceholder}
										tips={resumeTips.volunteer}
									/>
									<ResumeSection
										label={pageText2.ResumeExtracurricular}
										value={tempResumeData.extracurricular}
										onChange={(e) => handleInputChange("extracurricular", e.target.value)}
										placeholder={pageText.ResumeExtracurricularPlaceholder}
										tips={resumeTips.extracurricular}
									/>
								</>
							)}
							{!isLoading && type === "cover" && (
								<>
									<ResumeSection
										label={pageText2.CVIntro}
										value={tempCoverLetterData.intro}
										onChange={(e) => handleInputChange("intro", e.target.value)}
										placeholder={coverLetterPlaceholders.intro}
										tips={coverLetterTips.intro}
									/>
									<ResumeSection
										label={pageText2.CVBody}
										value={tempCoverLetterData.body}
										onChange={(e) => handleInputChange("body", e.target.value)}
										placeholder={coverLetterPlaceholders.body}
										tips={coverLetterTips.body}
									/>
								</>
							)}
						</div>
						{!isLoading && (tempResumeData || tempCoverLetterData) && (
							<div className={s2.modalBtnContainer} style={{ padding: "0 25%" }}>
								<button
									type="button"
									className={`${s2.modalConfirmBtn} ${s2.modalConfirmBtnGenerate} ${
										type === "resume" && Object.values(tempResumeData).every((value) => value === "") ? s2.modalDisabledBtn : ""
									} ${
										type === "cover" && Object.values(tempCoverLetterData).every((value) => value === "")
											? s2.modalDisabledBtn
											: ""
									}`}
									disabled={
										(type === "resume" && Object.values(tempResumeData).every((value) => value === "")) ||
										(type === "cover" && Object.values(tempCoverLetterData).every((value) => value === ""))
									}
									onClick={async () => {
										handleConfirm();
										setShowGenerateModal(false);
										setFile({ name: `Manual Entry - ${type === "resume" ? "Resume" : "Cover Letter"}` });
										if (skipModal) {
											await saveDetailsToDB(type === "resume" ? tempResumeData : tempCoverLetterData);
										} else if (type === "resume") {
											setResumeData(tempResumeData);
										} else {
											setCoverLetterData(tempCoverLetterData);
										}
									}}
								>
									{pageText2.Confirm}
								</button>
							</div>
						)}
					</div>
				</ModalWrapper>
			)}

			{showResumeCreatorOptions && type === "resume" && (
				<ModalWrapper closeModal={() => setShowResumeCreatorOptions(false)}>
					<div className={`${s2.modal} ${s2.modalMaxWidthSmall}`}>
						<img src={circles} alt="Circles" className={s2.modalCircles} />
						<p className={s2.modalTitle}>{pageText2.ChooseOption}</p>
						<div className={s2.modalHeaderIconContainer}>
							<MdOutlineAssignment className={s2.modalHeaderIcon} />
						</div>
						<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowResumeCreatorOptions(false)} />

						<div className={s2.createButtonModalContainer}>
							<button type="button" className={s2.modalConfirmBtn} onClick={() => window.open("/resume-creator", "_blank")}>
								{pageText2.ResumeCreator}
							</button>
							<button
								type="button"
								className={s2.modalConfirmBtn}
								onClick={() => {
									setShowResumeCreatorOptions(false);
									setShowGenerateModal(true);
								}}
							>
								{pageText2.QuickStart}
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}

			{!file && (
				<>
					<div className={styles.titleLabel}>
						<p className={styles.fileUploadLabel}>{type === "resume" ? `${pageText.Resume}` : `${pageText.CoverLetter}`}</p>
						<RxQuestionMarkCircled
							className={styles.infoIconHeading}
							style={{ marginLeft: "0.5rem", marginBottom: "0.5rem" }}
							onClick={() => setShowPTModal(true)}
						/>
					</div>
					{showPTModal && <ProductTourModal setShowPTModal={setShowPTModal} content={getAssistTourContent(type, false)} />}

					<div className={styles.fileSection}>
						<label htmlFor={fileInputId} className={styles.fileUpload} onDrop={handleFileUpload} onDragOver={handleDragOver}>
							<input
								type="file"
								id={fileInputId}
								onChange={(event) => {
									if (event.target.files[0].size > 10485760) {
										alert("File size exceeds 10MB");
										event.target.value = "";
									} else {
										handleFileUpload(event);
									}
								}}
								accept=".pdf, .docx, .jpeg, .png, .tiff"
								className={styles.hiddenInputField}
							/>
							<div className={styles.uploadIconContainer}>
								<FiUploadCloud className={styles.uploadIcon} />
							</div>
							<div className={styles.fileUploadTextContainer}>
								<p className={styles.fileUploadText}>
									<span style={{ color: "#00a9af", fontFamily: "BoldFont" }}>{pageText.ClickToUpload}</span>
									{pageText.DragAndDropHintText}
								</p>
								<p className={styles.fileUploadNote}>{pageText.SupportedFileTypes} .doc, .docx, .pdf, .jpeg, .png, .tiff</p>
							</div>
						</label>
						<p className={styles.fileSectionText}>{pageText.Or}</p>
						<button type="button" className={styles.fileUploadButton} onClick={() => setShowGenerateModal(true)}>
							{pageText.Create}
						</button>
						{/* <button
							type="button"
							className={styles.fileUploadButton}
							onClick={() => {
								if (resumeCreatorFlag && type === "resume") {
									setShowResumeCreatorOptions(true);
								} else {
									setShowGenerateModal(true);
								}
							}}
						>
							{pageText.Create}
						</button> */}
					</div>
				</>
			)}
			{file && (
				<>
					<div
						className={`${styles.fileUpload} ${styles.fileUploaded}`}
						onClick={() => setShowUploadModal(true)}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								setShowUploadModal(true);
							}
						}}
						role="button"
						tabIndex={0}
					>
						{(type === "resume" ? resumeData : coverLetterData) === null && (
							<div className={styles.spinnerContainerResume}>
								<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
							</div>
						)}
						{(type === "resume" ? resumeData : coverLetterData) !== null && (
							<>
								<MdAttachFile className={styles.uploadIcon} />
								{/* <div className={styles.fileUploadedText}>{type === "resume" ? "Resume" : "Cover Letter"} uploaded</div> */}
								<div className={styles.fileUploadedText}>
									{file.name}
									{/* ({`${(file.size / 1024 / 1024).toFixed(2)} MB`}) */}
								</div>
							</>
						)}
						{(type === "resume" ? resumeData : coverLetterData) !== null && (
							<button
								className={styles.fileRemove}
								type="button"
								aria-label="Remove file"
								onClick={(event) => {
									event.stopPropagation();
									setFile(null);
									if (type === "resume") {
										setResumeData(null);
										setTempResumeData({ summary: "", experience: "", skills: "", education: "" });
									} else {
										setCoverLetterData(null);
										setTempCoverLetterData({ intro: "", body: "" });
									}
								}}
							>
								<IoClose className={styles.fileRemoveIcon} />
							</button>
						)}
					</div>
				</>
			)}
		</>
	);
}

function TextAreaField({ label, value, onChange, placeholder }) {
	// const [performTranslation, userLanguage, user] = useContext(AppContext);
	// const LocalLanguageDict = {
	// 	ResumeSummary: "summary",
	// 	ResumeExperience: "experience",
	// 	ResumeSkills: "skills",
	// 	ResumeEducation: "education",
	// 	ResumeVolunteer: "volunteer",
	// 	ResumeExtracurricular: "extracurricular"
	// };

	// const [pageText, setPageText] = useState({
	// 	...LocalLanguageDict
	// });

	// // Usage
	// useEffect(() => {
	// 	const translateText = async () => {
	// 		await performTranslation(pageText, setPageText);
	// 	};
	// 	translateText();
	// 	// console.log("Changes");
	// }, [userLanguage]);

	const requiredResumeSections = ["summary", "experience", "skills", "education"];
	return (
		<div className={s2.modalTextareaContainer}>
			<h2 className={s2.modalInputLabel}>
				{label}
				{requiredResumeSections.includes(label.toLowerCase()) && <span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>}
			</h2>
			<textarea className={s2.modalTextarea} value={value} onChange={onChange} placeholder={placeholder} />
		</div>
	);
}

function ResumeSection({ label, value, onChange, placeholder, tips }) {
	return (
		<div className={s2.modalResumeSection}>
			<TextAreaField label={label} value={value} onChange={onChange} placeholder={placeholder} />
			<div className={s2.modalResumeRewriteContainer}>
				<div className={s2.modalResumeRewriteTextContainer}>
					<ul className={s2.modalResumeRewriteList}>
						{tips.map((tip) => (
							<li key={tip} className={s2.modalResumeRewriteListItem}>
								{tip}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}
