import React, { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import { openDB } from "idb";

import { IoClose } from "react-icons/io5";
import s2 from "../../styleModules/modalStyles.module.css";
import ModalWrapper from "./ModalWrapper.js";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

async function initDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			db.createObjectStore("simulation_db");
		}
	});
}
function AudioVideoSettingsModal({ pauseSimRef, setAudioVideoModalOpen }) {
	const { selectedDevices, saveDeviceSetting, devices } = useDeviceSettings();

	useEffect(() => {
		if (typeof pauseSimRef.current === "function") {
			pauseSimRef.current();
		}
		// Code to be executed when the component mounts
	}, [pauseSimRef]); // The empty array ensures this effect runs once on mount

	const { userLanguage, performTranslation, performanceMode, setPerformanceMode } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Camera: "Camera",
		Microphone: "Microphone",
		InputLevel: "Input Level",
		Speaker: "Speaker",
		TestSpeaker: "Test Speaker",
		Preformance: "Preformance",
		GraphicsAcceleration: "Graphics Acceleration",
		PerformanceMode: "Performance Mode"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, devices]);

	// const [devices, setDevices] = useState({
	// 	video: [],
	// 	audio: [],
	// 	speaker: []
	// });
	// const [selectedDevices, setSelectedDevices] = useState({
	// 	video: null,
	// 	audio: null,
	// 	speaker: null
	// });
	const [audioLevel, setAudioLevel] = useState(0);
	const [audioSettings, setAudioSettings] = useState({
		gainControl: false,
		noiseSuppression: false
	});

	const videoRef = useRef(null);
	const audioContextRef = useRef(null);
	const analyserRef = useRef(null);
	const micStreamRef = useRef(null);
	const animationFrameRef = useRef(null);

	// Cleanup function to stop video and audio streams
	const stopVideoStream = (reason = "") => {
		console.log(`stopVideoStream ${reason}`);
		if (videoRef.current && videoRef.current.srcObject) {
			const tracks = videoRef.current.srcObject.getTracks();
			tracks.forEach((track) => track.stop());
			videoRef.current.srcObject = null;
		}
	};

	const stopAudioStream = (reason = "") => {
		console.log(`stopAudioStream ${reason}`);
		if (micStreamRef.current) {
			const tracks = micStreamRef.current.getTracks();
			tracks.forEach((track) => track.stop());
			micStreamRef.current = null;
		}
	};
	const stopMediaStreams = (reason = "") => {
		stopVideoStream(reason);
		stopAudioStream(reason);
	};
	// useEffect(() => {
	// 	const initializeSettings = async () => {
	// 		const db = await initDB();

	// 		const savedVideoDevice = await db.get("simulation_db", "saved_video_device");
	// 		const savedAudioDevice = await db.get("simulation_db", "saved_audio_device");
	// 		const savedSpeakerDevice = await db.get("simulation_db", "saved_speaker_device");

	// 		console.log(savedVideoDevice, savedAudioDevice, savedSpeakerDevice);
	// 		const savedAudioSettings = await db.get("simulation_db", "audioSettings");

	// 		navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
	// 			const videoDevices = deviceInfos.filter((device) => device.kind === "videoinput");
	// 			const audioDevices = deviceInfos.filter((device) => device.kind === "audioinput");
	// 			const speakerDevices = deviceInfos.filter((device) => device.kind === "audiooutput");

	// 			// Automatically set the default devices if available
	// 			const defaultVideoDevice = videoDevices[0] ? { value: videoDevices[0].deviceId, label: videoDevices[0].label } : null;
	// 			const defaultAudioDevice = audioDevices[0] ? { value: audioDevices[0].deviceId, label: audioDevices[0].label } : null;
	// 			const defaultSpeakerDevice = speakerDevices[0] ? { value: speakerDevices[0].deviceId, label: speakerDevices[0].label } : null;
	// 			setDevices({
	// 				video: videoDevices.map((device) => ({ value: device.deviceId, label: device.label })),
	// 				audio: audioDevices.map((device) => ({ value: device.deviceId, label: device.label })),
	// 				speaker: speakerDevices.map((device) => ({ value: device.deviceId, label: device.label }))
	// 			});

	// 			const savedDeviced = {
	// 				video: defaultVideoDevice,
	// 				audio: defaultAudioDevice,
	// 				speaker: defaultSpeakerDevice
	// 			};

	// 			// Set saved devices if they exist
	// 			if (savedVideoDevice) {
	// 				const videoDevice = videoDevices.find((device) => device.deviceId === savedVideoDevice);
	// 				savedDeviced.video = videoDevice ? { value: videoDevice.deviceId, label: videoDevice.label } : savedDeviced.video;
	// 			}
	// 			if (savedAudioDevice) {
	// 				const audioDevice = audioDevices.find((device) => device.deviceId === savedAudioDevice);
	// 				savedDeviced.audio = audioDevice ? { value: audioDevice.deviceId, label: audioDevice.label } : savedDeviced.audio;
	// 			}
	// 			if (savedSpeakerDevice) {
	// 				const speakerDevice = speakerDevices.find((device) => device.deviceId === savedSpeakerDevice);
	// 				savedDeviced.speaker = speakerDevice ? { value: speakerDevice.deviceId, label: speakerDevice.label } : savedDeviced.speaker;
	// 			}
	// 			// Set saved audio settings if they exist
	// 			if (savedAudioSettings) {
	// 				setAudioSettings(savedAudioSettings);
	// 			}

	// 			setSelectedDevices(savedDeviced);
	// 		});
	// 	};
	// 	// Fetch and set devices for video, audio, and speaker
	// 	initializeSettings();
	// 	return () => {
	// 		stopMediaStreams();
	// 	};
	// }, []);

	useEffect(() => {
		// Set the stream for the video preview
		if (videoRef.current && selectedDevices.video) {
			navigator.mediaDevices
				.getUserMedia({
					video: { deviceId: selectedDevices.video.value }
				})
				.then((stream) => {
					videoRef.current.srcObject = stream;
				})
				.catch((e) => {
					console.error(e);
				});
		}
		return () => {
			stopVideoStream("selected devices video unmount");
		};
	}, [selectedDevices.video]);

	useEffect(() => {
		// Initialize audio context and analyser for the microphone level
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
			analyserRef.current = audioContextRef.current.createAnalyser();
			analyserRef.current.fftSize = 256;
		}

		if (selectedDevices.audio) {
			// Stop the previous stream
			if (micStreamRef.current) {
				micStreamRef.current.getTracks().forEach((track) => track.stop());
			}
			const audioConstraints = {
				deviceId: selectedDevices.audio.value,
				// Add the noiseSuppression constraint based on the toggle value
				noiseSuppression: audioSettings.noiseSuppression
			};
			// Get the new stream
			navigator.mediaDevices
				.getUserMedia({
					audio: audioConstraints
				})
				.then((stream) => {
					micStreamRef.current = stream;
					const micSource = audioContextRef.current.createMediaStreamSource(stream);
					micSource.connect(analyserRef.current);
					animateAudioLevel();
				})
				.catch((e) => {
					console.error(e);
				});
		}

		// Cleanup function to stop the animation frame and stream tracks
		return () => {
			cancelAnimationFrame(animationFrameRef.current);
			if (micStreamRef.current) {
				micStreamRef.current.getTracks().forEach((track) => track.stop());
			}
			stopAudioStream("selected audio unmount");
		};
	}, [selectedDevices.audio, audioSettings.noiseSuppression]);

	const handleDeviceChange = async (selectedOption, { name }) => {
		// setSelectedDevices((prev) => ({
		// 	...prev,
		// 	[name]: selectedOption
		// }));
		// console.log(selectedOption, { name }, `saved_${name}_device`);
		// const db = await initDB();
		// await db.put("simulation_db", selectedOption.value, `saved_${name}_device`);

		saveDeviceSetting(name, selectedOption);
	};
	// Function to animate the audio level meter
	function animateAudioLevel() {
		animationFrameRef.current = requestAnimationFrame(animateAudioLevel);
		const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
		analyserRef.current.getByteFrequencyData(dataArray);
		let sum = 0;
		for (let i = 0; i < dataArray.length; i++) {
			sum += dataArray[i];
		}
		const average = sum / dataArray.length;
		setAudioLevel(average);
	}

	// const handleToggleChange = async (event) => {
	// 	const { name } = event.target;
	// 	console.log("handleToggleChange", name);
	// 	setAudioSettings((prev) => ({
	// 		...prev,
	// 		[name]: !prev[name]
	// 	}));
	// 	const db = await initDB();
	// 	await db.put("simulation_db", "audioSettings", { ...audioSettings, [name]: checked });
	// };

	const handleTestSpeaker = () => {
		if (selectedDevices.speaker) {
			const audio = new Audio("https://www.w3schools.com/html/horse.mp3"); // Replace with the URL to your audio file
			// Set the sinkId to the selected output device if supported
			if (typeof audio.sinkId !== "undefined") {
				audio
					.setSinkId(selectedDevices.speaker.value)
					.then(() => {
						audio.play();
					})
					.catch((error) => {
						console.warn("Error assigning audio output device", error);
					});
			} else {
				console.warn("Your browser does not support output device selection.");
				audio.play(); // Play without setting a specific device
			}
		}

		// Logic to test the speaker should go here
	};

	const graphicsAcceleration = () => {
		window.open("https://help.instagepractice.com/en/articles/9216365-how-to-fix-simulation-lag", "_blank");
		// console.log("Opening settings");
	};

	// Function to handle closing of the modal and cleanup
	const closeModal = () => {
		stopMediaStreams("close modal");
		setAudioVideoModalOpen(false);
	};
	// Inline styles for the audio level meter
	const audioLevelMeterStyle = {
		height: "10px",
		width: "100%",
		backgroundColor: "#ddd",
		borderRadius: "5px",
		overflow: "hidden",
		marginTop: "10px"
	};

	const audioLevelIndicatorStyle = {
		height: "100%",
		width: `${audioLevel}%`,
		backgroundColor: audioLevel > 50 ? "red" : "green"
		// transition: "width 0.2s ease-in-out"
	};
	const videoStyle = {
		width: "320px", // Set your desired width
		height: "240px", // Set your desired height
		backgroundColor: "#000" // Set a background color to visualize the area when not playing
	};

	const testAudioStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",

		height: "3rem",
		fontSize: "1.25rem",
		color: "white",
		backgroundColor: "#00a9af",
		border: "none",
		borderRadius: "0.5rem",
		padding: "0.90438rem 1.5rem 0.90813rem 1.5rem",
		gap: "0.5rem"
	};
	return (
		<ModalWrapper closeModal={() => closeModal()}>
			<div className={s2.modalAnimated}>
				<span className={s2.modalCloseIcon}>
					<IoClose title="Close icon" onClick={() => closeModal()} />
				</span>
				<div className={s2.modalTextContainer}>
					{/* <h1 className={s2.modalSubtitle} style={{ margin: "0" }}>
						Settings
					</h1> */}
					<h2 className={s2.modalSubtitle} style={{ margin: "0" }}>
						{pageText.Camera}
					</h2>
					{/* Video preview */}
					<video autoPlay ref={videoRef} style={videoStyle} className={s2.videoPreview} /> {/* Camera selection */}
					<Select
						name="video"
						options={devices.video}
						value={selectedDevices.video}
						onChange={handleDeviceChange}
						placeholder="Select Camera"
					/>
					<hr />
					<h2 className={s2.modalSubtitle} style={{ margin: "0" }}>
						{pageText.Microphone}
					</h2>
					{/* Microphone selection */}
					<Select
						name="audio"
						options={devices.audio}
						value={selectedDevices.audio}
						onChange={handleDeviceChange}
						placeholder="Select Microphone"
					/>
					{/* Audio level meter */}
					{/* {audioLevel} */}
					{pageText.InputLevel}:
					<div style={audioLevelMeterStyle}>
						<div style={audioLevelIndicatorStyle} />
					</div>
					<br />
					{/* <input
						type="checkbox"
						checked={audioSettings.noiseSuppression}
						id="noiseSuppression"
						name="noiseSuppression"
						onChange={handleToggleChange}
					/>
					<label htmlFor="noiseSuppression" style={{ marginBottom: "0", color: "grey" }}>
						Enable noise suppression
					</label> */}
					<hr />
					<h2 className={s2.modalSubtitle} style={{ margin: "0" }}>
						{pageText.Speaker}
					</h2>
					{/* Speaker selection */}
					<Select
						name="speaker"
						options={devices.speaker}
						value={selectedDevices.speaker}
						onChange={handleDeviceChange}
						placeholder="Select Speaker"
					/>
					{/* Toggles for gain control and noise suppression */}
					<div className={s2.toggleContainer}>
						{/* <input
							type="checkbox"
							checked={audioSettings.gainControl}
							id="gainControl"
							name="gainControl"
							onChange={handleToggleChange}
						/>
						<label htmlFor="gainControl" style={{ marginBottom: "0", color: "grey" }}>
							Enable automatic gain control
						</label> */}
					</div>
					{/* Test Speaker */}
					<br />
					<button type="button" onClick={handleTestSpeaker} style={testAudioStyle} className={s2.testSpeakerButton}>
						{pageText.TestSpeaker}
					</button>
					<br />
					<hr />
					<h2 className={s2.modalSubtitle} style={{ margin: "0" }}>
						{pageText.Preformance}
					</h2>
					<button type="button" onClick={graphicsAcceleration} style={testAudioStyle} className={s2.testSpeakerButton}>
						{pageText.GraphicsAcceleration}
					</button>
					<br />
					<input
						type="checkbox"
						checked={performanceMode}
						onChange={() => {
							setPerformanceMode(!performanceMode);
							window.location.reload();
						}}
						id="performanceMode"
					/>
					<label htmlFor="performanceMode" style={{ marginBottom: "0" }}>
						{pageText.PerformanceMode}
					</label>
				</div>
			</div>
		</ModalWrapper>
	);
}

export default AudioVideoSettingsModal;
