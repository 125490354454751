import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { FiCheck, FiX, FiCopy } from "react-icons/fi/index.esm.js";
import { IoClose, IoDocumentTextSharp } from "react-icons/io5/index.esm.js";
import Select from "react-select";
import { DateRangePicker, Stack, Pagination } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { AppContext } from "../UtilityFunctions/AppContext.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { obfuscate } from "../UtilityFunctions/DataUtility.js";

import styles from "../../styleModules/adminSystemStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import { BaseTable, Column } from "../SecondaryComponents/BaseTable.jsx";
import ModalWrapper from "../SecondaryComponents/ModalWrapper.js";
import circles from "../../images/circles.png";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
// import Pagination from "../UtilityFunctions/Pagination.js";
import s from "../../styleModules/setupStyles.module.css";

import "rsuite/DateRangePicker/styles/index.css";
import "rsuite/Pagination/styles/index.css";

const { combine, afterToday } = DateRangePicker;
const predefinedBottomRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()]
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)]
	},

	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()]
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()]
	},

	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
	},
	{
		label: "This year",
		value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
	},
	{
		label: "All time",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
	}
];

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

/**
 * Escapes a CSV field to ensure it is properly formatted.
 * If the field contains a comma, double quote, or newline, it is enclosed in double quotes.
 * Any double quotes in the field are escaped with two double quotes.
 *
 * @param {string} field - The field to escape.
 * @returns {string} - The escaped field.
 */
function escapeCsvField(field) {
	if (field === null || field === undefined) {
		return "";
	}
	let stringField = String(field);
	if (stringField.includes(",") || stringField.includes('"') || stringField.includes("\n")) {
		stringField = `"${stringField.replace(/"/g, '""')}"`;
	}
	return stringField;
}

/**
 * Converts an array of JSON objects to a CSV string.
 *
 * @param {Object[]} jsonData - The JSON data to convert.
 * @param {string[]} columnOrder - The order of the columns in the CSV file.
 * @returns {string} - The CSV string.
 */
function convertJsonToCsv(jsonData, columnOrder) {
	if (!Array.isArray(jsonData) || !Array.isArray(columnOrder)) {
		throw new Error("Invalid input: jsonData and columnOrder must be arrays");
	}

	const csvRows = jsonData.map((obj) => columnOrder.map((key) => escapeCsvField(obj[key])).join(","));

	return [columnOrder.join(",")].concat(csvRows).join("\n");
}

const convertTime = (seconds) => {
	// convert seconds to mm:ss format
	const minutes = Math.floor(seconds / 60);
	let secondsLeft = seconds % 60;
	if (secondsLeft < 10) {
		secondsLeft = `0${secondsLeft}`;
	}
	return `${minutes}:${secondsLeft}`;
};

export function SimActivity() {
	const { axiosLimitedGet } = useAxiosLimited();
	// Summary Modal compoment
	const [summary, setSummary] = useState("");
	const { tenantId, userLanguage, translateSingleText, translateDictText, performTranslation } = useContext(AppContext);

	const [data, setData] = useState([]);

	const PaginationLocale = {
		fr: {
			more: "Plus",
			prev: "Précédent",
			next: "Suivant",
			first: "Premier",
			last: "Dernier",
			limit: "{0} / page",
			total: "Total lignes: {0}",
			skip: "Aller à {0}"
		},
		en: {
			more: "More",
			prev: "Previous",
			next: "Next",
			first: "First",
			last: "Last",
			limit: "{0} / page",
			total: "Total Rows: {0}",
			skip: "Go to {0}"
		}
	};

	const LocalLanguageDict = {
		SimulationActivity: "Simulation Activity",
		FilterByEmail: "Filter by email",
		FilterByDate: "Filter by date",
		FilterByType: "Filter by type",
		FilterBySimulation: "Filter by simulation",
		Email: "Email",
		Date: "Date",
		Duration: "Duration",
		Name: "Name",
		Simulation: "Simulation",
		SearchEmail: "Search email",
		SearchSimulation: "Search simulation",
		SearchType: "Search type",
		TotalRows: "Total rows",
		Go: "Go",
		To: "to",
		Page: "Page",
		Of: "of",
		ExportToCSV: "Export to CSV",
		Outcome: "Outcome"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, data]);

	const [translatedRanges, setTranslatedRanges] = useState([]);

	useEffect(() => {
		const translateLabels = async () => {
			const translatedRanges = await Promise.all(
				predefinedBottomRanges.map(async (range) => {
					const translatedLabel = await translateSingleText(range.label, userLanguage);
					return { ...range, label: translatedLabel };
				})
			);
			// Assuming you have a state to hold the translated ranges
			setTranslatedRanges(translatedRanges);
		};

		setTimeout(() => {
			// stale state problem goes away using inner function
			translateLabels();
		}, 1000);
	}, [userLanguage]); // Re-run the effect when userLanguage changes

	let clientID = "nTop";
	let isInstage = clientID === "inStage";
	let isDashboard = false;

	if (window.location.href.includes("admin/dashboard")) {
		isInstage = true;
		isDashboard = true;
	}

	if (isInstage) {
		clientID = "Quillworks";
	}

	const [isLoading, setIsLoading] = useState(true);

	const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

	const [submitted, setSubmitted] = useState(false);
	const [emailFilter, setEmailFilter] = useState("");
	const [selectedSetupName, setSelectedSetupName] = useState("all");
	const [selectedSetupType, setSelectedTypeDisplay] = useState([{ value: "all", label: "All" }]);
	const [selectedSearchFilter, setSelectedSearchFilter] = useState([]);
	const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
	const [selectedSearchEmail, setSelectedSearchEmail] = useState([]);

	// const selectedSearchFilterZero = selectedSearchFilter.length > 0 ? selectedSearchFilter[0] : null;

	const [emailOptions, setEmailOptions] = useState([]);
	const [setupNameOptions, setSetupNameOptions] = useState([]);
	const [dateFilter, setDateFilter] = React.useState([]);
	const [outcome, setOutcome] = React.useState([]);
	const [activePage, setActivePage] = React.useState(1);
	const [totalRecords, setTotalRecords] = React.useState(1);
	const limitPerPage = 10;

	const [errorStatus, setErrorStatus] = useState(null);

	useEffect(() => {
		fetchEmails();
	}, [selectedSetupName]);

	async function fetchEmails() {
		try {
			const result = await axiosLimitedGet(`${url}/api/dashboard/filter-values`, 1, {
				params: {
					tenantId,
					...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {})
				}
			});
			// console.log(result.data);

			setEmailOptions(result.data.emails.map((item) => ({ value: item.email, label: item.email })));

			const uniqueSetupNames = [...new Set(result.data.setup_names.map((item) => (item.setup_name ? item.setup_name.toLowerCase() : "")))];
			setSelectedSearchOptions(
				uniqueSetupNames.map((item) => ({
					value: item,
					label: item.charAt(0).toUpperCase() + item.slice(1)
				}))
			);

			const localSetupNameOptions = result.data.setup_types.map((item) => {
				if (typeof item.setup_type === "string") {
					return {
						value: item.setup_type,
						label: item.setup_type.charAt(0).toUpperCase() + item.setup_type.slice(1)
					};
				} else {
					// Handle the case where setup_type is not a string
					return {
						value: "",
						label: "Unknown" // You can customize this placeholder as needed
					};
				}
			});
			setSetupNameOptions([{ value: "all", label: "All" }, ...localSetupNameOptions]);
		} catch (error) {
			console.error(error);
			setErrorStatus(error.status);
		}
	}

	useEffect(() => {
		async function fetchSetupTypes() {
			// Extract labels from setupNameOptions for translation
			const labelsToTranslate = setupNameOptions.map((option) => option.label);

			try {
				const translatedTypes = await translateDictText(labelsToTranslate, userLanguage);
				// Map translated texts back to setupNameOptions structure
				const translatedSetupNameOptions = setupNameOptions.map((option, index) => ({
					value: option.value,
					label: translatedTypes[index] || option.label // Fallback to original label if translation fails
				}));

				// console.log("translatedSetupNameOptions: ", translatedSetupNameOptions);
				setSetupNameOptions([...translatedSetupNameOptions]);
			} catch (error) {
				console.error("Error translating setup name options:", error);
			}
		}

		if (setupNameOptions.length > 0) {
			fetchSetupTypes();
		}
	}, [userLanguage, emailOptions]);

	const selectedSearchFilterLength = selectedSearchFilter.length;

	const fetchPageData = useCallback(async () => {
		const currentPage = activePage;
		// console.log("Fetching data for page:", currentPage);
		// console.log("emailFilter", emailFilter);
		// console.log("selectedSearchFilter", selectedSearchFilter);
		// console.log("selectedSetupName", selectedSetupName);
		// console.log("dateFilter", dateFilter);
		// console.log("dateFilter", outcome);
		try {
			setIsLoading(true);
			const result = await axiosLimitedGet(`${url}/api/dashboard/SimulationActivity`, 1, {
				params: {
					tenantId,
					page: currentPage,
					email: emailFilter,
					limit: 10,
					setup_name: selectedSearchFilter[0]?.value,
					...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {}),
					startDate: dateFilter && dateFilter[0] ? dateFilter[0] : null, // startDate
					endDate: dateFilter && dateFilter[1] ? dateFilter[1] : null,
					outcome: outcome && outcome[1] ? outcome[1] : null

					// endDate
				}
			});
			// console.log("results", result.data);
			setTotalRecords(result.data.totalRecords);
			setData(result.data.data);
		} catch (error) {
			console.error(error);
			setErrorStatus(error.status);
		} finally {
			setIsLoading(false);
		}
	}, [emailFilter, selectedSearchFilterLength, selectedSearchFilter, selectedSetupName, dateFilter, activePage]);

	useEffect(() => {
		fetchPageData();
	}, [fetchPageData, activePage]);

	// function to export data to csv it will loop all the pages and fetch data
	async function exportToCSV() {
		setIsDownloadingCSV(true);
		// console.log("Exporting to CSV");
		const columnOrder = [
			`${pageText.Email}`,
			`${pageText.Name}`,
			`${pageText.Simulation}`,
			`${pageText.Date}`,
			`${pageText.Duration}`,
			`${pageText.Outcome}`
		];
		let csvData = [];
		const totalPages = Math.ceil(totalRecords / 100);
		for (let i = 1; i <= totalPages; i++) {
			// eslint-disable-next-line no-await-in-loop
			const result = await axiosLimitedGet(`${url}/api/dashboard/SimulationActivity`, 1, {
				params: {
					tenantId,
					page: i,
					email: emailFilter,
					limit: 100,
					setup_name: selectedSearchFilter[0]?.value,
					...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {}),
					startDate: dateFilter && dateFilter[0] ? dateFilter[0] : null, // startDate
					endDate: dateFilter && dateFilter[1] ? dateFilter[1] : null,
					outcome: outcome && outcome[1] ? outcome[1] : null

					// endDate
				}
			});
			// console.log(result.data);
			// eslint-disable-next-line
			await new Promise((resolve) => setTimeout(resolve, 300));
			csvData.push(...result.data.data);
		}
		// console.log("csvData: ", csvData);
		csvData = csvData.map((item) => {
			const setupname = item.setup_output?.assignment_name ? item.setup_output?.assignment_name : item.setup_name;
			return {
				Email: item.email,
				Name: item.full_name,
				Simulation: setupname,
				Date: item.start_time,
				Duration: convertTime(item.duration),
				Outcome: item.outcome
			};
		});
		// console.log("csvData: ", csvData);
		const csvString = convertJsonToCsv(csvData, columnOrder);
		const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
		const csvUrl = URL.createObjectURL(csvBlob);
		const downloadLink = document.createElement("a");
		// auto download
		downloadLink.setAttribute("href", csvUrl);
		downloadLink.setAttribute("download", "instage_session_activity.csv"); // specify the download file name
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink); // remove the link when done
		setIsDownloadingCSV(false);
	}

	const filteredData = data || [];
	// console.log("filteredData", filteredData);
	const headers = [
		`${pageText.Email}`,
		`${pageText.Name}`,
		`${pageText.Simulation}`,
		`${pageText.Date}`,
		`${pageText.Duration}`,
		`${pageText.Outcome}`
	];

	const columnWidths = {
		Email: "225px",
		Name: "225px",
		Simulation: "300px",
		Date: "100px",
		Duration: "100px",
		Grade: "300px",
		Link: "150px",
		Commented: "100px",
		Outcome: "100px",
		Summary: "100px",
		Questions: "115px",
		Process: "115px",
		"Items to Learn": "115px"
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			cursor: "pointer",
			outline: state.isFocused ? "1px solid #00a9af" : "none",
			width: "16rem",
			backgroundColor: "#f1f1f1",
			color: "#303d7c"
		}),
		option: (provided, state) => ({
			...provided,
			cursor: "pointer",
			backgroundColor: state.isFocused ? (state.isSelected ? "#00a9af" : "#00a9af7e") : state.isSelected ? "#00a9af" : null,
			color: state.isSelected ? "#fff" : "#414042",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		})
	};

	return (
		<div className={styles.adminPageContainer}>
			<h1 className={styles.dashboardHeading} style={{ position: "sticky", top: "0", left: "0" }}>
				{pageText.SimulationActivity}
			</h1>
			{summary !== "" && (
				<ModalWrapper closeModal={() => setSummary("")}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={styles2.modalHeaderIconContainer}>
							<IoDocumentTextSharp className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setSummary("")} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>Summary</h2>
							<p className={styles2.modalText}>{summary}</p>
						</div>
					</div>
				</ModalWrapper>
			)}
			<div className={styles.filterContainer} style={{ position: "sticky", left: "0" }}>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByEmail}</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchEmail}
							styles={customStyles}
							isSearchable
							isClearable
							options={emailOptions}
							value={selectedSearchEmail}
							onChange={(option) => {
								setSelectedSearchEmail(option);
								setEmailFilter(option ? option.value : "");
							}}
						/>
					</div>
				</div>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByType}</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchType}
							styles={customStyles}
							isSearchable={false}
							options={setupNameOptions}
							value={selectedSetupType}
							onChange={(option) => {
								setSelectedTypeDisplay(option);
								setSelectedSetupName(option ? option.value : "all");
								setSelectedSearchFilter([]);
								setActivePage(1);
							}}
						/>
					</div>
				</div>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterBySimulation}</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchSimulation}
							styles={customStyles}
							isSearchable
							isClearable
							options={selectedSearchOptions}
							value={selectedSearchFilter}
							onChange={(option) => {
								setSelectedSearchFilter(option ? [option] : []);
								setActivePage(1);
							}}
						/>
					</div>
				</div>

				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByDate}</p>
					<div className={styles.reactDropdownSelect}>
						<DateRangePicker
							// locale={userLanguage}
							format="yyyy-MM-dd"
							onChange={(value) => {
								setDateFilter(value);
								setActivePage(1);
							}}
							ranges={translatedRanges}
							showOneCalendar
							shouldDisableDate={afterToday()}
							showHeader={false}
						/>
					</div>
				</div>
			</div>
			{/* <div>
                Selected Value(s): {selectedSearchFilter.map(item => item.label).join(', ')}
            </div> */}
			{/* <div className={styles.tabContainer} style={{ left: "0" }}>
                {!isDashboard && false && (
                    <>
                        <Tab onTabClick={() => setSubmitted(false)} isActive={!submitted} tabIndex={false}>
                            Not Submitted
                        </Tab>

                        <Tab onTabClick={() => setSubmitted(true)} isActive={submitted} tabIndex>
                            Submitted
                        </Tab>
                    </>
                )}
                {isDashboard && (
                    <>
                        <Tab onTabClick={() => setSubmitted(false)} isActive={!submitted} tabIndex={false}>
                            Active
                        </Tab>
                        <Tab onTabClick={() => setSubmitted(true)} isActive={submitted} tabIndex="-1">
                            all
                        </Tab>
                    </>
                )}
            </div> */}
			{/* <select onChange={(e) => setEmailFilter(e.target.value)} style={{ marginLeft: '10px' }}>
                        <option value="all">all</option>
                        {uniqueEmails.map(email => (
                            <option key={email} value={email}>{email}</option>
                        ))}
            </select> */}
			{isLoading && !errorStatus && (
				<div className={s.spinnerContainerStart}>
					<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
				</div>
			)}

			{!isLoading && !errorStatus && (
				<BaseTable
					className={styles.tableContainer}
					textAlign="left"
					data={filteredData}
					rowKey="key"
					fixed
					maxHeight={Infinity}
					headerHeight={0}
					headers={headers}
					classNameHeader={styles.tableHeaderRow}
					columnWidths={columnWidths}
				>
					<Column
						title="email"
						key="email"
						dataKey="email"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Email }}>
								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="name"
						key="full_name"
						dataKey="full_name"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Name }}>
								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="simulation"
						key="setup_name"
						dataKey="setup_name"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Simulation }}>
								{props.rowData?.setup_output?.assignment_name && <b>{`${props.rowData?.setup_output?.assignment_name}:`}</b>}

								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="date"
						key="start_time"
						dataKey="start_time"
						textAlign="center"
						cellRenderer={(props) => {
							const date = new Date(props.cellData);
							const value = `${date.toLocaleString("default", { month: "long" })} ${date.getDate()}`;
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Date }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="duration"
						key="duration"
						dataKey="duration"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Duration }}>
								{convertTime(props.cellData)}
							</div>
						)}
					/>
					<Column
						title="grade"
						key="outcome"
						dataKey="outcome"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Grade }}>
								{props.cellData ? props.cellData.split(".")[0] : ""}
							</div>
						)}
					/>
					<Column
						title="link"
						key="session_id"
						dataKey="session_id"
						textAlign="center"
						active={submitted}
						cellRenderer={(props) => {
							let value = props.cellData;
							const currentDomain = window.location.origin;
							const reportLink = `${currentDomain}/admin/report/${obfuscate(value)}`;
							//  console.log(reportLink);
							const reportID = value;
							// make value a clickable link in the to the page /admin/report/value
							value = <ReportUrlCell value={reportID} reportLink={reportLink} />;
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Link }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="comments"
						key="comments"
						dataKey="comments"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							let value;
							if (props.cellData) {
								value = <FiCheck />;
							} else {
								value = <FiX />;
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Comments }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="objective"
						key="objective"
						dataKey="objective"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							let value = props.cellData;
							if (value) {
								value = <FiCheck />;
							} else {
								value = <FiX />;
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Outcome }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="summary"
						key="summary"
						dataKey="summary"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							const value = props.cellData;
							if (value === null) {
								return null;
							}
							return (
								<div
									className={`${styles.tableData} ${styles.summaryCell}`}
									style={{ width: columnWidths.Summary }}
									onClick={() => setSummary(value)}
								>
									{/* {value} */}
									<span className={styles.readSummary}>Read</span>
								</div>
							);
						}}
					/>
					<Column
						title="question_responses_tally"
						key="question_responses_tally"
						dataKey="question_responses_tally"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							const value = props.cellData;
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Questions }}>
									{value}
								</div>
							);
						}}
					/>

					<Column
						title="process_tally"
						key="process_tally"
						dataKey="process_tally"
						textAlign="center"
						active={submitted && (selectedSetupName === "all" || selectedSetupName === "discovery")}
						width={columnWidths.topic_to_cover}
						cellRenderer={(props) => {
							let value = props.cellData;
							if (!props.cellData) {
								value = "N/A";
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Process }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="item_to_learn_tally"
						key="item_to_learn_tally"
						dataKey="item_to_learn_tally"
						textAlign="center"
						active={submitted && (selectedSetupName === "all" || selectedSetupName === "discovery")}
						width={columnWidths.topic_to_learn}
						cellRenderer={(props) => {
							let value = props.cellData;
							if (!props.cellData) {
								value = "N/A";
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths["Item to Learn"] }}>
									{value}
								</div>
							);
						}}
					/>
				</BaseTable>
			)}
			{errorStatus && errorStatus !== 403 && <div>There was an error fetching the data. Please try again later.</div>}
			{errorStatus && errorStatus === 403 && <div>You do not have access.</div>}
			{!errorStatus && (
				<>
					<Pagination
						ellipsis
						boundaryLinks
						prev
						last
						next
						first
						limit={limitPerPage}
						total={totalRecords}
						activePage={activePage}
						onChangePage={setActivePage}
						maxButtons={5}
						layout={["pager", "skip", "|", "total"]}
						locale={PaginationLocale[userLanguage]}
					/>

					{isDownloadingCSV && <LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />}
					{!isDownloadingCSV && (
						<button style={{ width: "unset" }} className={s.dashboardTableBtnStart} type="button" onClick={exportToCSV}>
							{" "}
							{pageText.ExportToCSV}
						</button>
					)}
				</>
			)}
		</div>
	);
}

export function ReportUrlCell({ value, reportLink }) {
	const [isCopied, setIsCopied] = useState(false);

	const { tenantId, userLanguage, translateSingleText, translateDictText, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		Go: "Go",
		To: "to",
		Page: "Page",
		Of: "of"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]); // Depend on pageText to trigger re-translation

	return (
		<div className={styles.urlCell}>
			<a className={styles.urlButton} target="blank" href={`/admin/report/${obfuscate(value)}`}>
				{pageText.Go}
			</a>

			{isCopied ? (
				<FiCheck
					className={styles.copiedIcon}
					onClick={() => {
						setIsCopied(true);
						navigator.clipboard.writeText(reportLink);
					}}
				/>
			) : (
				<FiCopy
					className={styles.copyIcon}
					onClick={() => {
						setIsCopied(true);
						navigator.clipboard
							.writeText(reportLink)
							.then(() => {
								setIsCopied(true);
								// console.log('URL copied to clipboard:', reportLink);
								setTimeout(() => {
									setIsCopied(false);
								}, 1000); // Reset the "Copied" message after 2 seconds
							})
							.catch((error) => {
								console.error("Failed to copy URL to clipboard:", error);
							});
					}}
				/>
			)}
		</div>
	);
}
