import React, { useState, useEffect, useContext } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import styles from "../../styleModules/adminSystemStyles.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { axiosRetryGet } from "../UtilityFunctions/axiosRetry";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { CSVLink } from "react-csv";

// Generic DashboardItem component
function DashboardItem({ title, size = "medium", onClick, expanded, children }) {
	return (
		<div
			className={`${styles.dataItem} ${styles[size]} ${expanded ? styles.expanded : ""}`}
			onClick={onClick}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClick();
				}
			}}
			role="button"
			tabIndex={0}
			style={{ display: expanded ? "flex" : "" }}
		>
			<h3 className={styles.dataItemTitle}>{title}</h3>
			{expanded && (
				<button
					type="button"
					className={styles.closeButton}
					onClick={(e) => {
						e.stopPropagation();
						onClick();
					}}
				>
					&times;
				</button>
			)}
			<div className={`${styles.mainField} ${expanded ? styles.expandedField : ""}`}>{children}</div>
		</div>
	);
}

// Small item with just a value
function DashboardItemSmall({ title, value, onClick, expanded }) {
	return (
		<DashboardItem title={title} size="small" onClick={onClick} expanded={expanded}>
			<p className={styles.dataItemValue}>{value}</p>
		</DashboardItem>
	);
}

// Item with circular progress
function DashboardItemPercent({ title, value, maxValue, onClick, expanded, onExport, showExportButton }) {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		setPercentage((value / maxValue) * 100);
	}, [value, maxValue]);

	return (
		<DashboardItem title={title} size="large" onClick={onClick} expanded={expanded}>
			<div className={styles.circularProgressContainer}>
				<CircularProgressbar
					value={percentage}
					// text={`${value}`}
					styles={buildStyles({
						textSize: "28px",
						pathColor: percentage >= 100 ? "#ee4188" : "#00a9af",
						textColor: "#344054",
						trailColor: "#e6e8f4"
					})}
				/>
				<div className={styles.circularProgressBarText}>{value}</div>
				<div className={styles.maxValueOverlay}>/ {maxValue}</div>
			</div>
			{showExportButton && (
				<button
					type="button"
					className={styles.exportButton}
					onClick={(e) => {
						e.stopPropagation();
						onExport();
					}}
				>
					Export CSV
				</button>
			)}
		</DashboardItem>
	);
}

// Item with bar chart
function DashboardItemBarChart({ title, data, onClick, expanded, itemSize }) {
	const chartData = data.map((item) => ({
		month: item.month.trim().substring(0, 3),
		count: parseInt(item.session_count, 10)
	}));

	return (
		<DashboardItem title={title} size={itemSize} onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<BarChart data={chartData}>
					<XAxis dataKey="month" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="count" fill="#00a9af" />
				</BarChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

function DashboardItemBarChart2({ title, data, onClick, expanded, itemSize }) {
	const chartData = [
		{
			name: "Uploads",
			resumes: data.total_resumes_uploaded,
			coverLetters: data.total_cover_letters_uploaded
		}
	];

	return (
		<DashboardItem title={title} size={itemSize} onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<BarChart data={chartData}>
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="resumes" fill="#00a9af" />
					<Bar dataKey="coverLetters" fill="#ee4188" />
				</BarChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

// Item with pie chart
function DashboardItemPieChart({ title, data, onClick, expanded }) {
	const COLORS = [
		"#00a9af", // Blue
		"#ee4188", // Light Pink
		"#8A2BE2", // BlueViolet
		"#3CB371", // MediumSeaGreen
		"#FF1493", // DeepPink
		"#00CED1", // DarkTurquoise
		"#32CD32", // LimeGreen
		"#FF6347", // Tomato
		"#1E90FF", // DodgerBlue
		"#FF8C00" // DarkOrange
	];

	const pieData = data
		.filter((item) => item.setup_type !== null && item.session_count > 0)
		.map((item, index) => ({
			name: item.setup_type,
			value: parseInt(item.session_count, 10),
			color: COLORS[index % COLORS.length]
		}));

	if (pieData.length === 0) {
		return (
			<DashboardItem title={title} onClick={onClick} expanded={expanded}>
				<p>No data available</p>
			</DashboardItem>
		);
	}

	const CustomTooltip = React.memo(({ active, payload }) => {
		if (active && payload && payload.length) {
			const tooltipData = payload[0].payload;
			return (
				<div className={styles.customTooltip}>
					<p>{`${tooltipData.name}: ${tooltipData.value}`}</p>
				</div>
			);
		}
		return null;
	});

	return (
		<DashboardItem title={title} size="large" onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<PieChart>
					<Pie
						data={pieData}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						innerRadius={expanded ? "60%" : "80%"}
						outerRadius={expanded ? "90%" : "100%"}
						fill="#8884d8"
					>
						{pieData.map((entry, index) => (
							<Cell key={`cell-${entry.name}`} fill={entry.color} />
						))}
					</Pie>
					<Tooltip content={<CustomTooltip />} />
					{expanded && <Legend layout="vertical" verticalAlign="middle" wrapperStyle={{ position: "absolute", right: 0 }} />}
				</PieChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

export function TenantStats() {
	const { localUser } = useContext(AppContext);
	const instageId = localUser.userId;
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const [numOfUsers, setNumOfUsers] = useState(0);
	const [numOfLicenses, setNumOfLicenses] = useState(0);

	const [totalSimsRun, setTotalSimsRun] = useState(0);
	const [totalSimsRun6Months, setTotalSimsRun6Months] = useState([]);
	const [totalSimsRunBySetupType, setTotalSimsRunBySetupType] = useState([]);
	const [expandedItem, setExpandedItem] = useState(null);

	const [totalResumesUploaded, setTotalResumesUploaded] = useState(0);
	const [totalCoverLettersUploaded, setTotalCoverLettersUploaded] = useState(0);
	const [totalResumesAndCoverLettersUploaded, setTotalResumesAndCoverLettersUploaded] = useState(0);
	const [totalTimeSpentInSessions, setTotalTimeSpentInSessions] = useState(0);

	const [licenseUsers, setLicenseUsers] = useState([]);

	const [csvLinkRef, setCsvLinkRef] = useState(null);

	const compareUniqueUsersToLicenses = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/CompareUniqueUsersToLicenses`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			if (!response.data.maxLicenses) {
				return;
			}
			const { maxLicenses, currentUsers } = response.data;

			setNumOfUsers(currentUsers);
			setNumOfLicenses(maxLicenses);
		} catch (err) {
			console.error("Error fetching license data:", err);
		}
	};

	useEffect(() => {
		compareUniqueUsersToLicenses();
	}, [numOfUsers, numOfLicenses]);

	const exportLicenseCSV = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/GetLicenseCSVInfoEdu`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});

			const { currentUsers } = response.data;
			// console.log("currentUsers", currentUsers);
			setLicenseUsers(currentUsers);
			if (csvLinkRef) {
				csvLinkRef.link.click();
			}
		} catch (err) {
			console.error("Error fetching license data:", err);
		}
	};

	// useEffect(() => {
	// 	exportLicenseCSV();
	// }, []);

	const getTotalSimsRun = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountByClientId`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			setTotalSimsRun(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getTotalSimsRun();
	}, []);

	const getTotalSimsRun6Months = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountByClientIdPast6Months`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			setTotalSimsRun6Months(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getTotalSimsRun6Months();
	}, []);

	const getSimsRunBySetupType = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountBySetupType`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			setTotalSimsRunBySetupType(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getSimsRunBySetupType();
	}, []);

	const getNumberOfResumesAndCoverLettersUploaded = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetNumberOfResumesAndCoverLettersUploaded`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			// console.log(response.data.total_resumes_uploaded);
			setTotalResumesUploaded(response.data.total_resumes_uploaded);
			setTotalCoverLettersUploaded(response.data.total_cover_letters_uploaded);
			setTotalResumesAndCoverLettersUploaded(response.data);
			console.log(response.data);
			console.log(totalResumesUploaded, totalCoverLettersUploaded);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getNumberOfResumesAndCoverLettersUploaded();
	}, []);

	const getTotalTimeSpentInSessions = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetTotalTimeSpentInSessions`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			// console.log(response.data.total_resumes_uploaded);
			setTotalTimeSpentInSessions(response.data[0].total_time_hours.toFixed(2));
			console.log(totalTimeSpentInSessions);
			console.log(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getTotalTimeSpentInSessions();
	}, []);

	const handleItemClick = (itemType) => {
		setExpandedItem(expandedItem === itemType ? null : itemType);
	};

	return (
		<div className={styles.adminPageContainer}>
			<h1 className={styles.dashboardHeading}>Aggregate Analytics</h1>
			<div className={`${styles.dataContainer} ${expandedItem ? styles.expanded : ""}`}>
				{(!expandedItem || expandedItem === "licenses") && (
					<DashboardItemPercent
						title="Licenses Used"
						value={numOfUsers}
						maxValue={numOfLicenses}
						onClick={() => handleItemClick("licenses")}
						expanded={expandedItem === "licenses"}
						onExport={exportLicenseCSV}
						showExportButton={true}
					/>
				)}
				{(!expandedItem || expandedItem === "totalSims") && (
					<DashboardItemSmall
						title="Total Sims Run"
						value={totalSimsRun.count}
						onClick={() => handleItemClick("totalSims")}
						expanded={expandedItem === "totalSims"}
					/>
				)}
				{/* {(!expandedItem || expandedItem === "resCovuploads") && (
					<DashboardItemBarChart2
						itemSize="small"
						title="Resumes & Cover Letters Uploaded"
						data={totalResumesAndCoverLettersUploaded}
						onClick={() => handleItemClick("resCovuploads")}
						expanded={expandedItem === "resCovuploads"}
					/>
				)} */}
				{(!expandedItem || expandedItem === "totalTime") && (
					<DashboardItemSmall
						title="Total Session Time (hrs)"
						value={totalTimeSpentInSessions}
						onClick={() => handleItemClick("totalTime")}
						expanded={expandedItem === "totalTime"}
					/>
				)}
				{(!expandedItem || expandedItem === "monthlySims") && (
					<DashboardItemBarChart
						itemSize="large"
						title="Monthly Sim Usage"
						data={totalSimsRun6Months}
						onClick={() => handleItemClick("monthlySims")}
						expanded={expandedItem === "monthlySims"}
					/>
				)}
				{(!expandedItem || expandedItem === "simsBySetup") && totalSimsRunBySetupType.length > 0 && (
					<DashboardItemPieChart
						title="Sims Run by Setup Type"
						data={totalSimsRunBySetupType}
						onClick={() => handleItemClick("simsBySetup")}
						expanded={expandedItem === "simsBySetup"}
					/>
				)}
			</div>
			{licenseUsers.length > 0 && (
				<CSVLink data={licenseUsers} filename="license_users.csv" className={styles.hiddenCSVLink} ref={setCsvLinkRef} target="_blank" />
			)}
		</div>
	);
}
