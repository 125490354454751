import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { FiCheck, FiX, FiCopy } from "react-icons/fi/index.esm.js";
import { IoClose, IoDocumentTextSharp } from "react-icons/io5/index.esm.js";
import Select from "react-select";
import { DateRangePicker, Stack, Pagination } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { AppContext } from "../UtilityFunctions/AppContext.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { obfuscate } from "../UtilityFunctions/DataUtility.js";

import styles from "../../styleModules/adminSystemStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import { BaseTable, Column } from "../SecondaryComponents/BaseTable.jsx";
import ModalWrapper from "../SecondaryComponents/ModalWrapper.js";
import circles from "../../images/circles.png";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
// import Pagination from "../UtilityFunctions/Pagination.js";
import s from "../../styleModules/setupStyles.module.css";

import "rsuite/DateRangePicker/styles/index.css";
import "rsuite/Pagination/styles/index.css";
import Overall from "../Report/Overall.js";

const { combine, afterToday } = DateRangePicker;
const predefinedBottomRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()]
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)]
	},

	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()]
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()]
	},

	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
	},
	{
		label: "This year",
		value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
	},
	{
		label: "All time",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
	}
];

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

/**
 * Escapes a CSV field to ensure it is properly formatted.
 * If the field contains a comma, double quote, or newline, it is enclosed in double quotes.
 * Any double quotes in the field are escaped with two double quotes.
 *
 * @param {string} field - The field to escape.
 * @returns {string} - The escaped field.
 */
function escapeCsvField(field) {
	if (field === null || field === undefined) {
		return "";
	}
	let stringField = String(field);
	if (stringField.includes(",") || stringField.includes('"') || stringField.includes("\n")) {
		stringField = `"${stringField.replace(/"/g, '""')}"`;
	}
	return stringField;
}

/**
 * Converts an array of JSON objects to a CSV string.
 *
 * @param {Object[]} jsonData - The JSON data to convert.
 * @param {string[]} columnOrder - The order of the columns in the CSV file.
 * @returns {string} - The CSV string.
 */
function convertJsonToCsv(jsonData, columnOrder) {
	if (!Array.isArray(jsonData) || !Array.isArray(columnOrder)) {
		throw new Error("Invalid input: jsonData and columnOrder must be arrays");
	}

	const csvRows = jsonData.map((obj) => columnOrder.map((key) => escapeCsvField(obj[key])).join(","));

	return [columnOrder.join(",")].concat(csvRows).join("\n");
}

const convertTime = (seconds) => {
	// convert seconds to mm:ss format
	const minutes = Math.floor(seconds / 60);
	let secondsLeft = seconds % 60;
	if (secondsLeft < 10) {
		secondsLeft = `0${secondsLeft}`;
	}
	return `${minutes}:${secondsLeft}`;
};

export function AssignmentActivity() {
	const { axiosLimitedGet } = useAxiosLimited();
	const { tenantId, userLanguage, performTranslation, translateSingleText, translateDictText } = useContext(AppContext);

	const [data, setData] = useState([]);

	const PaginationLocale = {
		fr: {
			more: "Plus",
			prev: "Précédent",
			next: "Suivant",
			first: "Premier",
			last: "Dernier",
			limit: "{0} / page",
			total: "Total lignes: {0}",
			skip: "Aller à {0}"
		},
		en: {
			more: "More",
			prev: "Previous",
			next: "Next",
			first: "First",
			last: "Last",
			limit: "{0} / page",
			total: "Total Rows: {0}",
			skip: "Go to {0}"
		}
	};

	const LocalLanguageDict = {
		AssignmentActivity: "Assignment Activity",
		FilterByEmail: "Filter by email",
		FilterByDate: "Filter by date",
		FilterByTerm: "Filter by term",
		FilterByCourse: "Filter by course",
		FilterBySection: "Filter by section",
		FilterByAssignment: "Filter by assignment",
		AssignmentName: "AssignmentName",
		SimulationTitle: "SimulationTitle",
		Email: "Email",
		DynamicOrNot: "DynamicOrNot",
		Term: "Term",
		Course: "Course",
		Section: "Section",
		Overall: "Overall",
		Date: "Date",
		Duration: "Duration",
		Name: "Name",
		Simulation: "Simulation",
		SearchEmail: "Search email",
		SearchAssignment: "Search assignment",
		SearchTerm: "Search term",
		SearchCourse: "Search course",
		SearchSection: "Search section",
		TotalRows: "Total rows",
		Go: "Go",
		To: "to",
		Page: "Page",
		Of: "of",
		ExportToCSV: "Export to CSV",
		Outcome: "Outcome",
		ReportLink: "ReportLink"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, data]);

	const [translatedRanges, setTranslatedRanges] = useState([]);

	useEffect(() => {
		const translateLabels = async () => {
			const translatedRanges = await Promise.all(
				predefinedBottomRanges.map(async (range) => {
					const translatedLabel = await translateSingleText(range.label, userLanguage);
					return { ...range, label: translatedLabel };
				})
			);
			// Assuming you have a state to hold the translated ranges
			setTranslatedRanges(translatedRanges);
		};

		setTimeout(() => {
			// stale state problem goes away using inner function
			translateLabels();
		}, 1000);
	}, [userLanguage]); // Re-run the effect when userLanguage changes

	// Summary Modal compoment
	const [summary, setSummary] = useState("");

	let clientID = "nTop";
	let isInstage = clientID === "inStage";
	let isDashboard = false;

	if (window.location.href.includes("admin/dashboard")) {
		isInstage = true;
		isDashboard = true;
	}

	if (isInstage) {
		clientID = "Quillworks";
	}

	const [isLoading, setIsLoading] = useState(true);

	const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

	const [submitted, setSubmitted] = useState(false);
	const [emailFilter, setEmailFilter] = useState("");
	const [assignmentFilter, setAssignmentFilter] = useState("");
	const [courseFilter, setCourseFilter] = useState("");
	const [termFilter, setTermFilter] = useState("");
	const [sectionFilter, setSectionFilter] = useState("");

	const [selectedSetupName, setSelectedSetupName] = useState("all");
	const [selectedSetupType, setSelectedTypeDisplay] = useState([{ value: "all", label: "All" }]);
	const [selectedSearchFilter, setSelectedSearchFilter] = useState([]);
	const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
	const [selectedSearchAssignment, setSelectedSearchAssignment] = useState([]);
	const [selectedSearchCourse, setSelectedSearchCourse] = useState([]);
	const [selectedSearchTerm, setSelectedSearchTerm] = useState([]);
	const [selectedSearchSection, setSelectedSearchSection] = useState([]);

	// const selectedSearchFilterZero = selectedSearchFilter.length > 0 ? selectedSearchFilter[0] : null;

	const [emailOptions, setEmailOptions] = useState([]);
	const [setupNameOptions, setSetupNameOptions] = useState([]);
	const [assignmentOptions, setAssignmentOptions] = useState([]);
	const [courseOptions, setCourseOptions] = useState([]);
	const [termOptions, setTermOptions] = useState([]);
	const [sectionOptions, setSectionOptions] = useState([]);
	const [question_summary, setQuestionSummary] = useState([]);

	const [dateFilter, setDateFilter] = React.useState([]);
	const [activePage, setActivePage] = React.useState(1);
	const [totalRecords, setTotalRecords] = React.useState(1);
	const limitPerPage = 10;

	const [errorStatus, setErrorStatus] = useState(null);

	useEffect(() => {
		fetchAssignments();
	}, [selectedSetupName]);

	async function fetchAssignments() {
		try {
			const result = await axiosLimitedGet(`${url}/api/dashboard/filter-assignments`, 1, {
				params: {
					tenantId,
					...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {})
				}
			});
			// console.log(result.data);

			setAssignmentOptions(result.data.assignment_name.map((item) => ({ value: item.assignment_name, label: item.assignment_name })));
			setCourseOptions(result.data.course_options.map((item) => ({ value: item.selectedcourse, label: item.selectedcourse })));
			setTermOptions(result.data.term_options.map((item) => ({ value: item.selectedterm, label: item.selectedterm })));
			setSectionOptions(result.data.section_options.map((item) => ({ value: item.selectedsection, label: item.selectedsection })));
		} catch (error) {
			console.error(error);
			setErrorStatus(error.status);
		}
	}

	// console.log("SetAssignmentOptions", assignmentOptions);
	// console.log("SetCourseOptions", courseOptions);
	const selectedSearchFilterLength = selectedSearchFilter.length;

	const fetchPageData = useCallback(async () => {
		const currentPage = activePage;
		// console.log("Fetching data for page:", currentPage);
		// console.log("dateFilter", dateFilter);
		try {
			setIsLoading(true);
			const result = await axiosLimitedGet(`${url}/api/dashboard/AssignmentActivity`, 1, {
				params: {
					client_id: tenantId,
					page: currentPage,
					limit: 10,
					assignment_name: assignmentFilter,
					startDate: dateFilter && dateFilter[0] ? dateFilter[0] : null, // startDate
					endDate: dateFilter && dateFilter[1] ? dateFilter[1] : null,
					course_options: courseFilter,
					term_options: termFilter,
					section_options: sectionFilter
				}
			});
			// console.log(tenantId);
			// console.log("Results ", result.data);

			setTotalRecords(result.data.totalRecords);
			setData(result.data.data);
			// if (result.data && result.data.data) {
			// 	setAssignmentOptions(
			// 		[...new Set(result.data.data.map((item) => item.assignment_name))].map((name) => ({ value: name, label: name }))
			// 	);
			// } else {
			// 	// Handle the case where data is not available
			// 	setAssignmentOptions([]);
			// 	console.error("Data not available");
			// }
			// setAssignmentOptions(result.data.data.map((item) => ({ value: item.assignment_name, label: item.assignment_name })));
		} catch (error) {
			console.error(error);
			setErrorStatus(error.status);
		} finally {
			setIsLoading(false);
		}
	}, [
		assignmentFilter,
		courseFilter,
		termFilter,
		sectionFilter,
		dateFilter,
		selectedSearchFilterLength,
		selectedSearchCourse,
		selectedSearchAssignment,
		activePage
	]);

	useEffect(() => {
		fetchPageData();
	}, [fetchPageData, activePage]);

	// function to export data to csv it will loop all the pages and fetch data
	async function exportToCSV() {
		setIsDownloadingCSV(true);
		// console.log("Exporting to CSV");
		const columnOrder = [
			`${pageText.AssignmentName}`,
			`${pageText.SimulationTitle}`,
			`${pageText.Email}`,
			`${pageText.DynamicOrNot}`,
			`${pageText.Term}`,
			`${pageText.Course}`,
			`${pageText.Section}`,
			`${pageText.Overall}`,
			`${pageText.ReportLink}`
		];
		let csvData = [];
		const totalPages = Math.ceil(totalRecords / 100);
		for (let i = 1; i <= totalPages; i++) {
			// eslint-disable-next-line no-await-in-loop
			const result = await axiosLimitedGet(`${url}/api/dashboard/AssignmentActivity`, 1, {
				params: {
					client_id: tenantId,
					page: i,
					limit: 100,
					assignment_name: assignmentFilter,
					startDate: dateFilter && dateFilter[0] ? dateFilter[0] : null, // startDate
					endDate: dateFilter && dateFilter[1] ? dateFilter[1] : null,
					course_options: courseFilter,
					term_options: termFilter,
					section_options: sectionFilter
				}
			});
			// console.log(result.data);
			// eslint-disable-next-line
			await new Promise((resolve) => setTimeout(resolve, 300));
			csvData.push(...result.data.data);
		}
		// console.log("CSV data: ", csvData);
		csvData = csvData.map((item) => {
			const setupname = item.full_setup_output?.assignment_name ? item.full_setup_output?.assignment_name : item.setup_name;
			return {
				Email: item.email,
				AssignmentName: item.setup_name || "",
				SimulationTitle: setupname || "",
				DynamicOrNot: item.full_setup_output.assignment_type || "",
				Term: item.assignment_details.selectedTerm || "",
				Course: item.assignment_details.selectedCourse || "",
				Section: item.assignment_details.selectedSection || "",
				Overall: item.overall_score ? item.overall_score : item.outcome ? item.outcome.split(".")[0] : "",
				ReportLink: item.report_link ? `=HYPERLINK("${item.report_link}","Shared Report Link")` : ""
			};
		});
		console.log("CSV data: ", csvData);
		const csvString = convertJsonToCsv(csvData, columnOrder);
		const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
		const csvUrl = URL.createObjectURL(csvBlob);
		const downloadLink = document.createElement("a");
		// auto download
		downloadLink.setAttribute("href", csvUrl);
		downloadLink.setAttribute("download", "instage_assignment_activity.csv"); // specify the download file name
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink); // remove the link when done
		setIsDownloadingCSV(false);
	}

	const filteredData = data || [];
	// console.log("filteredData", filteredData);
	const headers = [
		`${pageText.AssignmentName}`,
		`${pageText.SimulationTitle}`,
		`${pageText.Email}`,
		`${pageText.DynamicOrNot}`,
		`${pageText.Term}`,
		`${pageText.Course}`,
		`${pageText.Section}`,
		`${pageText.Overall}`,
		`${pageText.ReportLink}`
	];

	const columnWidths = {
		Email: "225px",
		Name: "225px",
		Simulation: "300px",
		Date: "100px",
		Duration: "100px",
		Link: "150px",
		Commented: "100px",
		Outcome: "100px",
		Summary: "100px",
		Questions: "115px",
		Process: "115px",
		"Items to Learn": "115px"
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			cursor: "pointer",
			outline: state.isFocused ? "1px solid #00a9af" : "none",
			width: "16rem",
			backgroundColor: "#f1f1f1",
			color: "#303d7c"
		}),
		option: (provided, state) => ({
			...provided,
			cursor: "pointer",
			backgroundColor: state.isFocused ? (state.isSelected ? "#00a9af" : "#00a9af7e") : state.isSelected ? "#00a9af" : null,
			color: state.isSelected ? "#fff" : "#414042",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		})
	};

	return (
		<div className={styles.adminPageContainer}>
			<h1 className={styles.dashboardHeading} style={{ position: "sticky", top: "0", left: "0" }}>
				{pageText.AssignmentActivity}
			</h1>
			{summary !== "" && (
				<ModalWrapper closeModal={() => setSummary("")}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={styles2.modalHeaderIconContainer}>
							<IoDocumentTextSharp className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setSummary("")} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>Summary</h2>
							<p className={styles2.modalText}>{summary}</p>
						</div>
					</div>
				</ModalWrapper>
			)}
			<div className={styles.filterContainer} style={{ position: "sticky", left: "0" }}>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByAssignment}:</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchAssignment}
							styles={customStyles}
							isSearchable
							isClearable
							options={assignmentOptions}
							value={selectedSearchAssignment}
							onChange={(option) => {
								setSelectedSearchAssignment(option);
								setAssignmentFilter(option ? option.value : "");
								// console.log("Selected Option: ", assignmentFilter);
							}}
						/>
					</div>
				</div>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByTerm}:</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchTerm}
							styles={customStyles}
							isSearchable
							isClearable
							options={termOptions}
							value={selectedSearchTerm}
							onChange={(option) => {
								setSelectedSearchTerm(option);
								setTermFilter(option ? option.value : "");
								// console.log("Selected Option: ", courseFilter);
							}}
						/>
					</div>
				</div>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByCourse}:</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchCourse}
							styles={customStyles}
							isSearchable
							isClearable
							options={courseOptions}
							value={selectedSearchCourse}
							onChange={(option) => {
								setSelectedSearchCourse(option);
								setCourseFilter(option ? option.value : "");
								// console.log("Selected Option: ", termFilter);
							}}
						/>
					</div>
				</div>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterBySection}:</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder={pageText.SearchSection}
							styles={customStyles}
							isSearchable
							isClearable
							options={sectionOptions}
							value={selectedSearchSection}
							onChange={(option) => {
								setSelectedSearchSection(option);
								setSectionFilter(option ? option.value : "");
								// console.log("Selected Option: ", sectionFilter);
							}}
						/>
					</div>
				</div>

				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByDate}:</p>
					<div className={styles.reactDropdownSelect}>
						<DateRangePicker
							format="yyyy-MM-dd"
							onChange={(value) => {
								setDateFilter(value);
								setActivePage(1);
							}}
							ranges={translatedRanges}
							showOneCalendar
							shouldDisableDate={afterToday()}
							showHeader={false}
						/>
					</div>
				</div>
			</div>

			{isLoading && !errorStatus && (
				<div className={s.spinnerContainerStart}>
					<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
				</div>
			)}

			{!isLoading && !errorStatus && (
				<BaseTable
					className={styles.tableContainer}
					textAlign="left"
					data={filteredData}
					rowKey="key"
					fixed
					maxHeight={Infinity}
					headerHeight={0}
					headers={headers}
					classNameHeader={styles.tableHeaderRow}
					columnWidths={columnWidths}
				>
					<Column
						title="assignment_name"
						key="assignment_name"
						dataKey="assignment_name"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Email }}>
								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="job_title"
						key="setup_name"
						dataKey="setup_name"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "200px" }}>
								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="email"
						key="email"
						dataKey="email"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: columnWidths.Email }}>
								{props.cellData}
							</div>
						)}
					/>
					<Column
						title="dynamicOrNot"
						key="full_setup_output"
						dataKey="full_setup_output"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "100px" }}>
								{props.cellData.assignment_type}
							</div>
						)}
					/>
					<Column
						title="CourseTerm"
						key="assignment_details"
						dataKey="assignment_details"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "125px" }}>
								{props.cellData ? props.cellData.selectedTerm : ""}
							</div>
						)}
					/>
					<Column
						title="CourseName"
						key="assignment_details"
						dataKey="assignment_details"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "125px" }}>
								{props.cellData ? props.cellData.selectedCourse : ""}
							</div>
						)}
					/>
					<Column
						title="CourseSection"
						key="assignment_details"
						dataKey="assignment_details"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "125px" }}>
								{props.cellData ? props.cellData.selectedSection : ""}
							</div>
						)}
					/>
					<Column
						title="Overall"
						key="overall_score"
						dataKey="overall_score"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "125px" }}>
								{props.cellData ? props.cellData + "/100" : props.rowData.outcome ? props.rowData.outcome.split(".")[0] : ""}
							</div>
						)}
					/>
					<Column
						title="ReportLink"
						key="report_link"
						dataKey="report_link"
						textAlign="center"
						cellRenderer={(props) => (
							<div className={styles.tableData} style={{ width: "125px" }}>
								<a href={props.cellData} target="_blank" rel="noopener noreferrer">
									{props.cellData}
								</a>
							</div>
						)}
					/>
					<Column
						title="link"
						key="session_id"
						dataKey="session_id"
						textAlign="center"
						active={submitted}
						cellRenderer={(props) => {
							let value = props.cellData;
							const currentDomain = window.location.origin;
							const reportLink = `${currentDomain}/admin/report/${obfuscate(value)}`;
							//  console.log(reportLink);
							const reportID = value;
							// make value a clickable link in the to the page /admin/report/value
							value = <ReportUrlCell value={reportID} reportLink={reportLink} />;
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Link }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="comments"
						key="comments"
						dataKey="comments"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							let value;
							if (props.cellData) {
								value = <FiCheck />;
							} else {
								value = <FiX />;
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Comments }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="objective"
						key="objective"
						dataKey="objective"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							let value = props.cellData;
							if (value) {
								value = <FiCheck />;
							} else {
								value = <FiX />;
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Outcome }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="summary"
						key="summary"
						dataKey="summary"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							const value = props.cellData;
							if (value === null) {
								return null;
							}
							return (
								<div
									className={`${styles.tableData} ${styles.summaryCell}`}
									style={{ width: columnWidths.Summary }}
									onClick={() => setSummary(value)}
								>
									{/* {value} */}
									<span className={styles.readSummary}>Read</span>
								</div>
							);
						}}
					/>
					<Column
						title="question_responses_tally"
						key="question_responses_tally"
						dataKey="question_responses_tally"
						active={submitted}
						textAlign="center"
						cellRenderer={(props) => {
							const value = props.cellData;
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Questions }}>
									{value}
								</div>
							);
						}}
					/>

					<Column
						title="process_tally"
						key="process_tally"
						dataKey="process_tally"
						textAlign="center"
						active={submitted && (selectedSetupName === "all" || selectedSetupName === "discovery")}
						width={columnWidths.topic_to_cover}
						cellRenderer={(props) => {
							let value = props.cellData;
							if (!props.cellData) {
								value = "N/A";
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths.Process }}>
									{value}
								</div>
							);
						}}
					/>
					<Column
						title="item_to_learn_tally"
						key="item_to_learn_tally"
						dataKey="item_to_learn_tally"
						textAlign="center"
						active={submitted && (selectedSetupName === "all" || selectedSetupName === "discovery")}
						width={columnWidths.topic_to_learn}
						cellRenderer={(props) => {
							let value = props.cellData;
							if (!props.cellData) {
								value = "N/A";
							}
							return (
								<div className={styles.tableData} style={{ width: columnWidths["Item to Learn"] }}>
									{value}
								</div>
							);
						}}
					/>
				</BaseTable>
			)}
			{errorStatus && errorStatus !== 403 && <div>There was an error fetching the data. Please try again later.</div>}
			{errorStatus && errorStatus === 403 && <div>You do not have access.</div>}
			{!errorStatus && (
				<>
					<Pagination
						ellipsis
						boundaryLinks
						prev
						last
						next
						first
						limit={limitPerPage}
						total={totalRecords}
						activePage={activePage}
						onChangePage={setActivePage}
						maxButtons={5}
						layout={["pager", "skip", "|", "total"]}
						locale={PaginationLocale[userLanguage]}
						style={{ zIndex: "-1" }}
					/>

					{isDownloadingCSV && <LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />}
					{!isDownloadingCSV && (
						<button style={{ width: "unset", zIndex: "-1" }} className={s.dashboardTableBtnStart} type="button" onClick={exportToCSV}>
							{" "}
							{pageText.ExportToCSV}
						</button>
					)}
				</>
			)}
		</div>
	);
}

export function ReportUrlCell({ value, reportLink }) {
	const [isCopied, setIsCopied] = useState(false);

	return (
		<div className={styles.urlCell}>
			<a className={styles.urlButton} target="blank" href={`/admin/report/${obfuscate(value)}`}>
				Go
			</a>

			{isCopied ? (
				<FiCheck
					className={styles.copiedIcon}
					onClick={() => {
						setIsCopied(true);
						navigator.clipboard.writeText(reportLink);
					}}
				/>
			) : (
				<FiCopy
					className={styles.copyIcon}
					onClick={() => {
						setIsCopied(true);
						navigator.clipboard
							.writeText(reportLink)
							.then(() => {
								setIsCopied(true);
								// console.log('URL copied to clipboard:', reportLink);
								setTimeout(() => {
									setIsCopied(false);
								}, 1000); // Reset the "Copied" message after 2 seconds
							})
							.catch((error) => {
								console.error("Failed to copy URL to clipboard:", error);
							});
					}}
				/>
			)}
		</div>
	);
}
