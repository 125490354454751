import "react-app-polyfill/ie9.js";
// import "bootstrap/dist/css/bootstrap.min.css";
import React, { lazy, useState, useEffect, useRef, useCallback } from "react";
import { usePostHog, useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import CryptoJS from "crypto-js";
import { isExpired, decodeToken } from "react-jwt";
import { z } from "zod";
import { BsCreditCard } from "react-icons/bs/index.esm.js";
import { FiActivity, FiBarChart, FiPlus, FiTool } from "react-icons/fi/index.esm.js";
import { MdOutlineVideoLibrary } from "react-icons/md/index.esm.js";
import { Routes, Route, useLocation, useNavigate, useParams, useSearchParams, Navigate } from "react-router-dom";
import { useAuth, useTenantsState, useFeatureEntitlements } from "@frontegg/react";
import axios from "axios";
import { openDB } from "idb";
import { IoClose } from "react-icons/io5/index.esm.js";
import { PiSparkleBold } from "react-icons/pi";
import { MdOutlineAssignmentLate } from "react-icons/md";
import ReactJoyride from "react-joyride";
import PrivateRoute from "./UtilityFunctions/PrivateRoute.jsx";
import { decryptWithAES, setPassphrase } from "./UtilityFunctions/DataUtility.js";
import { AppContext, VideoProvider } from "./UtilityFunctions/AppContext.js";
import { usePageTracking } from "./UtilityFunctions/usePageTracking.js";
import { useAxiosLimited, axiosRetryGet, axiosRetryPost, axiosRetryPut, axiosRetryPatch, axiosRetryDelete } from "./UtilityFunctions/axiosRetry.js";
import { DeviceSettingsProvider } from "./UtilityFunctions/useDeviceSettings.js";

// SECONDARY COMPONENTS
import JoyrideModal from "./SecondaryComponents/JoyrideModal.js";
import PostPreviewModal from "./SecondaryComponents/PostPreviewModal.js";
import BetaTag from "./SecondaryComponents/BetaTag.js";
import NavHeader from "./SecondaryComponents/NavHeader.jsx";
import Sidebar from "./SecondaryComponents/Sidebar.jsx";
import StatusBanner from "./SecondaryComponents/StatusBanner.js";

// PAGE COMPONENTS
import NotFound from "./UtilityFunctions/NotFound.jsx";
import Dashboard from "./Setup/Dashboard.js";
import DeepLink from "./Setup/DeepLink.js";
import Preview from "./Setup/Preview.js";
import Setup from "./Setup/Setup.js";
import Report from "./Report/Report.js";
import SignUpPage from "./Setup/SignUpPage.js";
import SignUpConfirmed from "./Setup/SignUpConfirmed.js";
import LandingPage from "./Setup/LandingPage.js";
import { SimActivity } from "./Admin/SimActivity.js";
import { AssignmentActivity } from "./Admin/AssignmentActivity.js";
import AdminPage from "./Admin/AdminPage.js";
import SimulationStream from "./Simulation/Simulation.js";
import SharePage from "./SharePage.js";
// import PromptTester from "./Admin/PromptTester.js";
import ResumeUpload from "./Setup/ResumeUpload.js";
import Survey from "./SurveyPage.js";
import EmailSubscriptionPage from "./EmailSubscriptionPage.js";
import UnsubscribePage from "./UnsubscribePage.js";
import TestPage from "./TestPage.js";
import ReflectionDataTest from "./Admin/ReflectionDataTest.js";
import { AggregateStatsList } from "./Admin/AggregateStatsList.js";
import AnalyticsDashboard from "./Admin/analytics.js";

import ModelViewer from "./SimulationOld/ModelViewer.js";
import ModelViewer1 from "./SimulationOld/ModelViewerAttempt1.js";

// DATA
import SetupList from "./Setup/SetupList.js";
import AudioTest from "./Admin/audioTest.js";

import styles from "../styleModules/modalStyles.module.css";
import ModalWrapper from "./SecondaryComponents/ModalWrapper.js";

import VideoPlayer from "./Report/VideoPlayer.js";

import logoLarge from "../images/logoLarge.png";
import circles from "../images/circles.png";
import { waitFor } from "@testing-library/react";

// const EmbeddingDashboard = lazy(() => import("./Embeddings/dashboard.js"));
function App() {
	const posthog = usePostHog();
	usePageTracking();
	const backend_url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const getLtik = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("ltik");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	const getLmsToken = () => {
		// console.log("getLmsToken");
		const lmsToken = searchParams.get("token");
		// console.log(lmsToken);
		if (!lmsToken) return false;
		return lmsToken;
	};
	const getLmsAssignmentType = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("assignmentType");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	/**
	 * Navigates to a new path with optional search parameters.
	 *
	 * @param {string} newPath - The new path to navigate to.
	 * @param {boolean} [hard=false] - If true, performs a hard navigation (full page reload).
	 * @param {Object} [newSearchParams={}] - An object containing new search parameters to add to the URL.
	 */
	const handleNavigate = useCallback(
		(newPath, hard = false, newSearchParams = {}) => {
			const updatedSearchParams = new URLSearchParams(searchParams);
			Object.keys(newSearchParams).forEach((key) => {
				updatedSearchParams.set(key, newSearchParams[key]);
			});
			const paramsString = updatedSearchParams.toString();
			const fullPath = paramsString ? `${newPath}?${paramsString}` : newPath;
			if (hard) {
				// console.log("Hard navigating to", fullPath);
				window.location.href = fullPath;
			} else {
				// console.log("Soft navigating to", fullPath);
				navigate(fullPath);
			}
		},
		[navigate, searchParams]
	);
	// GPT STATUS
	const [GPTStatus, setGPTStatus] = useState(true);
	const [statusBannerState, setStatusBannerState] = useState(false);

	const [statusBannerText, setStatusBannerText] = useState(null);

	// USER INFO
	const { user } = useAuth();
	const [localUser, setLocalUser] = useState(null);
	const tenantId = localUser ? localUser.tenantId : "";
	const id = user ? user.id : "";
	const email = user ? user.email : "";
	// const { preferences } = localUser;
	// const { role } = preferences;
	// console.log("role ", role);
	// console.log(localUser);

	const [userLanguage, setUserLanguage] = useState(null);

	const { tenants } = useTenantsState();
	const tenantName = tenants[0]?.name;
	const [tenantMetaData, setTenantMetaData] = useState();
	const [clientType, setClientType] = useState("default");

	const [availableAvatars, setAvailableAvatars] = useState(["David", "Nina", "John", "Myra", "Random"]);

	const tenantPlan = tenantMetaData?.plan ? tenantMetaData.plan : "level2";
	const customFeatures = tenantMetaData?.customFeatures ? tenantMetaData.customFeatures : {};
	useEffect(() => {
		try {
			if (id !== "" && email !== "" && tenantId !== "") {
				// Identify sends an event, so you want may want to limit how often you call it
				posthog?.identify(id, {
					email,
					tenantName,
					tenantId,
					tenantPlan,
					isPreview: false,
					role: localUser?.roles[0]?.key
				});
				// console.log("role ", localUser?.roles[0]?.key);
				// console.log("posthogGroup", "tenant", tenantId, clientType);
				try {
					posthog.group("tenant", tenantId, { tenantName, name: tenantName, plan: tenantPlan, customFeatures, tenantId, type: clientType });
				} catch (error) {
					console.error(error);
				}
			}

			if (localUser?.id && email === "" && tenantId === "") {
				posthog?.identify(localUser.id, {
					isPreview: true
				});
			}
		} catch (e) {
			console.error(e);
		}
	}, [posthog, id, email, tenantId, tenantName, localUser, clientType]);
	const [encryptionKey, setEncryptionKey] = useState(null);

	const [adminAccess, setAdminAccess] = useState(null);
	const [ownerAccess, setOwnerAccess] = useState(null);
	const instageUser = user && user.email.includes("instage.io") ? true : false;
	// const { isEntitled: resumeAssistAccess } = useFeatureEntitlements("resume_assist");
	const resumeAssistAccess = useFeatureFlagEnabled("resumeAssist");
	const { isEntitled: discoverySpinAccess } = useFeatureEntitlements("discovery_spin");

	const bannerPayload = useFeatureFlagPayload("banner-flag");

	// Check userRoles array to see if any of the roles contain the name "Owner" or "Admin"
	// const isOwner = userRoles.some((role) => role.name === "Owner");
	// const isAdmin = userRoles.some((role) => role.name === "Admin");
	// const adminAccess = isOwner || isAdmin ? true : false;

	// TENANT INFO

	const [activeSub, setActiveSub] = useState(true);
	const [sessionList, setSessionList] = useState();
	const [fullSessionHistory, setFullSessionHistory] = useState([]);
	const [resumeData, setResumeData] = useState(null);
	const [coverLetterData, setCoverLetterData] = useState(null);
	const [gptAssistData, setGptAssistData] = useState(null);
	const [gptAssistScore, setGptAssistScore] = useState(null);
	const [lockedModal, setLockedModal] = useState(false);
	const [lockedFeature, setLockedFeature] = useState("");
	const [trialOverModal, setTrialOverModal] = useState(false);
	const [performanceMode, setPerformanceMode] = useState(() => {
		const savedMode = localStorage.getItem("performanceMode");
		return savedMode ? JSON.parse(savedMode) : false;
	});

	// Update localStorage whenever performanceMode changes
	useEffect(() => {
		localStorage.setItem("performanceMode", JSON.stringify(performanceMode));
		// console.log("Performance Mode: ", performanceMode);
	}, [performanceMode]);

	const showLockedModal = (feature) => {
		setLockedFeature(feature);
		setLockedModal(true);
	};

	// ADMIN PAGES
	const [activeAdminLink, setActiveAdminLink] = useState("");
	const adminLinkList = {
		"Create Simulation": {
			component: <Dashboard admin showSimList={false} />,
			icon: <PiSparkleBold />,
			text: "Create a Simulation"
		},
		// "Session List": {
		// 	component: <SimulationList />,
		// 	icon: <MdOutlineVideoLibrary />
		// },
		"Session Activity": {
			component: <SimActivity />,
			icon: <FiActivity />,
			text: "Session Activity"
		},
		"Aggregate Analytics": {
			component: <AggregateStatsList />,
			icon: <FiBarChart />,
			text: "Aggregate Analytics"
		},
		"Assignment Activity": {
			component: <AssignmentActivity />,
			icon: <MdOutlineAssignmentLate />
		},
		"Manage Account": {
			icon: <FiTool />,
			text: "Manage Account"
		},
		...(tenantMetaData &&
			tenantMetaData.stripeId &&
			ownerAccess && {
				Billing: {
					billing: true,
					icon: <BsCreditCard />,
					text: "Billing"
				}
			})
	};

	// LOADED SIM DATA
	const [setupData, setSetupData] = useState();

	// SECONDARY COMPONENT CONTROLS
	const [showSidebar, setShowSidebar] = useState(false);
	const [sidebarContent, setSidebarContent] = useState("");
	const [sidebarContentView, setSidebarContentView] = useState("checklist");
	const [blinkButtons, setBlinkButtons] = useState(false);
	const [activeReport, setActiveReport] = useState(null);
	const [activeReportTab, setActiveReportTab] = useState();

	// SIDEBAR
	const [chatMessages, setChatMessages] = useState([]); // Chat messages with Ava

	const locationPathname = location.pathname;
	useEffect(() => {
		const handleIntercomButtonClick = (event) => {
			// console.log(event.target);
			if (event.target.closest(".intercom-launcher")) {
				// console.log("Intercom button clicked!", Date.now());

				posthog?.identify(id, {
					record: true
				});
			}

			// Track event via Intercom API or another analytics service
			// Assuming Intercom is a global object, otherwise it needs to be imported or defined
			// window.Intercom("trackEvent", "intercom-button-clicked");
		};

		document.body.addEventListener("click", handleIntercomButtonClick);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.body.removeEventListener("click", handleIntercomButtonClick);
		};
	}, [id, posthog]);

	useEffect(() => {
		if (user && locationPathname.includes("accId")) {
			// Remove everything starting from "accId" up to "?" or end of the string
			const newPathname = location.pathname.replace(/\/accId.*?(?=\?|$)/, "");

			// Construct the new URL with preserved query parameters
			const newUrl = `${newPathname}${location.search}`;

			navigate(newUrl);
		}
	}, [user, locationPathname]);

	// useEffect that runs every time the page url changes.
	useEffect(() => {
		// console.log("URL changed");
		// console.log("Location: ", location);

		if (!localUser) return;
		try {
			axiosRetryGet(`${backend_url}/api/banner/active`, 1, { headers: { Authorization: `Bearer ${localUser.accessToken}` } })
				.then((resp) => {
					const { data } = resp;
					// console.log("Banner data: ", data);
					if (data && data.message) {
						setStatusBannerState(true);
						setStatusBannerText(data.message);
					} else {
						setStatusBannerText(null);
						setStatusBannerState(false);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		} catch (e) {
			console.error(e);
		}
	}, [locationPathname, localUser]);

	useEffect(() => {
		const lmsToken = searchParams.get("token");
		const userPayload = z.object({
			tenantId: z.string(),
			sub: z.string(),
			role: z.object({ name: z.string() }),
			email: z.string().email(),
			permissions: z.array(),
			instage_id: z.string(),
			client_fk: z.number(),
			full_name: z.string(),
			account_type: z.string(),
			created_at: z.string().optional(),
			updated_at: z.string().optional(),
			lmsToken: z.unknown()
		});

		async function updateUserIdInDb() {
			const config = { headers: { Authorization: `Bearer ${user.accessToken}` } };
			const response = await axiosRetryPost(`${backend_url}/api/users/check-id`, {}, 1, config);
			// console.log("updateUserIdInDb", response.data);
		}

		async function fetchDecryptionKey(token) {
			const resp = await axiosRetryGet(`${backend_url}/encryption-key`, 1, { headers: { Authorization: `Bearer ${token}` } });

			// console.log("Decryption key response1: ", resp.data);
			const tmp = CryptoJS.enc.Base64.parse(resp.data.encData);
			const decData = tmp.toString(CryptoJS.enc.Utf8);
			const bytes = CryptoJS.AES.decrypt(decData, token).toString(CryptoJS.enc.Utf8);

			//	const bytes = CryptoJS.AES.decrypt(, token);
			// console.log("Decryption key response2: ", bytes);
			const originalText = bytes.toString(CryptoJS.enc.Utf8);
			// console.log("Decryption key response3: ", originalText);

			setPassphrase(originalText);
			setEncryptionKey(originalText);
		}

		async function handleUserState() {
			// console.log("Handling user state...");
			let AccountDB_id;

			// console.log("Local user: ", localUser);

			const db = await initDB();
			AccountDB_id = await db.get("account", "id");
			let localAccessToken = null;
			if (user) {
				await updateUserIdInDb();
				// console.log("User logged in: ", user);
				// Check if AccountDB_id and alias to PostHog --> must only be done once
				// if (AccountDB_id) {
				// 	posthog?.alias(user.id, AccountDB_id);
				// }
				const isOwner = user.roles.some((role) => role.name === "Owner");
				const isAdmin = user.roles.some((role) => role.name === "Admin");
				setAdminAccess(!!(isOwner || isAdmin));
				setOwnerAccess(!!isOwner);
				const params = { instage_id: user.id };
				const config = { params, headers: { Authorization: `Bearer ${user.accessToken}` } };
				await fetchDecryptionKey(user.accessToken);
				const resp = await axiosRetryGet(`${backend_url}/api/users/getUser`, 1, config);

				// return resp.data.resume_details;
				const { resume_details } = resp.data;
				localAccessToken = user.accessToken;
				// console.log("Setting local user... logged in", { id: user.id, resume_details, accessToken: user.accessToken });
				setLocalUser((prevState) => ({ ...prevState, ...user, id: user.id, resume_details }));
			} else if (lmsToken) {
				// console.log("lmsToken", lmsToken);

				const decodedToken = decodeToken(lmsToken);
				// console.log("decodedToken", decodedToken);

				const userValidationResult = userPayload.safeParse(decodedToken);
				if (userValidationResult.success) {
					// console.log("userValidationResult", userValidationResult);
					const userData = userValidationResult.data;
					// console.log("parsedToken", userData);
					const isOwner = userData.role.name === "Owner";
					const isAdmin = userData.role.name === "Admin";
					setAdminAccess(!!(isOwner || isAdmin));
					setOwnerAccess(!!isOwner);
					const params = { instage_id: userData.instage_id };
					const config = { params, headers: { Authorization: `Bearer ${lmsToken}` } };
					await fetchDecryptionKey(lmsToken);
					const resp = await axiosRetryGet(`${backend_url}/api/users/getUser`, 1, config);

					const { resume_details } = resp.data;
					localAccessToken = lmsToken;
					// console.log("Setting local user... logged in", { id: user.id, resume_details, accessToken: user.accessToken });
					setLocalUser((prevState) => ({ ...prevState, accessToken: lmsToken, ...userData, id: userData.instage_id, resume_details }));
				} else {
					// console.log("userValidationResult", userValidationResult);
				}
			} else {
				console.log("not User logged in: ", user);

				const currentPathParts = window.location.pathname.split("/");
				const isPrivateRoute = routes.some((route) => route.path.split("/")[1] === currentPathParts[1] && route.private);

				// console.log("window.location", window.location);
				// console.log("currentPathParts", currentPathParts);
				// console.log("isPrivateRoute", isPrivateRoute);

				if (isPrivateRoute) {
					// console.log("Redirecting to login because a private route.");
					handleNavigate("/account/login", true, { redirectUrl: location.pathname + location.search });
				} else {
					try {
						const config = {}; //
						const body = { instage_id: AccountDB_id };
						if (window.location.pathname.startsWith("/guest")) {
							const clientId = window.location.pathname.split("/")[2];
							// console.log(clientId);
							body.clientId = clientId;
						}
						const response = await axiosRetryPost(`${backend_url}/guest/token`, body, 1, config);
						// console.log("response.data", response.data);

						AccountDB_id = response.data.instage_id;
						await db.put("account", AccountDB_id, "id");
						const { resume_details } = response.data.user;
						const { token: accessToken } = response.data;
						await fetchDecryptionKey(accessToken);

						console.log("Setting local user... guest", { id: AccountDB_id, resume_details, accessToken });
						localAccessToken = accessToken;
						setLocalUser((prevState) => ({ ...prevState, ...response.data.user, id: AccountDB_id, resume_details, accessToken }));
					} catch (error) {
						console.error("Error handling user state:", error);
					}
				}
			}
		}

		handleUserState().catch((err) => console.error("Error handling user state:", err));
	}, []);

	useEffect(() => {
		if (!localUser) return;

		if (window.Intercom) {
			window.Intercom("boot", {
				app_id: "ay3pq49y",
				name: localUser?.name,
				email: localUser?.email,
				user_id: localUser?.id,
				created_in_frontegg: localUser?.createdAt
				// language_override: "en", // Other option could be "es"
			});
		}
		if (localUser?.id && fullSessionHistory.length === 0) {
			// console.log("fetchAndSetFullSessionHistory app.js useEffect", localUser.id, fullSessionHistory.length);
			fetchAndSetFullSessionHistory();
		}
		getUserPreferences();
	}, [localUser, fullSessionHistory]);

	const fetchData = async (url, config) => {
		try {
			const response = await axiosRetryGet(url, 1, config);
			//	console.log("response", response);
			return response.data;
		} catch (error) {
			console.log(url, error);
			throw error;
		}
	};

	const isInitialEffectRunning = useRef(true);

	async function fetchAndSetFullSessionHistory() {
		// console.log("Fetching full session history...", localUser);
		const config = { params: { instage_id: localUser?.id }, headers: { Authorization: `Bearer ${localUser.accessToken}` } };
		const m_fullSessionHistory = await fetchData(`${backend_url}/api/sessionData/list`, config);
		// console.log("m_fullSessionHistory: ", m_fullSessionHistory);
		if (m_fullSessionHistory) {
			const parsedData = JSON.parse(decryptWithAES(m_fullSessionHistory));
			if (parsedData && parsedData.length > 0) {
				setFullSessionHistory(parsedData);
			}
		}
	}

	useEffect(() => {
		const fetchAndSetData = async () => {
			// const statusCheckGPT = await fetchData(`${backend_url}/api/status/CheckGPT`, {
			// 	headers: { Authorization: `Bearer ${localUser.accessToken}` }
			// });
			// setGPTStatus(statusCheckGPT);
			// console.log("after checkGPt");

			// console.log("Getting tenant data...");
			// Get tenant data
			let tenantData;
			try {
				tenantData = await fetchData(`${backend_url}/api/users/GetTenantData`, {
					params: { tenantId },
					headers: { Authorization: `Bearer ${localUser.accessToken}` }
				});
				// console.log("Tenant tenantData: ", tenantData);
			} catch (error) {
				console.error("Error getting tenant data:", error);
			}

			if (tenantData) {
				setTenantMetaData(JSON.parse(tenantData.metadata));
				const metadata = JSON.parse(tenantData.metadata);
				// console.log("Tenant metadata: ", metadata);
				// console.log("Tenant instageMetadata: ", tenantData.instageMetadata);
				if (tenantData.instageMetadata) {
					setClientType(tenantData.instageMetadata.clientType || "default");
					// console.log("ClientType", tenantData.instageMetadata.clientType);
				}
				const customerId = metadata.stripeId;
				if (customerId) {
					// Get Stripe subscription status
					const customerSubs = await fetchData(`${backend_url}/api/users/GetCustomerSubs`, {
						params: { customerId },
						headers: { Authorization: `Bearer ${localUser.accessToken}` }
					});
					if (customerSubs) {
						const parsedData = JSON.parse(decryptWithAES(customerSubs));
						// console.log("Customer subs: ", parsedData)
						const newSub = parsedData.data[0] && (parsedData.data[0].status === "active" || parsedData.data[0].status === "trialing");
						if (newSub !== undefined) {
							setActiveSub(true);
						} else {
							setActiveSub(true);
						}
					}
				}
			} else {
				setTenantMetaData(null);
			}
			isInitialEffectRunning.current = false;
		};
		if (localUser) {
			fetchAndSetData();
		}
	}, [localUser]);

	async function getTrialStatus() {
		// console.log("getTrialStatus");
		// const tenantData = await fetchData(`${backend_url}/api/users/GetTenantData`, { tenantId });
		// console.log("tenantData",tenantData);
		if (tenantMetaData) {
			const metadata = tenantMetaData;
			// console.log("metadata",metadata)
			const { trialFinishDate } = metadata;
			// check if trial is over
			if (trialFinishDate) {
				const trialDate = new Date(trialFinishDate);
				const today = new Date();
				if (today > trialDate) {
					// console.log("Trial is over")
					setActiveSub(false);
					setTrialOverModal(true);
				} else {
					// console.log("Trial is not over")
				}
			}
		}
	}

	// Any time the location includes /report get the latest full session history
	useEffect(() => {
		if (!tenantMetaData) return;

		getTrialStatus(tenantMetaData);
		// fetchAndSetFullSessionHistory();
	}, [tenantMetaData]);

	async function initDB() {
		return openDB("AccountDB", 1, {
			upgrade(db) {
				db.createObjectStore("account");
			}
		});
	}

	async function getUserPreferences() {
		if (localUser) {
			const body = { instage_id: localUser?.id };
			const config = { params: body };
			config.headers = { Authorization: `Bearer ${localUser.accessToken}` };

			// console.log("Getting user preferences...");
			// console.log(`${backend_url}/api/public/booking/GetUserPreferences`);

			try {
				const resp = await axios.get(`${backend_url}/api/users/GetUserPreferences`, config);
				const { data } = resp;

				const { language } = data;
				if (language !== undefined && language !== null) {
					setUserLanguage(language);
					// console.log("Language", language);
				} else {
					setUserLanguage("en");
				}

				if (data && data.redirect_url) {
					const dataToDelete = {
						instage_id: localUser?.id,
						key: "redirect_url"
					};
					// Delete the redirect_url from the database
					const response = await axios.post(`${backend_url}/api/users/DeleteUserPreference`, dataToDelete, config);
					// Check if the deletion was successful before redirecting
					if (response.status === 200) {
						// Redirect to the url
						handleNavigate(data.redirect_url, true);
					}
				}
			} catch (e) {
				console.log(e);
				console.log(e?.response?.data);
			}
		}
	}

	const handleSend = async (message) => {
		const updatedMessages = [...chatMessages, message];

		setChatMessages(updatedMessages);

		const response = await fetch(`${backend_url}/api/chat/AvaChat`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Autorization: "Bearer " + localUser.accessToken
			},
			body: JSON.stringify({
				messages: updatedMessages
			})
		});

		// if response is not ok, throw an error
		if (!response.ok) {
			throw new Error(response.statusText);
		}

		const data = response.body;

		if (!data) {
			return;
		}

		const reader = data.getReader();
		const decoder = new TextDecoder();
		let done = false;
		let isFirst = true;

		while (!done) {
			const { value, done: doneReading } = await reader.read();
			done = doneReading;
			const chunkValue = decoder.decode(value);

			if (isFirst) {
				isFirst = false;
				setChatMessages((chatMessages) => [
					...chatMessages,
					{
						role: "assistant",
						content: chunkValue
					}
				]);
			} else {
				setChatMessages((chatMessages) => {
					const lastMessage = chatMessages[chatMessages.length - 1];
					const updatedMessage = {
						...lastMessage,
						content: lastMessage.content + chunkValue
					};
					return [...chatMessages.slice(0, -1), updatedMessage];
				});
			}
		}
	};

	// SIM SETUP AND SIMULATION
	const [selectedSim, setSelectedSim] = useState("");
	const [activeSessionData, setActiveSessionData] = useState(null);

	// const getLastSession = async (instage_id) => {
	// 	const router = "/api/sessionData/session";

	// 	try {
	// 		const params = {
	// 			instage_id
	// 		};
	// 		const config = { params };
	// 		config.headers = { Authorization: `Bearer ${localUser.accessToken}` };
	// 		console.log("Getting most recent session...");
	// 		const resp = await axiosRetryGet(backend_url + router, 1, config);
	// 		console.log(resp.data);
	// 		return resp.data;
	// 	} catch (e) {
	// 		console.log(e);
	// 		console.error(e);
	// 		return null;
	// 	}
	// };

	// PRODUCT TOURS - JOYRIDE
	const [runTour, setRunTour] = useState(false);
	const [tourKey, setTourKey] = useState(0);
	const [currentTourSteps, setCurrentTourSteps] = useState([]);
	const [showPostPreviewModal, setShowPostPreviewModal] = useState(false);

	const tourSteps = {
		"preview-report": [
			{
				target: "body", // Targeting the body for a centered modal
				placement: "center", // Centers the modal in the viewport
				title: "Session complete! 🎉",
				content: [
					"After each session, you get a feedback report to help you improve your skills for the next time.",
					"Let's quickly review how to use this report."
				]
			},
			{
				target: "#sidebar",
				placement: "auto",
				title: "Transcript",
				content: ["You can read or listen to any part of the conversation."]
			},
			{
				target: "#communication",
				placement: "auto",
				title: "Communication",
				content: ["You can also see how well you communicated during the session."]
			},
			{
				target: "#process",
				placement: "auto",
				title: "Checklist",
				content: ["Here you can see how well you followed the main steps."]
			},
			{
				target: "#overall",
				placement: "auto",
				title: "Overall Performance",
				content: ["Finally, this section provides an overview of your performance in the session."],
				final: true
			}
		]
	};

	const handleJoyrideCallback = (data) => {
		const { status, index, type, action } = data;
		const finishedStatuses = ["finished", "skipped"];

		if (type === "step:after" && index === 1) {
			setActiveReportTab({ id: "communication", title: "Communication" });
		}

		if (type === "step:after" && index === 2) {
			setActiveReportTab({ id: "process", title: "Checklist" });
		}

		if (type === "step:after" && index === 3) {
			setActiveReportTab({ id: "overall", title: "Overall" });
		}

		if (finishedStatuses.includes(status)) {
			// Tour finished or skipped - Show popup after X seconds
			setTimeout(() => {
				try {
					posthog?.capture("instage_preview_report_popup_auto", {
						instage_id: localUser.id
					});
				} catch (error) {
					console.error("Posthog error:", error);
				}
				setShowPostPreviewModal(true);
			}, 6000);
		}
	};

	useEffect(() => {
		if (window.location.href.includes("preview-report")) {
			// Add progress data to each step
			const enrichedSteps = tourSteps["preview-report"].map((step, index, array) => ({
				...step,
				progress: `${index + 1}/${array.length}`
			}));
			setCurrentTourSteps(enrichedSteps);
		}
	}, []);

	function isMobileDevice() {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		const isAndroid = /android/i.test(userAgent);
		const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		// You can adjust the screen width threshold as needed
		const mobileScreenWidthThreshold = 768;

		return isAndroid || isiOS || screenWidth <= mobileScreenWidthThreshold;
	}

	const routes = [
		// ADMIN ROUTES
		// {
		// 	path: "/test-page",
		// 	element: <TestPage />,
		// 	private: false,
		// 	hideHeader: false
		// },
		// {
		// 	path: "/embedding/dashboard/:param1/:param2",
		// 	element: <EmbeddingDashboard />,
		// 	private: true,
		// 	hideHeader: true
		// },
		// {
		// 	path: "/embedding/dashboard",
		// 	element: <EmbeddingDashboard />,
		// 	private: true,
		// 	hideHeader: true
		// },
		{ path: "/modelViewer/:param1", element: <ModelViewer />, private: true },
		{ path: "/modelViewer", element: <ModelViewer />, private: true },
		{ path: "/modelViewer/:param1/:param2", element: <ModelViewer1 />, private: true },
		{
			path: "/admin",
			element: <AdminPage />,
			private: true,
			hideHeader: false
		},
		// {
		// 	path: "/AudioTest",
		// 	element: <AudioTest />,
		// 	private: true,
		// 	hideHeader: false
		// },

		{
			path: "/deeplink",
			element: <DeepLink />,
			private: false,
			hideHeader: false
		},
		{
			path: "/admin/dashboard",
			element: <>{instageUser ? <AnalyticsDashboard /> : <p>Access Denied</p>}</>,
			private: true,
			hideHeader: false
		},
		// {
		// 	path: "/admin/prompt-tester",
		// 	element: <>{instageUser ? <PromptTester /> : <p>Access Denied</p>}</>,
		// 	private: true,
		// 	hideHeader: true,
		// 	hideIntercom: true
		// },

		// GATED ROUTES
		{
			path: "/",
			element: <LandingPage />,
			private: true,
			hideHeader: true
		},
		{ path: "/enterprise", element: <RedirectRoute to={() => "setup/discovery"} />, private: false },
		{
			path: "/admin/report/:obfuscated_gpt_session_id",
			element: (
				<Report setActiveReport={setActiveReport} activeReportTab={activeReportTab} setActiveReportTab={setActiveReportTab} obfuscated />
			),
			private: true
		},
		{
			path: "/super-admin/report/:gpt_session_id",
			element: (
				<Report
					setActiveReport={setActiveReport}
					activeReportTab={activeReportTab}
					setActiveReportTab={setActiveReportTab}
					obfuscated={false}
				/>
			),
			private: true
		},
		{ path: "/practice", element: <RedirectRoute to={() => "dashboard"} />, private: true },
		{ path: "/dashboard", element: <Dashboard />, private: true },

		// Redirect all '/practice/*' routes to '/setup'
		{
			path: "/practice/*",
			element: <RedirectRoute to={({ "*": restOfPath }) => `setup/${restOfPath}`} />,
			private: true
		},
		{ path: "/setup/:setupType", element: <RedirectRoute component={Setup} to={({ setupType }) => `setup/${setupType}`} />, private: true },
		{
			path: "/setup/:setupType/:savedId",
			element: <RedirectRoute component={Setup} to={({ setupType, savedId }) => `setup/${setupType}/${savedId}`} />,
			private: true
		},

		{
			path: "/simulation",
			element: <SimulationStream />,
			private: true,
			hideHeader: false
		},

		{
			path: "/simulation/:setupType/:savedId/:simId",
			element: (
				<RedirectRoute component={SimulationStream} to={({ setupType, savedId, simId }) => `simulation/${setupType}/${savedId}/${simId}`} />
			),
			private: true,
			hideHeader: false
		},
		{ path: "/share/*", element: <SharePage />, private: true },
		{ path: "/survey-member", element: <Survey />, private: false },

		{ path: "/survey-admin", element: <Survey />, private: false },

		{ path: "/email-preferences", element: <EmailSubscriptionPage />, private: true },
		{ path: "/unsubscribe/:instage_id", element: <UnsubscribePage />, private: false },

		// OPEN ROUTES

		{ path: "/reflection-data-test", element: <ReflectionDataTest />, private: false },

		/// FREE PREVIEW

		{ path: "/guest-setup/:clientId", element: <Setup setupType="interview" />, private: false },
		{ path: "/guest-setup/:clientId/:savedId", element: <Setup setupType="interview" />, private: false },
		{ path: "/guest-simulation/:clientId/:savedId/:simId", element: <SimulationStream setupType="interview" />, private: false },

		{ path: "/preview-setup", element: <Preview />, private: false },
		{
			path: "/preview-simulation/:setupType/:savedId/:simId",
			element: (
				<RedirectRoute
					component={SimulationStream}
					to={({ setupType, savedId, simId }) => `preview-simulation/${setupType}/${savedId}/${simId}`}
				/>
			),
			private: false,
			hideHeader: false
		},
		{
			path: "/unsubscribe/:instage_id",
			element: <UnsubscribePage />,
			private: false
		},
		{
			path: "/unsubscribe-preferences",
			element: <EmailSubscriptionPage />,
			private: true
		},
		{
			path: "/survey-admin",
			element: <Survey />,
			private: false
		},
		{
			path: "/survey-member",
			element: <Survey />,
			private: false
		},
		{
			path: "/signup/:clientId",
			element: <SignUpPage />,
			private: false,
			hideHeader: true
		},
		{
			path: "/signup/:clientId/:redirect",
			element: <SignUpPage />,
			private: false,
			hideHeader: true
		},
		{
			path: "/signup-confirmed",
			element: <SignUpConfirmed />,
			private: false,
			hideHeader: true
		},

		{
			path: "/share-report/:obfuscated_gpt_session_id",
			element: (
				<Report setActiveReport={setActiveReport} activeReportTab={activeReportTab} setActiveReportTab={setActiveReportTab} obfuscated />
			),
			private: false
		},
		{
			path: "/report/:obfuscated_gpt_session_id",
			element: (
				<Report setActiveReport={setActiveReport} activeReportTab={activeReportTab} setActiveReportTab={setActiveReportTab} obfuscated />
			),
			private: false
		},
		{
			path: "/preview-report/:obfuscated_gpt_session_id",
			element: (
				<Report setActiveReport={setActiveReport} activeReportTab={activeReportTab} setActiveReportTab={setActiveReportTab} obfuscated />
			),
			private: false
		},
		{ path: "/pen", element: <Navigate to="/" replace />, private: false },

		// Redirect all '/preview/*' routes to '/setup'
		{
			path: "/preview/*",
			element: <RedirectRoute to={({ "*": restOfPath }) => `setup/${restOfPath}`} />,
			private: true
		},

		// CATCH-ALL ROUTE. KEEP AT BOTTOM OF ROUTES
		{
			path: "*",
			element: <NotFound />,
			private: false,
			hideHeader: true
		}
	];
	const pauseSimRef = useRef({});
	const translationCache = useRef({});

	async function performTranslation(pageText, setPageText) {
		const keys = Object.keys(pageText);
		const values = Object.values(pageText);
		if (!values || values.length === 0) {
			console.error("values is undefined or empty");
			return;
		}

		// Filter out texts that are already translated and cached
		const textsToTranslate = values.filter((text, index) => !translationCache.current[`${keys[index]}_${userLanguage}`]);

		if (textsToTranslate.length === 0) {
			// console.log("Using cached translations");
			const cachedTexts = values.map((text, index) => translationCache.current[`${keys[index]}_${userLanguage}`] || text);
			setPageText(Object.fromEntries(keys.map((key, index) => [key, cachedTexts[index]])));
			return;
		}

		try {
			// console.log("Translating texts:", textsToTranslate);
			const translatedTexts = await translateDictText(textsToTranslate, userLanguage);
			let tempPageText = {};
			for (let i = 0; i < keys.length; i++) {
				let key = keys[i];
				// Update with translated text if available, otherwise use original
				const translatedText = translationCache.current[`${key}_${userLanguage}`] || translatedTexts[textsToTranslate.indexOf(values[i])];
				tempPageText[key] = translatedText || values[i];
				// Update the cache with new translations
				translationCache.current[`${key}_${userLanguage}`] = translatedText;
			}
			setPageText(tempPageText);
		} catch (error) {
			console.error("Error translating texts:", error);
		}
	}

	async function translateSingleText(text, targetLanguage) {
		try {
			// if (targetLanguage === "en") return text;
			const response = await axiosRetryGet(`${backend_url}/api/translate`, 1, {
				params: {
					texts: [text], // Wrap the text in an array
					targetLanguage: targetLanguage // Use the function parameter
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			return response.data[0];
		} catch (err) {
			console.error("Translation failed: ", err);
			return text; // Return the original text if translation fails
		}
	}

	async function translateDictText(texts, targetLanguage) {
		try {
			if (userLanguage === "en" && targetLanguage === "en") return texts;
			// console.log("TranslatingDict");
			// console.log("Translating");
			const response = await axiosRetryGet(`${backend_url}/api/translate`, 1, {
				params: {
					texts: texts,
					targetLanguage
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			return response.data;
		} catch (err) {
			console.error("Translation failed: ", err);
			return texts; // Return the original texts if translation fails
		}
	}

	function runTranslation() {
		const translateTexts = async (texts, targetLanguage) => {
			// console.log("TranslatingTexts");
			// Filter out texts that are already translated and cached
			const textsToTranslate = texts.filter((text) => !translationCache.current[`${text}_${targetLanguage}`]);

			if (textsToTranslate.length === 0) {
				return texts.map((text) => translationCache.current[`${text}_${targetLanguage}`] || text);
			}

			try {
				const response = await axiosRetryGet(`${backend_url}/api/translate`, 1, {
					params: {
						texts: textsToTranslate,
						targetLanguage
					},
					headers: { Authorization: `Bearer ${localUser.accessToken}` }
				});
				const translatedTexts = response.data;

				// Update the cache with new translations
				textsToTranslate.forEach((text, index) => {
					translationCache.current[`${text}_${targetLanguage}`] = translatedTexts[index];
				});

				// console.log("Translated response:", translatedTexts);
				return texts.map((text) => translationCache.current[`${text}_${targetLanguage}`] || text);
			} catch (error) {
				console.error("Error translating texts:", error);
				return texts; // Return the original texts if translation fails
			}
		};

		const applyTranslations = async (selectors) => {
			const targetLanguage = userLanguage; // Set your target language here

			if (!targetLanguage || targetLanguage === "en") return;

			const elementsToSkip = [
				"overall",
				"question_summary",
				"communication",
				"question_responses",
				"process",
				"outcomeContainer",
				"scoreText",
				"outcomeText",
				"outcomeIconCircle",
				"outcomeIcon"
			]; // Add the IDs of elements you want to skip

			for (let i = 0; i < selectors.length; i++) {
				const selector = selectors[i];
				let elements = document.querySelectorAll(selector);
				elements = Array.from(elements).filter((element) => element.innerText.trim() !== "" && !elementsToSkip.includes(element.id));
				const originalTexts = Array.from(elements).map((element) => {
					// Specifically handle button elements to translate only direct text nodes
					if (element.tagName === "BUTTON") {
						return Array.from(element.childNodes)
							.filter((node) => node.nodeType === Node.TEXT_NODE)
							.map((node) => node.nodeValue.trim())
							.join(" ");
					}
					return element.innerText;
				});

				if (targetLanguage === "en") return;
				// eslint-disable-next-line no-await-in-loop
				const translatedTexts = await translateTexts(originalTexts, targetLanguage);
				// console.log("Original texts:", originalTexts, "Translated texts:", translatedTexts);

				elements.forEach((element, index) => {
					if (element.tagName === "BUTTON") {
						// Update only the text nodes for buttons
						let textNodeIndex = 0;
						Array.from(element.childNodes).forEach((node) => {
							if (node.nodeType === Node.TEXT_NODE) {
								node.nodeValue = translatedTexts[index].split(" ")[textNodeIndex++] || node.nodeValue;
							}
						});
					} else {
						element.innerText = translatedTexts[index] || element.innerText;
					}
				});
			}
		};

		const selectors = ["option", "h1", "h2", "h3", "h4", "p", "span", "button", "li"];
		const ids = ["addQuestionText"];
		applyTranslations(selectors, ids);
	}

	const locationHref = window.location.href;
	const isDeepLink = locationHref.includes("deeplink");
	// useEffect(() => {
	// 	runTranslation();
	// });
	return (
		<AppContext.Provider
			value={{
				handleNavigate,
				runTranslation,
				encryptionKey,
				pauseSimRef,
				setGPTStatus,
				user,
				localUser,
				setLocalUser,
				userLanguage,
				setUserLanguage,
				tenantId,
				tenantName,
				tenantMetaData,
				clientType,
				tenantPlan,
				customFeatures,
				adminAccess,
				setAdminAccess,
				instageUser,
				runTour,
				setRunTour,
				fullSessionHistory,
				fetchAndSetFullSessionHistory,
				sessionList,
				setSessionList,
				SetupList,
				setupData,
				setSetupData,
				activeSub,
				showSidebar,
				setShowSidebar,
				sidebarContent,
				setSidebarContent,
				sidebarContentView,
				setSidebarContentView,
				blinkButtons,
				setBlinkButtons,
				chatMessages,
				setChatMessages,
				selectedSim,
				setSelectedSim,
				activeSessionData,
				setActiveSessionData,
				isMobileDevice,
				adminLinkList,
				activeAdminLink,
				setActiveAdminLink,
				lockedModal,
				showLockedModal,
				resumeData,
				setResumeData,
				coverLetterData,
				setCoverLetterData,
				gptAssistData,
				setGptAssistData,
				gptAssistScore,
				setGptAssistScore,
				translateSingleText,
				translateDictText,
				performTranslation,
				setAvailableAvatars,
				availableAvatars,
				performanceMode,
				setPerformanceMode,

				// Feature flags
				resumeAssistAccess,
				discoverySpinAccess
			}}
		>
			<DeviceSettingsProvider>
				<VideoProvider>
					<>
						<ReactJoyride
							steps={currentTourSteps}
							run={runTour}
							continuous={true}
							scrollToFirstStep={true}
							showProgress={true}
							showSkipButton={true}
							styles={{
								options: {
									zIndex: 10000
								}
							}}
							tooltipComponent={JoyrideModal}
							callback={handleJoyrideCallback}
						/>
						{showPostPreviewModal && <PostPreviewModal onClose={() => setShowPostPreviewModal(false)} />}
						{lockedModal && (
							<ModalWrapper closeModal={() => setLockedModal(false)}>
								<div className={styles.modal}>
									<span className={styles.modalCloseIcon}>
										<IoClose title="Close icon" onClick={() => setLockedModal(false)} />
									</span>
									<div className={styles.modalContent}>
										<div className={styles.modalTitleContainer}>
											<img className={styles.modalTitleLogo} src={logoLarge} alt="InStage Logo" />
											<h1 className={styles.modalTitleLogoText}>PRO</h1>
										</div>
										<p className={styles.modalText} style={{ textAlign: "center" }}>
											Upgrade to Pro to unlock access to the
											{lockedFeature}.
										</p>
									</div>
									<div className={styles.modalBtnContainer}>
										<button
											type="button"
											className={styles.modalUpgradeBtn}
											onClick={() => {
												setLockedModal(false);
												if (window.Intercom) {
													window.Intercom("showNewMessage");
												}
											}}
										>
											Contact Sales
										</button>
									</div>
								</div>
							</ModalWrapper>
						)}
						{trialOverModal && (
							<ModalWrapper
								closeModal={() => {
									setTrialOverModal(false);
								}}
							>
								<div className={styles.modal}>
									<span className={styles.modalCloseIcon}>
										<IoClose title="Close icon" onClick={() => setTrialOverModal(false)} />
									</span>
									<div className={styles.modalContent}>
										<div className={styles.modalTitleContainer}>
											<img className={styles.modalTitleLogo} src={logoLarge} alt="InStage Logo" />
										</div>
										<p className={styles.modalText} style={{ textAlign: "center" }}>
											Your trial has expired.
										</p>
									</div>
									<div className={styles.modalBtnContainer}>
										<button
											type="button"
											className={styles.modalUpgradeBtn}
											onClick={() => {
												setTrialOverModal(false);
												window.Intercom("showNewMessage");
											}}
										>
											Contact Sales
										</button>
									</div>
								</div>
							</ModalWrapper>
						)}
						{bannerPayload && <StatusBanner statusBannerText={bannerPayload.bannerText} />}
						{/* {statusBannerState && <StatusBanner statusBannerText={statusBannerText ? statusBannerText : undefined} />} */}
						{localUser && userLanguage && (
							<div style={{ display: "flex" }}>
								<Sidebar onSend={handleSend} activeReport={activeReport} />

								<Routes>
									{routes.map((route, index) => (
										<Route
											key={index}
											path={route.path}
											element={
												<>
													{!route.hideHeader && !isDeepLink && !getLmsAssignmentType() && (
														<NavHeader
															setActiveReport={setActiveReport}
															activeReport={activeReport}
															setShowPostPreviewModal={setShowPostPreviewModal}
														/>
													)}
													{route.private ? (
														<PrivateRoute localUser={localUser}>{route.element}</PrivateRoute>
													) : (
														<>{route.element}</>
													)}
												</>
											}
										/>
									))}
								</Routes>

								{location.pathname.includes("report") && <VideoPlayer />}
							</div>
						)}
					</>
				</VideoProvider>
			</DeviceSettingsProvider>
		</AppContext.Provider>
	);
}

function RedirectRoute({ component: Component, to }) {
	const params = useParams();
	const navigate = useNavigate();

	// Construct new path outside useEffect
	const newParams = { ...params };
	if (newParams.setupType && newParams.setupType.endsWith("_custom")) {
		newParams.setupType = newParams.setupType.slice(0, -7);
	}
	const newPath = `/${to(newParams)}`;

	useEffect(() => {
		// Navigate only if the newPath is different from the current path
		if (newPath !== window.location.pathname) {
			navigate(newPath, { replace: true });
		}
	}, [navigate, newPath]); // Depend on newPath

	// Render the component if no redirection is needed
	return Component ? <Component {...params} /> : null;
}

export default App;
