import React, { useEffect, useState, createContext, useContext, useRef, useMemo } from "react";
import { BsStars, BsArrowUpRightCircle } from "react-icons/bs";
import { FiChevronRight, FiInfo, FiStar, FiExternalLink } from "react-icons/fi/index.esm.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { usePostHog } from "posthog-js/react";
import styles from "../../styleModules/generateStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { AudienceContainer } from "./AudienceContainer.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import ResumeUpload from "./ResumeUpload.js";
import ResumeSection from "./ResumeSection.js";
import ResumeScoreSection from "./ResumeScoreSection.js";
import ThumbButton from "./ThumbButton.js";
import { useSendScore, GptAssistFeedbackModal } from "./SetupElements/SetupModals.js";
import SetupDetails from "./SetupDetails.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

const TooltipContext = createContext();

export const useTooltip = () => useContext(TooltipContext);

export default function JobDetails({
	getLmsAssignmentType,
	setupData,
	typingRefs1,
	audienceOptions,
	personalityOptions,
	personality,
	audience,
	personalities,
	audienceRole,
	detailsArray,
	tabList,
	activeTab,
	setActiveTab,
	setShowEditModal,
	hoveredAvatar,
	setHoveredAvatar,
	handlePersonalityChange,
	changeAvatar
}) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const sendScore = useSendScore();
	const {
		localUser,
		resumeData,
		setResumeData,
		coverLetterData,
		setCoverLetterData,
		gptAssistData,
		setGptAssistData,
		gptAssistScore,
		setGptAssistScore,
		userLanguage,
		performTranslation,
		translateDictText
	} = useContext(AppContext);
	const posthog = usePostHog();

	// Static Translation
	const LocalLanguageDict = {
		Regenerate: "Regenerate",
		SaveChangesToRegenerate: "Save changes to regenerate",
		SummarySection: "Summary",
		ExperienceSection: "Experience",
		SkillsSection: "Skills",
		EducationSection: "Education",
		VolunteerSection: "Volunteer",
		ExtracurricularSection: "Extracurricular",
		CVIntro: "Intro",
		CVBody: "Body"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	useEffect(() => {
		// console.log("ResumeData", resumeData);
	}, [resumeData]);

	// Refs
	const resumeApiCallLockRef = useRef(false);
	const coverLetterApiCallLockRef = useRef(false);

	// States
	const [showMoreJobPost, setShowMoreJobPost] = useState(false);
	const [activeResumeSection, setActiveResumeSection] = useState("summary");
	const [activeCoverLetterSection, setActiveCoverLetterSection] = useState("intro");
	const [gptScoreLoading, setGptScoreLoading] = useState(false);
	const [allFeedbackLoading, setAllFeedbackLoading] = useState(false);
	const [sectionFeedbackLoading, setSectionFeedbackLoading] = useState({
		summary: false,
		experience: false,
		skills: false,
		education: false,
		intro: false,
		body: false
	});
	const [sectionRewriteLoading, setSectionRewriteLoading] = useState({
		summary: false,
		experience: false,
		skills: false,
		education: false,
		intro: false,
		body: false
	});
	const [tempResumeData, setTempResumeData] = useState(resumeData);
	const [tempCoverLetterData, setTempCoverLetterData] = useState(coverLetterData);
	const [resumeDataChanged, setResumeDataChanged] = useState(false);
	const [coverLetterDataChanged, setCoverLetterDataChanged] = useState(false);
	const [editMode, setEditMode] = useState({
		summary: false,
		experience: false,
		skills: false,
		education: false,
		intro: false,
		body: false
	});
	const [activeTooltip, setActiveTooltip] = useState(null);

	// Constants // These Need to be in the same order as the resumeSectionsText
	const resumeSections = ["summary", "experience", "skills", "education", "volunteer", "extracurricular"];
	const resumeSectionsText = [
		pageText.SummarySection,
		pageText.ExperienceSection,
		pageText.SkillsSection,
		pageText.EducationSection,
		pageText.VolunteerSection,
		pageText.ExtracurricularSection
	];
	const coverLetterSections = ["intro", "body"];
	const coverLetterSectionsText = [pageText.CVIntro, pageText.CVBody];

	// Feedback
	const [isThumbClicked, setIsThumbClicked] = useState({
		resume_score_summary: null,
		resume_score_experience: null,
		resume_score_skills: null,
		resume_score_education: null,
		resume_strengths: null,
		resume_weaknesses: null,
		cover_letter_score_intro: null,
		cover_letter_score_body: null,
		cover_letter_strengths: null,
		cover_letter_weaknesses: null
	});
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [feedbackName, setFeedbackName] = useState(null);
	const [feedbackStatus, setFeedbackStatus] = useState(null);
	const [selectedPromptId, setSelectedPromptId] = useState(null);

	const tooltipValue = useMemo(() => ({ activeTooltip, setActiveTooltip }), [activeTooltip, setActiveTooltip]);
	const resumeScore = useMemo(() => {
		if (gptAssistScore) {
			const sections = ["education", "experience", "skills", "summary"];
			const totalScore = sections.reduce((total, section) => {
				if (gptAssistScore[section]) {
					return total + Object.values(gptAssistScore[section]).reduce((a, b) => a + b, 0);
				}
				return total;
			}, 0);
			const totalLength = sections.reduce((total, section) => {
				if (gptAssistScore[section]) {
					return total + Object.keys(gptAssistScore[section]).length;
				}
				return total;
			}, 0);
			return Math.round((totalScore / (totalLength * 2)) * 100);
		}
		return null;
	}, [gptAssistScore]);
	const coverLetterScore = useMemo(() => {
		if (gptAssistScore) {
			const sections = ["intro", "body"];
			const totalScore = sections.reduce((total, section) => {
				if (gptAssistScore[section]) {
					return total + Object.values(gptAssistScore[section]).reduce((a, b) => a + b, 0);
				}
				return total;
			}, 0);
			const totalLength = sections.reduce((total, section) => {
				if (gptAssistScore[section]) {
					return total + Object.keys(gptAssistScore[section]).length;
				}
				return total;
			}, 0);
			return Math.round((totalScore / (totalLength * 2)) * 100);
		}
		return null;
	}, [gptAssistScore]);

	const getColor = (score) => {
		if (score > 75) return "#4CAF50"; // Green
		if (score > 50) return "#f1c40f"; // Gold
		if (score > 25) return "#FF8C00"; // Dark Orange
		return "#7D7D7D"; // Grey
	};

	// Function to toggle edit mode
	const toggleEdit = (section) => {
		setEditMode((prev) => ({ ...prev, [section]: !prev[section] }));
	};

	// Function to handle copying gpt assist data into textarea and update the state
	const handleCopy = (section, value, tempDataSetter) => {
		tempDataSetter((prev) => ({
			...prev,
			[section]: value
		}));
		setEditMode((prev) => ({ ...prev, [section]: true }));
	};

	// Function to handle temporary changes in textarea
	const handleTempUpdate = (section, value, tempDataSetter) => {
		tempDataSetter((prev) => ({
			...prev,
			[section]: value
		}));
	};

	// Function to save changes to the database
	const handleSave = async (section, dataSetter, dataStatusSetter) => {
		// console.log("Saving changes to the database");
		const isCoverLetterSection = section === "intro" || section === "body";
		try {
			const saveData = {
				setup_id: setupData.simulation_id,
				instage_id: localUser.id,
				resume_details: tempResumeData,
				cover_letter_details: tempCoverLetterData,
				isCoverLetterSection
			};

			const response = await axiosLimitedPost(`${url}/api/resume-assist/update`, saveData, 1);
			if (isCoverLetterSection) {
				dataSetter(response.data.cover_letter_details);
			} else {
				dataSetter(response.data.resume_details);
			}
			dataStatusSetter(true);
			setEditMode((prev) => ({ ...prev, [section]: false }));
		} catch (error) {
			console.error("Error saving changes:", error);
		}
	};

	const handleThumbClickScore = async (category, status, docType) => {
		let scoreName = category;
		if (category === "strengths" || category === "weaknesses") {
			scoreName = `${docType}_${category}`;
		}

		setIsThumbClicked((prevState) => ({
			...prevState,
			[scoreName]: status
		}));

		setFeedbackStatus(status);
		setShowFeedbackModal(true);
		setFeedbackName(scoreName);

		const request_id = docType === "resume" ? gptAssistScore.resume_score_request_id : gptAssistScore.cover_letter_score_request_id;

		// Reset Prompt request metadata
		const feedbackData = {
			pl_request_id: request_id,
			metadata: {
				date: "",
				[scoreName]: ""
			}
		};

		try {
			await axiosLimitedPost(`${url}/api/promptlayer/feedback`, feedbackData, 1, {}, 30000);
		} catch (err) {
			console.log(err);
		}

		if (request_id) {
			setSelectedPromptId(request_id);
			sendScore(request_id, status, scoreName);
		}
	};

	const runResumeScore = async () => {
		if (gptScoreLoading) return; // Early return if an API call is already in progress

		setGptAssistScore(null);
		setGptScoreLoading(true);
		resumeApiCallLockRef.current = true; // Set lock to true to prevent concurrent executions

		const assistData = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_title: setupData.job_title,
			experience_level: setupData.experience_level,
			industry: setupData.industry,
			job_function: setupData.job_function,
			main_responsibilities: setupData.main_responsibilities,
			short_summary: setupData.short_summary,
			skills_required: setupData.skills_required
		};

		try {
			// console.log("Getting resume score...");
			const response = await axiosLimitedPost(`${url}/api/resume-assist/runResumeScore`, assistData, 1, {}, 60000);
			posthog?.capture("instage_resume_score", { setup_id: setupData.simulation_id, instage_id: localUser.id });
			setGptAssistScore(response.data);
		} catch (error) {
			console.error("Error running GPT Assist:", error);
		} finally {
			setResumeDataChanged(false);
			setGptScoreLoading(false);
			resumeApiCallLockRef.current = false; // Release lock after API call completes
		}
	};

	const runCoverLetterScore = async () => {
		if (gptScoreLoading) return; // Early return if an API call is already in progress

		setGptAssistScore(null);
		setGptScoreLoading(true);
		coverLetterApiCallLockRef.current = true; // Set lock to true to prevent concurrent executions

		const assistData = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_title: setupData.job_title,
			experience_level: setupData.experience_level,
			industry: setupData.industry,
			job_function: setupData.job_function,
			main_responsibilities: setupData.main_responsibilities,
			short_summary: setupData.short_summary,
			skills_required: setupData.skills_required
		};

		try {
			// console.log("Getting cover letter score...");
			const response = await axiosLimitedPost(`${url}/api/resume-assist/runCoverLetterScore`, assistData, 1, {}, 60000);
			posthog?.capture("instage_cover_letter_score", { setup_id: setupData.simulation_id, instage_id: localUser.id });
			setGptAssistScore(response.data);
		} catch (error) {
			console.error("Error running GPT Assist:", error);
		} finally {
			setCoverLetterDataChanged(false);
			setGptScoreLoading(false);
			coverLetterApiCallLockRef.current = false; // Release lock after API call completes
		}
	};

	const resumeFeedbackSection = async (section) => {
		setSectionFeedbackLoading((prev) => ({ ...prev, [section]: true }));
		const isCoverLetterSection = section === "intro" || section === "body";

		const assistData = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			section
		};

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/resumeFeedbackSection`, assistData, 1, {}, 60000);

			if (isCoverLetterSection) {
				posthog?.capture("instage_cover_letter_feedback", { setup_id: setupData.simulation_id, instage_id: localUser.id, section });
			} else {
				posthog?.capture("instage_resume_feedback", { setup_id: setupData.simulation_id, instage_id: localUser.id, section });
			}

			setGptAssistData((prevData) => ({
				...prevData,
				[section]: {
					...prevData[section],
					feedback: response.data[section].feedback,
					feedback_request_id: response.data[section].feedback_request_id
				}
			}));
		} catch (error) {
			console.error("Error running GPT Feedback:", error);
		} finally {
			// Set loading false for the specific section
			setSectionFeedbackLoading((prev) => ({ ...prev, [section]: false }));
		}
	};

	const resumeRewriteSection = async (section, style) => {
		setSectionRewriteLoading((prev) => ({ ...prev, [section]: true }));
		const isCoverLetterSection = section === "intro" || section === "body";

		const assistData = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			section,
			feedback: gptAssistData?.[section]?.feedback,
			rewrite: gptAssistData?.[section]?.rewrite,
			style,
			job_details: detailsArray
		};

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/resumeRewriteSection`, assistData, 1, {}, 60000);

			if (isCoverLetterSection) {
				posthog?.capture("instage_cover_letter_rewrite", { setup_id: setupData.simulation_id, instage_id: localUser.id, section, style });
			} else {
				posthog?.capture("instage_resume_rewrite", { setup_id: setupData.simulation_id, instage_id: localUser.id, section, style });
			}

			setGptAssistData((prevData) => ({
				...prevData,
				[section]: {
					...prevData[section],
					rewrite: response.data[section].rewrite,
					rewrite_request_id: response.data[section].rewrite_request_id
				}
			}));
		} catch (error) {
			console.error("Error running GPT Rewrite:", error);
		} finally {
			// Set loading false for the specific section
			setSectionRewriteLoading((prev) => ({ ...prev, [section]: false }));
		}
	};

	const runCombinedResumeAssist = async () => {
		if (allFeedbackLoading) return; // Early return if an API call is already in progress

		setAllFeedbackLoading(true);
		resumeApiCallLockRef.current = true; // Set lock to true to prevent concurrent executions

		const assistDataForScore = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_title: setupData.job_title,
			experience_level: setupData.experience_level,
			industry: setupData.industry,
			job_function: setupData.job_function,
			main_responsibilities: setupData.main_responsibilities,
			short_summary: setupData.short_summary,
			skills_required: setupData.skills_required
		};

		const assistDataForAll = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_details: detailsArray
		};

		try {
			// console.log("Running GPT Assist for resume score and all sections...");
			const [scoreResponse, allResponse] = await Promise.all([
				axiosLimitedPost(`${url}/api/resume-assist/runResumeScore`, assistDataForScore, 1, {}, 60000),
				axiosLimitedPost(`${url}/api/resume-assist/resumeFeedbackAll`, assistDataForAll, 1, {}, 60000)
			]);
			// console.log("runCombinedResumeAssist: ", scoreResponse.data, allResponse.data);

			// Update states with the responses
			setGptAssistData(allResponse.data);
			setGptAssistScore(scoreResponse.data);
		} catch (error) {
			console.error("Error running combined GPT Assist for resume:", error);
		} finally {
			setAllFeedbackLoading(false);
			resumeApiCallLockRef.current = false; // Release lock after API call completes
		}
	};

	const runCombinedCoverLetterAssist = async () => {
		if (allFeedbackLoading) return; // Early return if an API call is already in progress

		setAllFeedbackLoading(true);
		coverLetterApiCallLockRef.current = true; // Set lock to true to prevent concurrent executions

		const assistDataForScore = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_title: setupData.job_title,
			experience_level: setupData.experience_level,
			industry: setupData.industry,
			job_function: setupData.job_function,
			main_responsibilities: setupData.main_responsibilities,
			short_summary: setupData.short_summary,
			skills_required: setupData.skills_required
		};

		const assistDataForAll = {
			setup_id: setupData.simulation_id,
			instage_id: localUser.id,
			job_details: detailsArray
		};

		try {
			// 	console.log("Running GPT Assist for cover letter score and all sections...");
			const [scoreResponse, allResponse] = await Promise.all([
				axiosLimitedPost(`${url}/api/resume-assist/runCoverLetterScore`, assistDataForScore, 1, {}, 60000),
				axiosLimitedPost(`${url}/api/resume-assist/coverLetterFeedbackAll`, assistDataForAll, 1, {}, 60000)
			]);
			// console.log("runCombinedCoverLetterAssist: ", scoreResponse.data, allResponse.data);

			// Update states with the responses
			setGptAssistData(allResponse.data);
			setGptAssistScore(scoreResponse.data);
		} catch (error) {
			console.error("Error running combined GPT Assist for cover letter:", error);
		} finally {
			setAllFeedbackLoading(false);
			coverLetterApiCallLockRef.current = false; // Release lock after API call completes
		}
	};

	useEffect(() => {
		setTempResumeData(resumeData);
	}, [resumeData]);

	useEffect(() => {
		setTempCoverLetterData(coverLetterData);
	}, [coverLetterData]);

	useEffect(() => {
		// Run combined resume assist when the tab is switched to Resume Assist the first time
		// (gptAssistData is null and no API call is in progress)
		if (resumeData && activeTab === 1 && !gptAssistData?.summary && !resumeApiCallLockRef.current) {
			runCombinedResumeAssist();
		}
	}, [activeTab, gptAssistData, resumeData]);

	useEffect(() => {
		// Run combined cover letter assist when the tab is switched to Cover Letter Assist the first time
		// (gptAssistData is null and no API call is in progress)
		if (coverLetterData && activeTab === 2 && !gptAssistData?.intro && !coverLetterApiCallLockRef.current) {
			runCombinedCoverLetterAssist();
		}
	}, [activeTab, gptAssistData, coverLetterData]);

	return (
		<TooltipContext.Provider value={tooltipValue}>
			{showFeedbackModal && (
				<GptAssistFeedbackModal
					setShowFeedbackModal={setShowFeedbackModal}
					feedbackName={feedbackName}
					feedbackStatus={feedbackStatus}
					selectedPromptId={selectedPromptId}
				/>
			)}
			<div className={styles.inputDetailsContainer} style={{ gap: "0" }}>
				{!getLmsAssignmentType() && (
					<div className={styles.titleLabel}>
						<div className={styles.detailTabContainer}>
							{tabList.map((tab, index) => (
								<button
									type="button"
									key={tab}
									className={`${styles.detailTab} ${activeTab === index ? styles.activeDetailTab : ""} ${
										index === 1 || index === 2 ? styles.previewDetailTab : ""
									}${index === 3 ? styles.applyDetailTab : ""}`}
									onClick={() => {
										if (index === 3) {
											const utmParams = "utm_medium=feeds_boards&utm_content=prospecting&utm_source=instage";
											const monsterUrlWithUTM = `${setupData.monsterUrl}?${utmParams}`;
											window.open(monsterUrlWithUTM, "_blank");
										} else {
											setActiveTab(index);
										}
									}}
									onKeyDown={(event) => {
										if (event.key === "Enter") {
											setActiveTab(index);
										}
									}}
									tabIndex={0}
									aria-label={tab}
								>
									{tab}
									{(index === 1 || index === 2) && <FiStar />}
									{index === 3 && <FiExternalLink />}
								</button>
							))}
						</div>
					</div>
				)}

				{activeTab === 0 && (
					<SetupDetails
						setupData={setupData}
						typingRefs1={typingRefs1}
						audienceOptions={audienceOptions}
						personalityOptions={personalityOptions}
						personalities={personalities}
						audience={audience}
						personality={personality}
						audienceRole={audienceRole}
						detailsArray={detailsArray}
						hoveredAvatar={hoveredAvatar}
						setHoveredAvatar={setHoveredAvatar}
						handlePersonalityChange={handlePersonalityChange}
						changeAvatar={changeAvatar}
					/>
				)}

				{activeTab === 1 && (
					<>
						{resumeData === null ? (
							<ResumeUpload type="resume" skipModal setup_id={setupData.simulation_id} />
						) : (
							<>
								<div className={styles.resumeScoreContainer}>
									<div className={styles.resumeScoreSection}>
										<div className={styles.resumeScoreSubsection}>
											<div className={styles.gptAssistScoreContainer}>
												{!gptScoreLoading && resumeScore !== null && Number.isNaN(resumeScore) === false ? (
													<div className={styles.scoreContainer} style={{ "--score-color": getColor(resumeScore) }}>
														<svg viewBox="0 0 36 22" className={styles.scoreDial}>
															<path
																className={styles.scoreTrack}
																d="M18 2.0845 a 15.9155 15.9155 0 0 1 15.9155 15.9155 15.9155 15.9155 0 0 1 -15.9155 15.9155"
															/>
															<path
																className={styles.scoreIndicator}
																style={{ strokeDasharray: `${(resumeScore / 100) * 50}, 50` }}
																d="M18 2.0845 a 15.9155 15.9155 0 0 1 15.9155 15.9155 15.9155 15.9155 0 0 1 -15.9155 15.9155"
															/>
															<text x="18" y="14.5" className={styles.scoreText}>
																{resumeScore}%
															</text>
														</svg>
													</div>
												) : (
													<div className={styles.spinnerContainerGptScore}>
														<LoadingSpinner height={148} width={148} thickness={10} color="#00a9af" />
													</div>
												)}
												<div className={`${styles.regenerateContainer} ${!resumeDataChanged ? styles.tooltipContainer : ""}`}>
													<button
														type="button"
														className={`${styles.gptAssistBtn} ${
															!resumeDataChanged || gptScoreLoading ? styles.gptAssistBtnDisabled : ""
														}`}
														onClick={() => runResumeScore()}
														disabled={!resumeDataChanged || gptScoreLoading}
													>
														<BsStars className={styles.gptAssistBtnIcon} />
														{pageText.Regenerate}
													</button>
													<span className={styles.tooltipText}>
														{!resumeDataChanged ? pageText.SaveChangesToRegenerate : ""}
													</span>
												</div>
											</div>
										</div>
										<div className={styles.resumeScoreSubsection}>
											<ResumeSubscore
												title={pageText.SummarySection}
												docType="resume"
												data={gptAssistScore?.summary}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
											<ResumeSubscore
												title={pageText.ExperienceSection}
												docType="resume"
												data={gptAssistScore?.experience}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
											<ResumeSubscore
												title={pageText.SkillsSection}
												docType="resume"
												data={gptAssistScore?.skills}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
											<ResumeSubscore
												title={pageText.EducationSection}
												docType="resume"
												data={gptAssistScore?.education}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
											<ResumeSubscore title={pageText.VolunteerSection} docType="resume" gptScoreLoading={gptScoreLoading} />
											<ResumeSubscore
												title={pageText.ExtracurricularSection}
												docType="resume"
												gptScoreLoading={gptScoreLoading}
											/>
										</div>
									</div>
									<ResumeScoreSection
										category="strengths"
										docType="resume"
										gptScoreLoading={gptScoreLoading}
										gptAssistScore={gptAssistScore}
										isThumbClicked={isThumbClicked}
										handleThumbClickScore={handleThumbClickScore}
									/>
									<ResumeScoreSection
										category="weaknesses"
										docType="resume"
										gptScoreLoading={gptScoreLoading}
										gptAssistScore={gptAssistScore}
										isThumbClicked={isThumbClicked}
										handleThumbClickScore={handleThumbClickScore}
									/>
								</div>
								<div className={styles.resumeSectionsContainer}>
									<div className={styles.gptAssistTabContainer}>
										{resumeSections.map((section, index) => (
											<button
												key={section}
												type="button"
												className={`${styles.gptAssistTab} ${
													activeResumeSection === section ? styles.activeGptAssistTab : ""
												}`}
												onClick={() => setActiveResumeSection(section)}
											>
												{resumeSectionsText[index].charAt(0).toUpperCase() + resumeSectionsText[index].slice(1)}
											</button>
										))}
									</div>
									{resumeSections.map((section) => (
										<div key={section} style={{ display: section === activeResumeSection ? "block" : "none" }}>
											<ResumeSection
												docType="resume"
												section={section}
												sectionTextResume={resumeSectionsText}
												sectionTextCoverLetter={coverLetterSectionsText}
												resumeSections={resumeSections}
												editMode={editMode}
												toggleEdit={toggleEdit}
												handleCopy={handleCopy}
												handleTempUpdate={handleTempUpdate}
												handleSave={handleSave}
												data={resumeData}
												tempData={tempResumeData}
												tempDataSetter={setTempResumeData}
												dataSetter={setResumeData}
												dataStatusSetter={setResumeDataChanged}
												gptAssistData={gptAssistData}
												setGptAssistData={setGptAssistData}
												allFeedbackLoading={allFeedbackLoading}
												sectionFeedbackLoading={sectionFeedbackLoading}
												sectionRewriteLoading={sectionRewriteLoading}
												resumeFeedbackSection={resumeFeedbackSection}
												resumeRewriteSection={resumeRewriteSection}
												detailsArray={detailsArray}
												setShowEditModal={setShowEditModal}
											/>
										</div>
									))}
								</div>
							</>
						)}
					</>
				)}
				{activeTab === 2 && (
					<>
						{coverLetterData === null ? (
							<ResumeUpload type="cover" skipModal setup_id={setupData.simulation_id} />
						) : (
							<>
								<div className={styles.resumeScoreContainer}>
									<div className={styles.resumeScoreSection}>
										<div className={styles.resumeScoreSubsection}>
											<div className={styles.gptAssistScoreContainer}>
												{!gptScoreLoading && coverLetterScore !== null && Number.isNaN(coverLetterScore) === false ? (
													<div className={styles.scoreContainer} style={{ "--score-color": getColor(coverLetterScore) }}>
														<svg viewBox="0 0 36 22" className={styles.scoreDial}>
															<path
																className={styles.scoreTrack}
																d="M18 2.0845 a 15.9155 15.9155 0 0 1 15.9155 15.9155 15.9155 15.9155 0 0 1 -15.9155 15.9155"
															/>
															<path
																className={styles.scoreIndicator}
																style={{ strokeDasharray: `${(coverLetterScore / 100) * 50}, 50` }}
																d="M18 2.0845 a 15.9155 15.9155 0 0 1 15.9155 15.9155 15.9155 15.9155 0 0 1 -15.9155 15.9155"
															/>
															<text x="18" y="14.5" className={styles.scoreText}>
																{coverLetterScore}%
															</text>
														</svg>
													</div>
												) : (
													<div className={styles.spinnerContainerGptScore}>
														<LoadingSpinner height={148} width={148} thickness={10} color="#00a9af" />
													</div>
												)}
												<div
													className={`${styles.regenerateContainer} ${
														!coverLetterDataChanged ? styles.tooltipContainer : ""
													}`}
												>
													<button
														type="button"
														className={`${styles.gptAssistBtn} ${
															!coverLetterDataChanged || gptScoreLoading ? styles.gptAssistBtnDisabled : ""
														}`}
														onClick={() => runCoverLetterScore()}
														disabled={!coverLetterDataChanged || gptScoreLoading}
													>
														<BsStars className={styles.gptAssistBtnIcon} />
														{pageText.Regenerate}
													</button>
													<span className={styles.tooltipText}>
														{!coverLetterDataChanged ? "Save changes to regenerate" : ""}
													</span>
												</div>
											</div>
										</div>
										<div className={styles.resumeScoreSubsection}>
											<ResumeSubscore
												title={pageText.CVIntro}
												docType="cover_letter"
												data={gptAssistScore?.intro}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
											<ResumeSubscore
												title={pageText.CVBody}
												docType="cover_letter"
												data={gptAssistScore?.body}
												gptScoreLoading={gptScoreLoading}
												handleThumbClickScore={handleThumbClickScore}
												isThumbClicked={isThumbClicked}
											/>
										</div>
									</div>
									<ResumeScoreSection
										category="strengths"
										docType="cover_letter"
										gptScoreLoading={gptScoreLoading}
										gptAssistScore={gptAssistScore}
										isThumbClicked={isThumbClicked}
										handleThumbClickScore={handleThumbClickScore}
									/>
									<ResumeScoreSection
										category="weaknesses"
										docType="cover_letter"
										gptScoreLoading={gptScoreLoading}
										gptAssistScore={gptAssistScore}
										isThumbClicked={isThumbClicked}
										handleThumbClickScore={handleThumbClickScore}
									/>
								</div>
								<div className={styles.resumeSectionsContainer}>
									<div className={styles.gptAssistTabContainer}>
										{coverLetterSections.map((section, index) => (
											<button
												key={section}
												type="button"
												className={`${styles.gptAssistTab} ${
													activeCoverLetterSection === section ? styles.activeGptAssistTab : ""
												}`}
												onClick={() => setActiveCoverLetterSection(section)}
											>
												{coverLetterSectionsText[index].charAt(0).toUpperCase() + coverLetterSectionsText[index].slice(1)}
											</button>
										))}
									</div>
									{coverLetterSections.map((section) => (
										<div key={section} style={{ display: section === activeCoverLetterSection ? "block" : "none" }}>
											<ResumeSection
												docType="cover_letter"
												section={section}
												sectionTextResume={resumeSectionsText}
												sectionTextCoverLetter={coverLetterSectionsText}
												resumeSections={resumeSections}
												editMode={editMode}
												toggleEdit={toggleEdit}
												handleCopy={handleCopy}
												handleTempUpdate={handleTempUpdate}
												handleSave={handleSave}
												data={coverLetterData}
												tempData={tempCoverLetterData}
												tempDataSetter={setTempCoverLetterData}
												dataSetter={setCoverLetterData}
												dataStatusSetter={setCoverLetterDataChanged}
												gptAssistData={gptAssistData}
												setGptAssistData={setGptAssistData}
												allFeedbackLoading={allFeedbackLoading}
												sectionFeedbackLoading={sectionFeedbackLoading}
												sectionRewriteLoading={sectionRewriteLoading}
												resumeFeedbackSection={resumeFeedbackSection}
												resumeRewriteSection={resumeRewriteSection}
												detailsArray={detailsArray}
												setShowEditModal={setShowEditModal}
											/>
										</div>
									))}
								</div>
							</>
						)}
					</>
				)}
			</div>
		</TooltipContext.Provider>
	);
}

function ResumeSubscore({ title, docType, data, gptScoreLoading, handleThumbClickScore, isThumbClicked }) {
	const { activeTooltip, setActiveTooltip } = useTooltip();
	const infoRef_details = useRef();
	const [showInfo_details, setShowInfo_details] = useState(false);

	const {
		user,
		resumeData,
		setResumeData,
		coverLetterData,
		setCoverLetterData,
		setupData,
		setSetupData,
		userLanguage,
		translateDictText,
		performTranslation
	} = useContext(AppContext);

	const LocalLanguageDict = {
		ResumeSummary: "summary",
		ResumeExperience: "experience",
		ResumeSkills: "skills",
		ResumeEducation: "education",
		ResumeVolunteer: "volunteer",
		ResumeExtracurricular: "extracurricular",
		CoverLetterIntro: "Intro",
		CoverLetterBody: "Body",
		ResumeSummary1: "Does the summary align with the targeted role?",
		ResumeSummary2: "Does the summary articulate a value proposition and professional identity?",
		ResumeSkills1: "Does this section address the skills required in the job post?",
		ResumeSkills2: "Are skills listed with varied, specific descriptors and in a concise format?",
		ResumeExperience1: "Does the experience align with the targeted role from the job post?",
		ResumeExperience2: "Are bullet points under this section written as accomplishment statements?",
		ResumeEducation1: "Does the education section list a degree, certification, or credential?",
		ResumeEducation2: "Does each degree, certification, or credential list the name of the institution?",
		ExcludedFromScoring: "Excluded from scoring",
		CoverLetterIntro1: "Is the job title explicitly mentioned?",
		CoverLetterIntro2: "Is there an interest statement, personal connection, or specific reason for applying?",
		CoverLetterIntro3: "Is a thesis statement included that aligns with the job's requirements?",
		CoverLetterIntro4: "Is it engaging, concise, and free from clichés?",
		CoverLetterBody1: "Does it provide specific examples linking skills and experiences directly to the job’s responsibilities?",
		CoverLetterBody2: "Does it illustrate past impact and how these contributions will benefit the employer?",
		CoverLetterBody3: "Is there evidence of alignment with the company culture and values?",
		CoverLetterBody4: "Does it include specific, quantifiable achievements to support qualifications?"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
		// console.log("Changes");
	}, [userLanguage, user]);

	const getColor = (score) => {
		if (score > 75) return "#4CAF50"; // Green
		if (score > 50) return "#f1c40f"; // Gold
		if (score > 25) return "#FF8C00"; // Dark Orange
		return "#16013e"; // Grey
	};

	useOutsideClick(infoRef_details, () => setShowInfo_details(false));
	useEffect(() => {
		if (activeTooltip !== title) {
			setShowInfo_details(false);
		}
	}, [activeTooltip, title]);

	const rubricQuestions = {
		[pageText.ResumeSummary.toLowerCase()]: [pageText.ResumeSummary1, pageText.ResumeSummary2],
		[pageText.ResumeSkills.toLowerCase()]: [pageText.ResumeSkills1, pageText.ResumeSkills2],
		[pageText.ResumeExperience.toLowerCase()]: [pageText.ResumeExperience1, pageText.ResumeExperience2],
		[pageText.ResumeEducation.toLowerCase()]: [pageText.ResumeEducation1, pageText.ResumeEducation2],
		[pageText.ResumeVolunteer.toLowerCase()]: [pageText.ExcludedFromScoring],
		[pageText.ResumeExtracurricular.toLowerCase()]: [pageText.ExcludedFromScoring],
		[pageText.CoverLetterIntro.toLowerCase()]: [
			pageText.CoverLetterIntro1,
			pageText.CoverLetterIntro2,
			pageText.CoverLetterIntro3,
			pageText.CoverLetterIntro4
		],
		[pageText.CoverLetterBody.toLowerCase()]: [
			pageText.CoverLetterBody1,
			pageText.CoverLetterBody2,
			pageText.CoverLetterBody3,
			pageText.CoverLetterBody4
		]
	};
	// useEffect(() => {
	// 	console.log("rubricQuestions", rubricQuestions);
	// }, [rubricQuestions]);

	const totalPoints = data ? Object.values(data).reduce((a, b) => a + b, 0) : 0;
	const totalQuestions = data ? Object.keys(data).length : 0;
	const percentage = totalQuestions > 0 ? Math.round((totalPoints / (totalQuestions * 2)) * 100) : 0;

	return (
		<>
			<div
				className={`${styles.gptAssistSubscoreContainer} ${gptScoreLoading ? styles.gptAssistTextLoading : ""} ${
					data === undefined ? styles.notApplicable : ""
				}`}
			>
				<p className={styles.gptAssistSubscoreLabel}>{title.charAt(0).toUpperCase() + title.slice(1)}:</p>
				<div className={styles.gptAssistSubscorePercContainer}>
					<p className={styles.gptAssistSubscorePercText} style={data !== undefined ? { color: getColor(percentage) } : {}}>
						{data === undefined ? "N/A" : `${percentage}%`}
					</p>
					<div className={styles.gptAssistInfoIconContainer}>
						<button
							type="button"
							aria-label="Information"
							tabIndex={0}
							className={styles.gptAssistInfo}
							onClick={(event) => {
								event.stopPropagation();
								setShowInfo_details(!showInfo_details);
								if (!showInfo_details) {
									// If we're about to show the tooltip
									setActiveTooltip(title); // Use title or any unique identifier
								} else {
									setActiveTooltip(null);
								}
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									setShowInfo_details(!showInfo_details);
									if (!showInfo_details) {
										// If we're about to show the tooltip
										setActiveTooltip(title); // Use title or any unique identifier
									} else {
										setActiveTooltip(null);
									}
								}
							}}
						>
							<FiInfo />
						</button>
						{showInfo_details && (
							<div
								className={styles.gptAssistInfoTooltip}
								ref={infoRef_details}
								onClick={(event) => event.stopPropagation()}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.stopPropagation();
									}
								}}
								tabIndex={0}
								role="button"
							>
								<span>
									{rubricQuestions[title.toLowerCase()]?.map((question, index) => (
										<p key={index} className={styles.gptAssistInfoTooltipText}>
											• {question}
										</p>
									))}
								</span>
							</div>
						)}
					</div>
					{data === undefined && <div className={styles.thumbsContainer}></div>}
					{!gptScoreLoading && data !== undefined && (
						<div className={styles.thumbsContainer}>
							<ThumbButton
								type="up"
								docType={docType}
								isClicked={isThumbClicked[`${docType}_score_${title}`]}
								onClick={(e) => {
									e.stopPropagation();
									handleThumbClickScore(`${docType}_score_${title}`, true, docType);
								}}
							/>
							<ThumbButton
								type="down"
								docType={docType}
								isClicked={isThumbClicked[`${docType}_score_${title}`] === false}
								onClick={(e) => {
									e.stopPropagation();
									handleThumbClickScore(`${docType}_score_${title}`, false, docType);
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
