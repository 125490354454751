import React, { useState, useEffect } from "react";
import { PiSparkleBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import styles from "../../styleModules/resumeCreatorForm.module.css";
import { LoadingSpinner } from "./LoadingSpinner.jsx";

// eslint-disable-next-line
const ResumeCreatorForm = ({ resumeData, setResumeData, selectedSection }) => {
	const [activeSection, setActiveSection] = useState(selectedSection);

	// useEffect(() => {
	// 	console.log("selectedSection", selectedSection);
	// }, [selectedSection]);

	const [resumeCreatorRewriteSections, setResumeCreatorRewriteSections] = useState({
		summary: "",
		experience: {},
		extraCurricular: {},
		volunteer: {}
	});
	const { axiosLimitedPost } = useAxiosLimited();

	const sections = ["contact", "summary", "experience", "education", "extraCurricular", "volunteer", "skills"];

	const isSectionFilled = (section) => {
		switch (section) {
			case "contact":
				return Object.values(resumeData.contact).some((value) => value.trim() !== "");
			case "summary":
				return resumeData.summary.trim() !== "";
			case "experience":
				return resumeData.experience.length > 0 && resumeData.experience.some((exp) => exp.jobTitle.trim() !== "");
			case "education":
				return resumeData.education.length > 0 && resumeData.education.some((edu) => edu.school.trim() !== "");
			case "skills":
				return resumeData.skills.technical.length > 0 || resumeData.skills.coreCompetencies.length > 0;
			case "extraCurricular":
				return resumeData.extraCurricular.some((activity) => !activity.isVolunteer && activity.activity.trim() !== "");
			case "volunteer":
				return resumeData.extraCurricular.some((activity) => activity.isVolunteer && activity.activity.trim() !== "");
			default:
				return false;
		}
	};

	const handleInputChange = (section, field, value, index = 0, subIndex = null) => {
		setResumeData((prevData) => {
			if (section === "summary") {
				return { ...prevData, summary: value };
			}
			if (section === "volunteer" || section === "extraCurricular") {
				const newArray = [...prevData.extraCurricular];
				if (subIndex !== null) {
					newArray[index] = {
						...newArray[index],
						[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
					};
				} else {
					newArray[index] = { ...newArray[index], [field]: value };
				}
				return { ...prevData, extraCurricular: newArray };
			}
			if (Array.isArray(prevData[section])) {
				const newArray = [...prevData[section]];
				if (subIndex !== null) {
					newArray[index] = {
						...newArray[index],
						[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
					};
				} else {
					newArray[index] = { ...newArray[index], [field]: value };
				}
				return { ...prevData, [section]: newArray };
			} else if (section === "skills") {
				return {
					...prevData,
					skills: {
						...prevData.skills,
						[field]: prevData.skills[field].map((item, i) => (i === index ? value : item))
					}
				};
			} else if (typeof prevData[section] === "object") {
				return { ...prevData, [section]: { ...prevData[section], [field]: value } };
			}
			return prevData;
		});
	};

	const addItem = (section) => {
		setResumeData((prevData) => {
			if (section === "extraCurricular" || section === "volunteer") {
				const newItem = {
					id: Date.now(),
					activity: "",
					orgName: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					tasks: [],
					isVolunteer: section === "volunteer"
				};
				return {
					...prevData,
					extraCurricular: [...prevData.extraCurricular, newItem]
				};
			} else {
				return {
					...prevData,
					[section]: [
						...prevData[section],
						section === "experience"
							? {
									id: Date.now(),
									jobTitle: "",
									companyName: "",
									city: "",
									stateOrProv: "",
									startMonth: "",
									startYear: "",
									endMonth: "",
									endYear: "",
									roles: []
							  }
							: section === "education"
							? {
									id: Date.now(),
									school: "",
									degree: "",
									program: "",
									graduatingYear: ""
							  }
							: ""
					]
				};
			}
		});
	};

	const addRole = (experienceIndex) => {
		setResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles.push("");
			return { ...prevData, experience: newExperience };
		});
	};

	const addTask = (activityIndex, isVolunteer = false) => {
		setResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			const targetIndex = isVolunteer
				? newExtraCurricular.findIndex((activity) => activity.isVolunteer && activity.id === newExtraCurricular[activityIndex].id)
				: activityIndex;

			if (targetIndex !== -1) {
				newExtraCurricular[targetIndex] = {
					...newExtraCurricular[targetIndex],
					tasks: [...newExtraCurricular[targetIndex].tasks, ""]
				};
			}

			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const addSkill = (skillType) => {
		setResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: [...prevData.skills[skillType], ""]
			}
		}));
	};

	const [loadingRewrite, setLoadingRewrite] = useState({});

	const rewriteSection = async (section, sectionInput, index = null, subIndex = null) => {
		setLoadingRewrite({ [section]: { [index]: { [subIndex]: true } } });

		const sendToPromptLayer = async () => {
			const data = {
				sectionTitle: section,
				sectionData: sectionInput
			};

			try {
				const resp = await axiosLimitedPost(
					`${process.env.REACT_APP_BACKEND_STATIC_URL}/api/resume-assist/resumeCreatorRewriteSection`,
					data,
					1,
					{},
					30000
				);
				setResumeCreatorRewriteSections((prevSections) => {
					if (section === "experience" || section === "extraCurricular" || section === "volunteer") {
						return {
							...prevSections,
							[section]: {
								...prevSections[section],
								[index]: {
									...prevSections[section][index],
									[subIndex]: resp.data
								}
							}
						};
					}
					return {
						...prevSections,
						[section]: resp.data
					};
				});
			} catch (err) {
				console.log(err);
			} finally {
				setLoadingRewrite({});
			}
		};

		return sendToPromptLayer();
	};

	const removeRole = (experienceIndex, roleIndex) => {
		setResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles = newExperience[experienceIndex].roles.filter((_, index) => index !== roleIndex);
			return { ...prevData, experience: newExperience };
		});
	};

	const removeTask = (activityIndex, taskIndex, isVolunteer) => {
		setResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			const targetIndex = newExtraCurricular.findIndex(
				(activity) => activity.id === newExtraCurricular[activityIndex].id && activity.isVolunteer === isVolunteer
			);
			if (targetIndex !== -1) {
				newExtraCurricular[targetIndex].tasks = newExtraCurricular[targetIndex].tasks.filter((_, index) => index !== taskIndex);
			}
			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const removeTaskExtracurricular = (activityIndex, taskIndex) => {
		setResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			newExtraCurricular[activityIndex].tasks = newExtraCurricular[activityIndex].tasks.filter((_, index) => index !== taskIndex);
			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const removeSkill = (skillType, index) => {
		setResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: prevData.skills[skillType].filter((_, i) => i !== index)
			}
		}));
	};

	const renderContactForm = () => (
		<div className={styles.formGroup}>
			<input
				className={styles.input}
				placeholder="First Name"
				value={resumeData.contact.firstName || ""}
				onChange={(e) => handleInputChange("contact", "firstName", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Last Name"
				value={resumeData.contact.lastName || ""}
				onChange={(e) => handleInputChange("contact", "lastName", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Email Address"
				type="email"
				value={resumeData.contact.email || ""}
				onChange={(e) => handleInputChange("contact", "email", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Address"
				value={resumeData.contact.address || ""}
				onChange={(e) => handleInputChange("contact", "address", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Phone Number"
				type="tel"
				value={resumeData.contact.phone || ""}
				onChange={(e) => handleInputChange("contact", "phone", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="LinkedIn URL"
				value={resumeData.contact.linkedinURL || ""}
				onChange={(e) => handleInputChange("contact", "linkedinURL", e.target.value)}
			/>
		</div>
	);

	const renderSummaryForm = () => (
		<>
			<textarea
				className={styles.textarea}
				placeholder="Professional Summary"
				value={resumeData.summary}
				onChange={(e) => handleInputChange("summary", null, e.target.value)}
			/>
			<div>
				<button type="button" className={styles.button} onClick={() => rewriteSection("summary", resumeData.summary)}>
					<PiSparkleBold style={{ marginRight: "10px" }} />
					Rewrite
				</button>
				{resumeCreatorRewriteSections.summary && (
					<textarea
						className={styles.rewriteText}
						value={resumeCreatorRewriteSections.summary}
						onChange={(e) =>
							setResumeCreatorRewriteSections({
								...resumeCreatorRewriteSections,
								summary: e.target.value
							})
						}
					/>
				)}
			</div>
		</>
	);

	const renderExperienceForm = () => (
		<div>
			{console.log("resumeData.experience: ", resumeData.experience)}
			{resumeData.experience.map((exp, index) => (
				<div key={exp.id || index} className={styles.experienceItem}>
					<input
						className={styles.input}
						placeholder="Job Title"
						value={exp.jobTitle || ""}
						onChange={(e) => handleInputChange("experience", "jobTitle", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Company Name"
						value={exp.companyName || ""}
						onChange={(e) => handleInputChange("experience", "companyName", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="City"
						value={exp.city || ""}
						onChange={(e) => handleInputChange("experience", "city", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="State/Province"
						value={exp.stateOrProv || ""}
						onChange={(e) => handleInputChange("experience", "stateOrProv", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Month"
						value={exp.startMonth || ""}
						onChange={(e) => handleInputChange("experience", "startMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Year"
						value={exp.startYear || ""}
						onChange={(e) => handleInputChange("experience", "startYear", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Month"
						value={exp.endMonth || ""}
						onChange={(e) => handleInputChange("experience", "endMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Year"
						value={exp.endYear || ""}
						onChange={(e) => handleInputChange("experience", "endYear", e.target.value, index)}
					/>
					<div className={styles.indentedField}>
						{(exp.roles || []).map((role, roleIndex) => (
							<div key={`role-${exp.id}-${roleIndex}`}>
								<div className={styles.roleItem}>
									<input
										className={styles.input}
										placeholder={`Role ${roleIndex + 1}`}
										value={role}
										onChange={(e) => handleInputChange("experience", "roles", e.target.value, index, roleIndex)}
									/>
									<div className={styles.buttonGroup}>
										<button
											type="button"
											className={styles.removeButtonSmall}
											onClick={() => removeRole(index, roleIndex)}
											title="Remove Role"
										>
											<FaRegTrashAlt />
										</button>
										<button
											type="button"
											className={styles.rewriteButtonSmall}
											onClick={() => rewriteSection("experience", role, index, roleIndex)}
											title="AI-Rewrite"
											disabled={loadingRewrite?.experience?.[index]?.[roleIndex]}
										>
											{loadingRewrite?.experience?.[index]?.[roleIndex] ? (
												<LoadingSpinner height={16} width={16} thickness={2} color="white" />
											) : (
												<PiSparkleBold />
											)}
										</button>
									</div>
								</div>
								{resumeCreatorRewriteSections.experience?.[index]?.[roleIndex] && (
									<textarea
										className={styles.rewriteText}
										value={resumeCreatorRewriteSections.experience[index][roleIndex]}
										onChange={(e) => {
											setResumeCreatorRewriteSections((prev) => ({
												...prev,
												experience: {
													...prev.experience,
													[index]: {
														...prev.experience[index],
														[roleIndex]: e.target.value
													}
												}
											}));
										}}
									/>
								)}
							</div>
						))}
						<button type="button" className={styles.addButton} onClick={() => addRole(index)}>
							Add Role
						</button>
					</div>
					<button type="button" className={styles.removeButton} onClick={() => removeItem("experience", index)}>
						Remove Experience
					</button>
					<hr />
				</div>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("experience")}>
				Add Experience
			</button>
		</div>
	);

	const renderSkillsForm = () => (
		<div>
			<h3>Technical Skills</h3>
			<div className={styles.indentedField}>
				{resumeData.skills.technical.map((skill, index) => (
					<div key={`technical-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Technical Skill ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "technical", e.target.value, index)}
						/>
						<button type="button" className={styles.removeButton} onClick={() => removeSkill("technical", index)}>
							Remove
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("technical")}>
					Add Technical Skill
				</button>
			</div>
			<h3>Core Competencies</h3>
			<div className={styles.indentedField}>
				{resumeData.skills.coreCompetencies.map((skill, index) => (
					<div key={`competency-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Core Competency ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "coreCompetencies", e.target.value, index)}
						/>
						<button type="button" className={styles.removeButton} onClick={() => removeSkill("coreCompetencies", index)}>
							Remove
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("coreCompetencies")}>
					Add Core Competency
				</button>
			</div>
		</div>
	);

	const renderEducationForm = () => (
		<div>
			{resumeData.education.map((edu, index) => (
				<div key={edu.id || index} className={styles.educationItem}>
					<input
						className={styles.input}
						placeholder="School"
						value={edu.school || ""}
						onChange={(e) => handleInputChange("education", "school", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Degree"
						value={edu.degree || ""}
						onChange={(e) => handleInputChange("education", "degree", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Program"
						value={edu.program || ""}
						onChange={(e) => handleInputChange("education", "program", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Graduating Year"
						value={edu.graduatingYear || ""}
						onChange={(e) => handleInputChange("education", "graduatingYear", e.target.value, index)}
					/>
					<button type="button" className={styles.removeButton} onClick={() => removeItem("education", index)}>
						Remove Education
					</button>
					<hr />
				</div>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("education")}>
				Add Education
			</button>
		</div>
	);

	const renderExtraCurricularForm = () => (
		<div>
			{resumeData.extraCurricular
				.filter((activity) => !activity.isVolunteer)
				.map((activity, index) => (
					<div key={activity.id} className={styles.extraCurricularItem}>
						<input
							className={styles.input}
							placeholder="Activity"
							value={activity.activity}
							onChange={(e) => handleInputChange("extraCurricular", "activity", e.target.value, index)}
						/>
						<input
							className={styles.input}
							placeholder="Organization Name"
							value={activity.orgName}
							onChange={(e) => handleInputChange("extraCurricular", "orgName", e.target.value, index)}
						/>
						<input
							className={styles.input}
							placeholder="Start Month"
							value={activity.startMonth}
							onChange={(e) => handleInputChange("extraCurricular", "startMonth", e.target.value, index)}
						/>
						<input
							className={styles.input}
							placeholder="Start Year"
							value={activity.startYear}
							onChange={(e) => handleInputChange("extraCurricular", "startYear", e.target.value, index)}
						/>
						<input
							className={styles.input}
							placeholder="End Month"
							value={activity.endMonth}
							onChange={(e) => handleInputChange("extraCurricular", "endMonth", e.target.value, index)}
						/>
						<input
							className={styles.input}
							placeholder="End Year"
							value={activity.endYear}
							onChange={(e) => handleInputChange("extraCurricular", "endYear", e.target.value, index)}
						/>
						<div className={styles.indentedField}>
							{activity.tasks.map((task, taskIndex) => (
								<div key={`task-${activity.id}-${taskIndex}`}>
									<div className={styles.taskItem}>
										<input
											className={styles.input}
											placeholder={`Task ${taskIndex + 1}`}
											value={task}
											onChange={(e) => handleInputChange("extraCurricular", "tasks", e.target.value, index, taskIndex)}
										/>
										<div className={styles.buttonGroup}>
											<button
												type="button"
												className={styles.removeButtonSmall}
												onClick={() => removeTaskExtracurricular(index, taskIndex)}
												title="Remove Task"
											>
												<FaRegTrashAlt />
											</button>
											<button
												type="button"
												className={styles.rewriteButtonSmall}
												onClick={() => rewriteSection("extraCurricular", task, index, taskIndex)}
												title="AI-Rewrite"
												disabled={loadingRewrite?.extraCurricular?.[index]?.[taskIndex]}
											>
												{loadingRewrite?.extraCurricular?.[index]?.[taskIndex] ? (
													<LoadingSpinner height={16} width={16} thickness={2} color="white" />
												) : (
													<PiSparkleBold />
												)}
											</button>
										</div>
									</div>
									{resumeCreatorRewriteSections.extraCurricular?.[index]?.[taskIndex] && (
										<textarea
											className={styles.rewriteText}
											value={resumeCreatorRewriteSections.extraCurricular[index][taskIndex]}
											onChange={(e) => {
												setResumeCreatorRewriteSections((prev) => ({
													...prev,
													extraCurricular: {
														...prev.extraCurricular,
														[index]: {
															...prev.extraCurricular[index],
															[taskIndex]: e.target.value
														}
													}
												}));
											}}
										/>
									)}
								</div>
							))}
							<button type="button" className={styles.addButton} onClick={() => addTask(index, false)}>
								Add Task
							</button>
						</div>
						<button type="button" className={styles.removeButton} onClick={() => removeItem("extraCurricular", index)}>
							Remove Extra-Curricular
						</button>
						<hr />
					</div>
				))}
			<button type="button" className={styles.button} onClick={() => addItem("extraCurricular")}>
				Add Extra-Curricular Activity
			</button>
		</div>
	);

	const renderVolunteerForm = () => (
		<div>
			{resumeData.extraCurricular
				.filter((activity) => activity.isVolunteer)
				.map((activity, filteredIndex) => {
					const originalIndex = resumeData.extraCurricular.findIndex((item) => item.id === activity.id);
					return (
						<div key={activity.id} className={styles.volunteerItem}>
							<input
								className={styles.input}
								placeholder="Activity"
								value={activity.activity}
								onChange={(e) => handleInputChange("extraCurricular", "activity", e.target.value, originalIndex)}
							/>
							<input
								className={styles.input}
								placeholder="Organization Name"
								value={activity.orgName}
								onChange={(e) => handleInputChange("extraCurricular", "orgName", e.target.value, originalIndex)}
							/>
							<input
								className={styles.input}
								placeholder="Start Month"
								value={activity.startMonth}
								onChange={(e) => handleInputChange("extraCurricular", "startMonth", e.target.value, originalIndex)}
							/>
							<input
								className={styles.input}
								placeholder="Start Year"
								value={activity.startYear}
								onChange={(e) => handleInputChange("extraCurricular", "startYear", e.target.value, originalIndex)}
							/>
							<input
								className={styles.input}
								placeholder="End Month"
								value={activity.endMonth}
								onChange={(e) => handleInputChange("extraCurricular", "endMonth", e.target.value, originalIndex)}
							/>
							<input
								className={styles.input}
								placeholder="End Year"
								value={activity.endYear}
								onChange={(e) => handleInputChange("extraCurricular", "endYear", e.target.value, originalIndex)}
							/>
							<div className={styles.indentedField}>
								{activity.tasks.map((task, taskIndex) => (
									<div key={`task-${activity.id}-${taskIndex}`}>
										<div className={styles.taskItem}>
											<input
												className={styles.input}
												placeholder={`Task ${taskIndex + 1}`}
												value={task}
												onChange={(e) =>
													handleInputChange("extraCurricular", "tasks", e.target.value, originalIndex, taskIndex)
												}
											/>
											<div className={styles.buttonGroup}>
												<button
													type="button"
													className={styles.removeButtonSmall}
													onClick={() => removeTask(originalIndex, taskIndex, true)}
													title="Remove Task"
												>
													<FaRegTrashAlt />
												</button>
												<button
													type="button"
													className={styles.rewriteButtonSmall}
													onClick={() => rewriteSection("volunteer", task, originalIndex, taskIndex)}
													title="AI-Rewrite"
													disabled={loadingRewrite?.volunteer?.[originalIndex]?.[taskIndex]}
												>
													{loadingRewrite?.volunteer?.[originalIndex]?.[taskIndex] ? (
														<LoadingSpinner height={16} width={16} thickness={2} color="white" />
													) : (
														<PiSparkleBold />
													)}
												</button>
											</div>
										</div>
										{resumeCreatorRewriteSections.volunteer?.[originalIndex]?.[taskIndex] && (
											<textarea
												className={styles.rewriteText}
												value={resumeCreatorRewriteSections.volunteer[originalIndex][taskIndex]}
												onChange={(e) => {
													setResumeCreatorRewriteSections((prev) => ({
														...prev,
														volunteer: {
															...prev.volunteer,
															[originalIndex]: {
																...prev.volunteer[originalIndex],
																[taskIndex]: e.target.value
															}
														}
													}));
												}}
											/>
										)}
									</div>
								))}
								<button type="button" className={styles.addButton} onClick={() => addTask(originalIndex, true)}>
									Add Task
								</button>
							</div>
							<button type="button" className={styles.removeButton} onClick={() => removeItem("volunteer", originalIndex)}>
								Remove Volunteer Activity
							</button>
							<hr />
						</div>
					);
				})}
			<button type="button" className={styles.button} onClick={() => addItem("volunteer")}>
				Add Volunteer Activity
			</button>
		</div>
	);

	const removeItem = (section, index) => {
		setResumeData((prevData) => {
			if (section === "extraCurricular" || section === "volunteer") {
				const newArray = prevData.extraCurricular.filter((_, i) => i !== index);
				return { ...prevData, extraCurricular: newArray };
			}
			return {
				...prevData,
				[section]: prevData[section].filter((_, i) => i !== index)
			};
		});
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.container}>
				{/* <div className={styles.sideNav}>
					{sections.map((section, index) => (
						<button
							type="button"
							key={section}
							className={`${styles.navButton} ${activeSection === section ? styles.activeButton : ""} ${
								isSectionFilled(section) ? styles.filledButton : styles.emptyButton
							}`}
							onClick={() => setActiveSection(section)}
						>
							<span className={styles.stepNumber}>{index + 1}</span>
							{section.charAt(0).toUpperCase() + section.slice(1)}
						</button>
					))}
				</div> */}
				<div className={styles.formContainer}>
					<h1 className={styles.title}>Resume Creator</h1>
					<p className={styles.subtitle}>Click "Add To Template" under "AI Edit" or manually fill out.</p>
					<div className={styles.formContent}>
						{selectedSection === "contact" && renderContactForm()}
						{selectedSection === "summary" && renderSummaryForm()}
						{selectedSection === "experience" && renderExperienceForm()}
						{selectedSection === "skills" && renderSkillsForm()}
						{selectedSection === "education" && renderEducationForm()}
						{selectedSection === "extraCurricular" && renderExtraCurricularForm()}
						{selectedSection === "volunteer" && renderVolunteerForm()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumeCreatorForm;
