import { useEffect, useState, useRef, useCallback } from "react";

import AvatarSpeechData from "../Classes/AvatarSpeechData";
import AvatarGptData from "../Classes/AvatarGptData";
import UserTranscriptData from "../Classes/UserTranscriptData";

export function CreateEventHistory(transcript_data, gpt_data, avatar_speech_data) {
	let eventHistory = [...transcript_data, ...gpt_data, ...avatar_speech_data];

	eventHistory = eventHistory.filter((event) => {
		const time = event.createdTime ? event.createdTime : event.startTime;
		return time !== 0 && time !== null && time >= 10000;
	});
	eventHistory.sort((a, b) => {
		const timeA = a.createdTime ? a.createdTime : a.startTime;
		const timeB = b.createdTime ? b.createdTime : b.startTime;
		return timeA - timeB;
	});
	return eventHistory;
}

function getAvatarIconUrl(name) {
	return `https://avatarportraits.s3.ca-central-1.amazonaws.com/${name || "John"}.png`;
}
// let icons = [

// 	,
// 	<FiUser className={r.transcriptUserIcon} />

// ]
class ReportTranscriptObject {
	constructor(text, isUser = true, timeStamp, end) {
		this.speaker = isUser ? 1 : 0;
		this.text = text;
		this.start = timeStamp;
		this.end = end;
		this.metadata = {};
		this.speechId = null;
		this.transcriptId = null;
		// console.log("ReportTranscriptObject", text, isUser, timeStamp, end);
	}
}
function normalizePlaybackTime(playbackTime) {
	if (playbackTime < 1000) {
		return playbackTime * 1000;
	}
	if (playbackTime > 1000) {
		return playbackTime;
	}
	return playbackTime;
}
export function CreateTranscriptV3(eventHistory, audience, questionList, personality) {
	const transcript = [];

	let lastSpeechData = null;
	let lastTranscriptData = null;
	let lastEvent = null;

	const gptDataLookup = eventHistory.reduce((lookup, event) => {
		if (event instanceof AvatarGptData) {
			return { ...lookup, [event.gptId]: event };
		}
		return lookup;
	}, {});

	eventHistory.forEach((event) => {
		if (event instanceof UserTranscriptData) {
			let newTimestamp;
			lastTranscriptData = event;
			// console.log("lastTranscriptData", lastTranscriptData, lastSpeechData && lastEvent && lastEvent === lastSpeechData);
			if (lastSpeechData && lastEvent && lastEvent === lastSpeechData) {
				const playbackTime = normalizePlaybackTime(lastSpeechData.playbackTime);
				newTimestamp = lastSpeechData.timeStamp + playbackTime;
				// console.log("newTimestamp", newTimestamp);
				//if (newTimestamp > event.timeStamp) {
				// newTimestamp = event.timeStamp;
				// }
			} else {
				newTimestamp = event.timeStamp;
			}

			const text = event.transcript;
			let end = newTimestamp + event.duration * 1000;
			// console.log("before end", newTimestamp, end);

			// try {
			// 	if (event.deepgram_data) {
			// 		if (event.deepgram_data.transcript !== "") {
			// 			// text = event.deepgram_data.transcript
			// 		}
			// 		if (event.deepgram_data.duration) {
			// 			end = newTimestamp + event.deepgram_data.duration * 1000;
			// 		}
			// 	}
			// } catch (error) {}

			// get last object in transcript
			// if it is a user object, append the text to it
			const lastTranscript = transcript[transcript.length - 1];

			if (lastTranscript && lastTranscript.speaker === 1) {
				lastTranscript.end = end;
				// console.log("lastTranscript", lastTranscript);

				lastTranscript.text = `${lastTranscript.text} ${text}`;
			} else {
				lastEvent = event;
				// console.log("before transcript", newTimestamp, end);

				const newobject = new ReportTranscriptObject(text, true, newTimestamp, end);
				newobject.transcriptId = event.transcriptId;
				transcript.push(newobject);
			}
		} else if (event instanceof AvatarSpeechData) {
			if (event.playbackTime !== 0) {
				let startTimeStamp = event.timeStamp;
				lastSpeechData = event;

				if (lastTranscriptData && lastEvent && lastTranscriptData === lastEvent) {
					// startTimeStamp = lastTranscriptData.timeStamp;
					// lastSpeechData.timeStamp = startTimeStamp;
				}
				lastEvent = event;
				const playbackTime = normalizePlaybackTime(lastSpeechData.playbackTime);

				const emdTimestamp = lastSpeechData.timeStamp + playbackTime;

				const data = new ReportTranscriptObject(event.spokenText, false, startTimeStamp, emdTimestamp);
				data.IconUrl = getAvatarIconUrl(audience[0]);
				data.speechId = event.speechId;
				// Find matching GPT data in event history
				const matchingGptData = gptDataLookup[event.gptId];
				if (matchingGptData && typeof matchingGptData.metadata === "object" && matchingGptData.promptLayer_request_id) {
					// console.log("matchingGptData", matchingGptData);
					data.metadata = matchingGptData.metadata;
					data.metadata.promptLayer_request_id = matchingGptData.promptLayer_request_id;
					data.metadata.personality = personality;
					// array deconstruction
					[data.metadata.audience] = audience;

					if (matchingGptData.functionOutput && matchingGptData.functionOutput.ResponseType) {
						data.metadata.responseType = matchingGptData.functionOutput.ResponseType;
					} else {
						data.metadata.responseType = null;
					}
					if (
						matchingGptData.functionOutput &&
						matchingGptData.functionOutput.Question_List_Index !== null &&
						matchingGptData.functionOutput.Question_List_Index !== undefined &&
						matchingGptData.functionOutput.Question_List_Index >= 0 &&
						questionList
					) {
						data.metadata.question = questionList[matchingGptData.functionOutput.Question_List_Index];
					} else {
						data.metadata.question = null;
					}
				}
				if (data.text !== "") {
					transcript.push(data);
					// console.log(data);
				}
			}
		} else if (event instanceof AvatarGptData) {
		}
	});

	// loop transcript to detect when current item start is earlier than previous

	const loopbackIndex = transcript.reduceRight(
		(acc, item, index, array) => (acc === -1 && index > 0 && item.start < array[index - 1].start ? index : acc),
		-1
	);

	if (loopbackIndex !== -1) {
		console.error("loopbackIndex", loopbackIndex);
		for (let i = 0; i < loopbackIndex - 1; i++) {
			transcript[i].start = -1;
			transcript[i].end = -1;
		}
		const previousItem = transcript[loopbackIndex - 1];
		previousItem.end -= previousItem.start;
		previousItem.start = 0;
	}

	return transcript;
}
/**
 * Creates a chat history from a transcript and setup type.
 * @param {Array} transcript - The transcriptV3 of the conversation.
 * @param {string} setup_type - The type of setup (e.g., 'interview', 'presentation', 'discovery').
 * @returns {Array} An array of chat messages with roles, content, and metadata.
 */
export function CreateChatHistoryV3(transcript, setup_type) {
	const chatHistory = [];
	const rolePrefixes = {
		interview: "Interviewee: ",
		presentation: "Presenter: ",
		discovery: "Sales Rep: "
	};
	const rolePrefix = rolePrefixes[setup_type] || "User: ";

	transcript.forEach((item) => {
		const message = {};
		const characterName = item.metadata?.audience || "John";
		if (item.speaker === 1) {
			// User message
			message.role = "user";
			message.content = rolePrefix + item.text;
		} else {
			// Assistant message
			message.role = "assistant";
			message.content = `${characterName}: ${item.text}`;

			if (item.metadata && item.metadata.promptLayer_request_id) {
				message.metadata = {
					promptLayer_request_id: item.metadata.promptLayer_request_id,
					token_count: item.metadata.token_count,
					tool_choice: item.metadata.tool_choice
				};
			}

			message.speechId = item.speechId;
		}

		chatHistory.push(message);
	});

	return chatHistory;
}
