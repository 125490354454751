import React, { useState, useEffect, useRef, useContext } from "react";
import _, { isEqual } from "lodash";

import { IoClose } from "react-icons/io5";
import ModalWrapper from "../../SecondaryComponents/ModalWrapper";
import s from "../../../styleModules/modalStyles.module.css";
import { useAxiosLimited } from "../../UtilityFunctions/axiosRetry.js";
import MultiSelect from "../MultiSelect.js";
import Competencies from "../Competencies.js";
import WIL_Types from "../WIL_Types.js";
import { AppContext } from "../../UtilityFunctions/AppContext.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

function DetailsModal({ data, setData, setModalOpen, simulation_id, fields }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [edited, setEdited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [dataTemp, setDataTemp] = useState(_.cloneDeep(data));
	const [targetTimeError, setTargetTimeError] = useState(null);
	const originalData = useRef(_.cloneDeep(data));
	const { userLanguage, performTranslation, translateDictText } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		EditGeneratedDetails: "Edit Generated Details",
		Save: "Save",
		Cancel: "Cancel",
		Minutes: "minutes"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		translateText();
	}, [userLanguage]);

	// Usage Dynamic
	useEffect(() => {
		console.log("data: ", dataTemp);
		async function performDynamicTranslation() {
			// Create a mapping of field names to their values, filtering out null values
			const fieldsToTranslate = {
				setup_name: data.setup_name,
				short_summary: data.short_summary,
				job_function: data.job_function,
				experience_level: data.experience_level,
				industry: data.industry,
				prospect_company_description: data.prospect_company_description,
				call_notes: data.call_notes,
				prospect_company_name: data.prospect_company_name,
				intro_event: data.intro_event,
				intro_details: data.intro_details,
				pitch_details: data.pitch_details,
				pitch_topic: data.pitch_topic
			};

			// Filter out null or undefined values
			const validFields = Object.entries(fieldsToTranslate).filter(([_, value]) => value != null);

			// Extract texts for translation
			const texts = validFields.map(([_, value]) => value);

			if (texts.length === 0) {
				console.error("texts is undefined or empty");
				return; // Exit if texts are not valid
			}

			try {
				const translatedTexts = await translateDictText(texts, userLanguage);
				// console.log("translatedTexts: ", translatedTexts);

				// Update dataTemp with translated texts
				setDataTemp((prevDataTemp) => {
					const updatedDataTemp = { ...prevDataTemp };

					// Apply translated texts back to their respective fields
					translatedTexts.forEach((translated, index) => {
						const fieldName = validFields[index][0];
						updatedDataTemp[fieldName] = translated || prevDataTemp[fieldName];
					});

					// console.log("updatedDataTemp: ", updatedDataTemp);
					return updatedDataTemp;
				});
			} catch (error) {
				console.error("Error translating texts:", error);
			}
		}
		performDynamicTranslation();
	}, [userLanguage, data]); // Add data.main_responsibilities to dependency array // Add data.main_responsibilities to dependency array

	useEffect(() => {
		originalData.current = { ...data };
	}, [data]);

	const saveToDb = () => {
		if (targetTimeError) {
			alert("Please fix the errors before saving.");
			return;
		}

		// Specific logic for the title field (first in array)
		const titleField = fields.find((field) => field.title === true);
		if (titleField && originalData.current[titleField.name] !== dataTemp[titleField.name]) {
			const dataForTitleField = {
				simulation_id,
				value: dataTemp[titleField.name],
				field: "setup_name"
			};
			axiosLimitedPatch(`${url}/api/saved/simulation`, dataForTitleField, 1, {}, 30000)
				.then((response) => {
					console.log(response.data);
					setData((prevData) => ({ ...prevData, [titleField.name]: dataTemp[titleField.name] }));
				})
				.catch((error) => {
					console.error(`Error saving ${titleField.name}`, error);
				});
		}

		// General logic for all fields
		const fieldsToMap = [...fields];
		if (titleField.name === "experience_type") {
			fieldsToMap.push({ name: "experience_type_description" });
		}

		fieldsToMap.forEach((field) => {
			if (!isEqual(originalData.current[field.name], dataTemp[field.name])) {
				const dataForField = {
					simulation_id,
					value: dataTemp[field.name],
					field: field.name
				};

				axiosLimitedPatch(`${url}/api/saved/simulation/output`, dataForField, 1, {}, 30000)
					.then((response) => {
						console.log(response.data);
						setData((prevData) => ({ ...prevData, [field.name]: dataTemp[field.name] }));
					})
					.catch((error) => {
						console.error(`Error saving ${field.name}`, error);
					});
			}
		});

		setEdited(false);
	};

	const handleModalChange = (e, property, index = null, key = null) => {
		setIsLoading(true);
		setEdited(true);

		const tempData = _.cloneDeep(dataTemp);

		if (property === "competency_list") {
			if (e.length > 5) {
				alert("Please select a maximum of 5 competencies.");
				setIsLoading(false);
				return;
			}

			tempData[property] = e;
		} else if (property === "experience_type") {
			const selectedType = WIL_Types.find((type) => type.name === e.target.value);
			tempData[property] = e.target.value;
			tempData.experience_type_description = selectedType.description;
		} else if (property === "target_time") {
			const { value } = e.target;
			if (!value || Number.isNaN(Number(value))) {
				// Validation check
				setTargetTimeError("Please enter a valid number."); // Set error message
			} else {
				setTargetTimeError(null); // Clear error message
				tempData[property] = value; // Only update if valid
			}
		} else if (index !== null && Array.isArray(tempData[property])) {
			// Update for array fields
			tempData[property][index] = e.target.value;
		} else if (key !== null && typeof tempData[property] === "object") {
			// Update for object fields
			tempData[property][key] = e.target.value;
		} else {
			// Update for other fields
			tempData[property] = e.target.value;
		}

		setDataTemp(tempData);
		setIsLoading(false);
	};

	return (
		<ModalWrapper closeModal={() => setModalOpen(false)}>
			<div className={`${s.modal} ${s.modalMinWidth}`}>
				<IoClose className={s.modalCloseIcon} title="Close icon" onClick={() => setModalOpen(false)} />
				<div className={s.modalContent}>
					<h1 className={s.modalTitle}>{pageText.EditGeneratedDetails}</h1>
					{fields.map((field, index) => (
						<div key={index}>
							<p className={s.modalInputLabel}>{field.label.toUpperCase()}</p>
							{field.type === "input" && (
								<input
									className={s.modalInput}
									type="text"
									value={dataTemp[field.name]}
									onChange={(e) => handleModalChange(e, field.name)}
								/>
							)}
							{field.type === "number" && (
								<>
									<input
										className={s.modalInput}
										type="range"
										min={field.unit === "minutes" ? 30 : 10}
										max={field.unit === "minutes" ? 1200 : 120}
										step={field.unit === "minutes" ? 30 : 10}
										value={dataTemp[field.name]}
										onChange={(e) => handleModalChange(e, field.name)}
									/>
									<div className={s.sliderLabel}>{`${
										field.unit === pageText.Minutes ? dataTemp[field.name] / 60 : dataTemp[field.name]
									} ${field.unit}`}</div>
									{targetTimeError && <p className={s.error}>{targetTimeError}</p>}
								</>
							)}
							{field.type === "textarea" && (
								<textarea
									className={s.modalTextarea}
									rows={field.rows || 5}
									value={dataTemp[field.name]}
									onChange={(e) => handleModalChange(e, field.name)}
								/>
							)}
							{field.type === "array" &&
								dataTemp[field.name].map((item, index) => (
									<input
										key={index}
										className={s.modalInput}
										type="text"
										value={item}
										onChange={(e) => {
											handleModalChange(e, field.name, index);
										}}
									/>
								))}
							{field.type === "object" &&
								Object.keys(dataTemp[field.name]).map((key, index) => (
									<div key={index}>
										<p className={s.modalInputLabel}>{key.toUpperCase()}</p>
										<input
											className={s.modalInput}
											type="text"
											value={dataTemp[field.name][key]}
											onChange={(e) => handleModalChange(e, field.name, null, key)}
										/>
									</div>
								))}
							{field.type === "dropdown" && (
								<Dropdown
									startingValue={dataTemp[field.name]}
									options={field.options}
									property={field.name}
									onChange={handleModalChange}
								/>
							)}
							{field.type === "multiselect" && (
								<MultiSelect
									competencies={Competencies}
									selectedCompetencies={dataTemp.competency_list}
									onCompetencyChange={(newCompetencyList) => handleModalChange(newCompetencyList, "competency_list")}
								/>
							)}
						</div>
					))}
				</div>

				<div className={s.modalBtnContainer}>
					<button className={s.modalCancelBtn} onClick={() => setModalOpen(false)}>
						{pageText.Cancel}
					</button>
					<button
						className={edited && !targetTimeError ? s.modalConfirmBtn : s.modalDisabledBtn}
						disabled={isLoading || targetTimeError}
						onMouseDown={() => {
							saveToDb();
							setModalOpen(false);
						}}
					>
						{pageText.Save}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}

export default DetailsModal;

const Dropdown = React.memo(({ startingValue, options, property, onChange }) => {
	const [selectedValue, setSelectedValue] = useState(startingValue);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);

		// Call the onChange prop if it's provided
		if (onChange) {
			onChange(event, property);
		}
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-start" }}>
			<select
				className={s.modalQuestionSelect}
				style={{ color: "#414042", border: "1px solid #ced4da" }}
				value={selectedValue}
				onChange={handleChange}
			>
				<option value="">Select...</option>
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
});
