import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { FaHistory, FaRegFolderClosed, FaUser } from "react-icons/fa/index.esm.js";
import axios from "axios";

import { GrLanguage } from "react-icons/gr";
import {
	FiMenu,
	FiCornerUpLeft,
	FiRepeat,
	FiUser,
	FiLogOut,
	FiEdit,
	FiShare,
	FiSidebar,
	FiMoreHorizontal,
	FiMessageSquare,
	FiRotateCcw,
	FiTrash2
} from "react-icons/fi";
import { z } from "zod";
import { HiOutlineFolder } from "react-icons/hi2";
import { IoSettingsSharp, IoClose, IoHomeSharp } from "react-icons/io5/index.esm.js";
import { MdGeneratingTokens } from "react-icons/md/index.esm.js";
import { RiBarChartFill, RiListCheck } from "react-icons/ri";
import { useAuth, AdminPortal } from "@frontegg/react";
import { format, parseISO } from "date-fns";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { obfuscate, deobfuscate, decryptWithAES } from "../UtilityFunctions/DataUtility.js";
import s from "../../styleModules/secondaryStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import logoIcon from "../../images/logoIcon.png";
import logo from "../../images/logoLarge.png";
import senecaLogo from "../../images/senecaLogo.png";
import senecaLogoIcon from "../../images/senecaLogoIcon.png";
import circles from "../../images/circles.png";
import ShareModal from "./ShareModal.js";
import { useAxiosLimited, axiosRetryPost, axiosRetryGet } from "../UtilityFunctions/axiosRetry.js";
import { LoadingSpinner } from "./LoadingSpinner.jsx";
import ModalWrapper from "./ModalWrapper.js";
import tenantLogoConfig from "./tenantLogoConfig.js";

import AudioVideoSettingsModal from "./AudioVideoSettingsModal.js";
import LanguageSettingsModal from "./LanguageSettingsModal.js";
import { useFeatureFlagEnabled } from "posthog-js/react";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;
const ltiBackend = process.env.REACT_APP_LTI_BACKEND_URL;

const useOutsideClick = (ref, callback) => {
	const handlePageClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handlePageClick);

		return () => {
			document.removeEventListener("click", handlePageClick);
		};
	});
};

const TenantLogo = ({ tenantId, isMobileView }) => {
	// Select tenant config or fall back to default if not found
	const tenant = tenantLogoConfig[tenantId] || tenantLogoConfig["default"];

	return (
		<>
			<img
				src={isMobileView ? tenant.logo.mobile : tenant.logo.desktop}
				alt={`${tenant.name} Logo`}
				title={`${tenant.name} Logo`}
				className={s.logo}
			/>
			{!isMobileView && tenant.poweredBy && <p className={s.whiteLabelText}>Powered by InStage</p>}
		</>
	);
};

export default function NavHeader({ setActiveReport, activeReport, setShowPostPreviewModal }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { savedId, simId } = useParams();

	const { isAuthenticated } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams();

	const {
		pauseSimRef,
		user,
		tenantId,
		tenantMetaData,
		localUser,
		handleNavigate,
		adminAccess,
		instageUser,
		activeSessionData,
		sessionList,
		setSessionList,
		showSidebar,
		setShowSidebar,
		adminLinkList,
		setActiveAdminLink,
		fetchAndSetFullSessionHistory,
		fullSessionHistory,
		resumeAssistAccess,
		runTranslation,
		userLanguage,
		translateDictText,
		performTranslation,
		setupData
	} = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Created: "Created",
		ContinueSession: "Continue Session",
		Continue: "Continue",
		desktopNavButtonsHome: "Home",
		desktopNavButtonsSessions: "Sessions",
		desktopNavButtonsReports: "Reports",
		desktopNavButtonsAdmin: "Admin",
		desktopNavButtonsAccount: "Account",
		desktopNavButtonsMobile: "Mobile",
		YourSessions: "Your Sessions",
		AudioAndVideo: "Audio & Video",
		Share: "Share",
		LearnMore: "Learn More",
		Reset: "Reset",
		Retry: "Retry",
		ResetReport: "Reset Report",
		AreYouSureYouWantToResetThisSessionReportAndRegenerateNewData:
			"Are you sure you want to reset this session report and regenerate new data? This action cannot be undone.",
		NoteABackupOfTheSessionPayloadJSONWillBeDownloadedForYourRecords:
			"Note: A backup of the session payload JSON will be downloaded for your records.",
		Cancel: "Cancel",
		AreYouSureYouWantToDeleteThisSession: "Are you sure you want to delete this session? This action cannot be undone.",
		AreYouSureYouWantToReturnToTheSetupScreen: "Are you sure you want to return to the setup screen?",
		YouWillBeAbleToResumeThisSimulationFromTheHomePage: "You will be able to resume this simulation from the home page.",
		DeleteReport: "Delete Report",
		AreYouSureYouWantToDeleteThisSessionReport: "Are you sure you want to delete this session report? This action cannot be undone.",
		Delete: "Delete"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	const getLtik = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("ltik");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	const getLmsToken = () => {
		// console.log("getLmsToken");
		const lmsToken = searchParams.get("token");
		//console.log(lmsToken);
		if (!lmsToken) return false;
		return lmsToken;
	};
	async function submitGrade(grade, outcome, summary_feedback) {
		if (typeof grade === "string") {
			// eslint-disable-next-line no-param-reassign
			grade = parseInt(grade, 10);
		}
		if (grade === null || grade === undefined) {
			grade = 0;
		}
		let feedbackString = outcome || "";
		if (summary_feedback) {
			feedbackString += `\n\n${summary_feedback.positives}`;
			feedbackString += `\n\n${summary_feedback.improvements}`;
		}
		const gradeDataSchema = z.object({
			grade: z.number().min(0).max(100).default(0),
			feedback: z.string().default(""),

			client_id: z.string().default(""),
			instage_id: z.string().default("")
		});
		// console.log("submitGrade");
		const lmsToken = getLmsToken();
		const ltik = getLtik();
		// console.log(lmsToken, ltik);
		const gradeData = gradeDataSchema.parse({
			grade,
			feedback: feedbackString,
			client_id: localUser.tenantId,
			instage_id: localUser.id
		});
		if (getLmsToken() && getLtik()) {
			try {
				const response = await axios.post(`${ltiBackend}/grade`, gradeData, {
					headers: { Authorization: `Bearer ${getLtik()}` }
				});
				console.log(response);
				console.log(activeReport.session_id);
				const result = await axiosLimitedPost(
					`${url}/api/sessionData/session/submitted`,
					{ session_id: activeReport.session_id, status: true },
					1
				);
				setActiveReport({ ...activeReport, submitted: true });
			} catch (error) {
				console.log(error);
			}
		}
	}
	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const instage_id = localUser ? localUser.id : null;
	const isSeneca = tenantId === "001-76544-76699-74447" || tenantId === "002-46546-66025-56742";

	const navigate = useNavigate();
	const location = useLocation();
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1000);
	const [reportSubmitted, setReportSubmitted] = useState("loading");
	const [selectedSessionId, setSelectedSessionId] = useState(-1);
	const [selectedReportId, setSelectedReportId] = useState(-1);
	const [isResetLoading, setIsResetLoading] = useState(false);

	// Modal states
	const [showShareModal, setShowShareModal] = useState(false);
	const [showBackModal, setShowBackModal] = useState(false);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
	const [showResetReportModal, setShowResetReportModal] = useState(false);

	// Dropdown states
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [visibleContent, setVisibleContent] = useState("");
	const [activeDropdownTitle, setActiveDropdownTitle] = useState("");
	const [audioVideoModalOpen, setAudioVideoModalOpen] = useState(false);
	const [languageModalOpen, setLanguageModalOpen] = useState(false);
	const dropdownRef = useRef();

	// useEffect(() => {
	// 	runTranslation();
	// });

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth < 1000);
		};

		// Call handleResize immediately to set the initial state correctly
		handleResize();

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const fetchSessionList = async (checkLocalUser = false) => {
		if (checkLocalUser && !localUser) {
			return;
		}

		try {
			const result = await axiosLimitedGet(`${url}/api/saved/simulation/list`, 1, { params: { instage_id } });

			// order the data where global is set to true first
			result.data.sort((a, b) => (a.global > b.global ? -1 : 1));
			setSessionList(result.data);
		} catch (e) {
			console.log(e.status);
			console.log(e);
		}
	};

	useEffect(() => {
		let isCancelled = false;

		const fetchList = async () => {
			if (!isCancelled) {
				fetchSessionList(true);
			}
		};

		fetchList();

		return () => {
			isCancelled = true;
		};
	}, [localUser]);

	useOutsideClick(dropdownRef, () => {
		if (isDropdownOpen) {
			setIsDropdownOpen(false);
		}
		setVisibleContent("");
		setActiveDropdownTitle("");
	});

	const [real_session_id, setReal_session_id] = useState("");

	// Helper function to group sessions by date
	const groupSessionsByDate = (sessions) => {
		sessions = sessions.filter((session) => session.ongoing !== true);
		// Group sessions by date
		const groupedSessions = sessions.reduce((acc, session) => {
			try {
				const date = format(parseISO(session.start_time), "yyyy-MM-dd");
				if (!acc[date]) {
					acc[date] = [];
				}
				acc[date].push(session);
			} catch (e) {
				console.log("session.start_time: ", session.start_time);
				console.log("Error in groupSessionsByDate: ", e);
			}

			return acc;
		}, {});

		// Sort sessions within each group in descending order
		Object.values(groupedSessions).forEach((sessionGroup) => {
			sessionGroup.sort((a, b) => {
				const dateA = new Date(a.start_time);
				const dateB = new Date(b.start_time);
				return dateB - dateA;
			});
		});

		return groupedSessions;
	};

	// Group the sessions by date
	const groupedSessions = groupSessionsByDate(fullSessionHistory);
	const ongoingSessions = fullSessionHistory.filter((session) => session.ongoing === true);

	const locationHref = window.location.href;
	const isReport = locationHref.includes("report");

	// useEffect(() => {
	// 	if (real_session_id != "") {
	// 		setReportSubmitted("loading");
	// 		loadSubmission(real_session_id).then((result) => {
	// 			// console.log("loadSubmission result: ", result)
	// 			setReportSubmitted(result);
	// 		});
	// 	}
	// }, [real_session_id]);

	useEffect(() => {
		if (isReport) {
			let temp_real_session_id = locationHref.split("report/")[1];
			const deobfuscated_session_id = deobfuscate(temp_real_session_id);
			if (deobfuscated_session_id !== "") {
				temp_real_session_id = deobfuscated_session_id;
			}
			if (temp_real_session_id !== real_session_id) {
				setReal_session_id(temp_real_session_id);
			}
		}
	}, [isReport, locationHref]);

	const [lmsAlreadySubmitted, setLmsAlreadySubmitted] = useState(false);
	useEffect(() => {
		// console.log(activeReport);
		if (activeReport) {
			axiosLimitedGet(`${url}/api/sessionData/session/submitted/savedSim`, 1, {
				params: { simulation_id: activeReport.simulation_fk }
			}).then((result) => {
				console.log(result);
				if (result.data.submittedSessions.length > 0) {
					setLmsAlreadySubmitted(true);
				}
			});
		}
	}, [activeReport]);
	const ongoingSetupIds = {
		interview: "interview",
		discovery: "discovery",
		discovery_spin: "discovery_spin",
		closing: "closing",
		retail: "retail",
		presentation: "presentation",
		intro: "intro",
		freestyle: "freestyle",
		patient: "patient",
		pitch: "pitch",
		business_pitch: "business_pitch",
		reflection: "reflection"
	};

	const tryAgain = async () => {
		const setupType = activeReport.setup_type;
		const path = `/setup/${setupType}/${activeReport.simulation_fk}`;

		setShowSidebar(false);
		navigate(path);
	};

	const navigateHome = () => {
		setActiveAdminLink("");
		handleNavigate("/");
	};

	function goBack() {
		let newPathname = location.pathname.replace(`/${simId}`, "");
		newPathname = window.location.pathname.includes("simulation") ? newPathname.replace("simulation", "setup") : newPathname;

		handleNavigate(window.location.origin + newPathname, true);
	}

	const switchDropdownContent = (title) => {
		switch (title) {
			case `${pageText.desktopNavButtonsSessions}`:
				setVisibleContent(
					<SessionsDropdownContent
						sessionList={sessionList}
						setSelectedSessionId={setSelectedSessionId}
						setShowDeleteSessionModal={setShowDeleteSessionModal}
						setIsDropdownOpen={setIsDropdownOpen}
						resumeAssistAccess={resumeAssistAccess}
					/>
				);
				break;
			case `${pageText.desktopNavButtonsReports}`:
				setVisibleContent(
					<ReportsDropdownContent
						fullSessionHistory={fullSessionHistory}
						groupedSessions={groupedSessions}
						activeReport={activeReport}
						setIsDropdownOpen={setIsDropdownOpen}
						setSelectedReportId={setSelectedReportId}
						setShowDeleteReportModal={setShowDeleteReportModal}
					/>
				);
				break;
			case `${pageText.desktopNavButtonsAdmin}`:
				setVisibleContent(
					<AdminDropdownContent
						adminLinkList={adminLinkList}
						setShowSidebar={setShowSidebar}
						setActiveAdminLink={setActiveAdminLink}
						setIsDropdownOpen={setIsDropdownOpen}
						tenantMetaData={tenantMetaData}
					/>
				);
				break;
			case `${pageText.desktopNavButtonsAccount}`:
				setVisibleContent(
					<AccountDropdownContent setAudioVideoModalOpen={setAudioVideoModalOpen} setLanguageModalOpen={setLanguageModalOpen} />
				);
				break;
			case "Mobile":
				setVisibleContent(<MobileDropdownContent toggleDropdowns={toggleDropdowns} navigateHome={navigateHome} sessionList={sessionList} />);
				break;
			default:
				setVisibleContent("");
				break;
		}
	};

	function toggleDropdowns(title) {
		switchDropdownContent(title);

		if (!isDropdownOpen) {
			setActiveDropdownTitle(title);
			setIsDropdownOpen(true);
			if (title === `${pageText.desktopNavButtonsSessions}`) {
				fetchSessionList();
			}
			if (title === `${pageText.desktopNavButtonsReports}`) {
				fetchAndSetFullSessionHistory();
			}
		} else if (activeDropdownTitle === title) {
			setActiveDropdownTitle("");
			setIsDropdownOpen(false);
		} else {
			setActiveDropdownTitle(title);
		}
	}

	const deleteSession = async (simulation_id) => {
		const result = await axiosLimitedDelete(`${url}/api/saved/simulation`, 1, { params: { simulation_id } });
		fetchSessionList();

		if (savedId && savedId === simulation_id.toString()) {
			navigateHome();
		}
	};

	const deleteReport = async (session_id) => {
		const result = await axiosLimitedDelete(`${url}/api/sessionData/session`, 1, { params: { session_id } });
		console.log("fetchAndSetFullSessionHistory NavHeader.js deleteReport");

		fetchAndSetFullSessionHistory();

		// If the user is on the report page of the deleted session, navigate to the home page
		if (activeReport && activeReport.session_id === session_id) {
			setShowSidebar(false);
			navigateHome();
		}
	};

	const resetReport = async () => {
		if (!activeReport || !activeReport.session_id || !activeReport.setup_type) {
			return;
		}

		try {
			setIsResetLoading(true);
			const { session_id, setup_type } = activeReport;
			const jsonData = JSON.stringify(activeReport);
			const blob = new Blob([jsonData], { type: "application/json" });
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = blobUrl;
			link.setAttribute("download", `session_payload_${session_id}.json`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(blobUrl); // Revoke the URL to free up memory
			const resp = await axiosLimitedPost(`${url}/api/sessionData/session/reset`, { session_id, setup_type }, 1, {});
			window.location.reload();
		} catch (error) {
			console.error("Error resetting report:", error);
			setIsResetLoading(false);
		}
	};

	const navHeaderStyle = isMobileView ? { padding: simId ? "0 2%" : "0 2%" } : { padding: simId ? "0 2%" : "0 7rem" };

	const desktopNavButtons = [
		{ title: `${pageText.desktopNavButtonsHome}`, icon: <IoHomeSharp title={pageText.desktopNavButtonsHome} className={s.headerBtnIcon} /> },
		{
			title: `${pageText.desktopNavButtonsSessions}`,
			icon: <RiListCheck title={pageText.desktopNavButtonsSessions} className={s.headerBtnIcon} />
		},
		{
			title: `${pageText.desktopNavButtonsReports}`,
			icon: <RiBarChartFill title={pageText.desktopNavButtonsReports} className={s.headerBtnIcon} />
		},
		{
			title: `${pageText.desktopNavButtonsAdmin}`,
			icon: <IoSettingsSharp title={pageText.desktopNavButtonsAdmin} className={s.headerBtnIcon} />
		},
		{ title: `${pageText.desktopNavButtonsAccount}`, icon: <FaUser title={pageText.desktopNavButtonsAccount} className={s.headerBtnIcon} /> }
	];

	return (
		<>
			{audioVideoModalOpen && <AudioVideoSettingsModal pauseSimRef={pauseSimRef} setAudioVideoModalOpen={setAudioVideoModalOpen} />}
			{languageModalOpen && <LanguageSettingsModal pauseSimRef={pauseSimRef} setLanguageModalOpen={setLanguageModalOpen} />}
			{showBackModal && (
				<ModalWrapper closeModal={() => setShowBackModal(false)}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
							<FiCornerUpLeft className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowBackModal(false)} />
						<div className={styles2.modalTextContainer}>
							<h1 className={styles2.modalTitle}>{pageText.AreYouSureYouWantToReturnToTheSetupScreen}</h1>
							<p className={styles2.modalText}>{pageText.YouWillBeAbleToResumeThisSimulationFromTheHomePage}</p>
						</div>
						<div className={styles2.modalBtnContainer}>
							<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowBackModal(false)}>
								Cancel
							</button>
							<button type="button" className={styles2.modalWarningBtn} onClick={goBack}>
								Return
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}
			{showDeleteSessionModal && (
				<ModalWrapper closeModal={() => setShowDeleteSessionModal(false)}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
							<FiTrash2 className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowDeleteSessionModal(false)} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>Delete Session</h2>
							<p className={styles2.modalText}>{pageText.AreYouSureYouWantToDeleteThisSession}</p>
						</div>
						<div className={styles2.modalBtnContainer}>
							<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowDeleteSessionModal(false)}>
								Cancel
							</button>
							<button
								type="button"
								className={styles2.modalWarningBtn}
								disabled={false}
								onClick={() => {
									deleteSession(selectedSessionId);
									setShowDeleteSessionModal(false);
								}}
							>
								Delete
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}
			{showDeleteReportModal && (
				<ModalWrapper closeModal={() => setShowDeleteReportModal(false)}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
							<FiTrash2 className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowDeleteReportModal(false)} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>{pageText.DeleteReport}</h2>
							<p className={styles2.modalText}>{pageText.AreYouSureYouWantToDeleteThisSessionReport}</p>
						</div>
						<div className={styles2.modalBtnContainer}>
							<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowDeleteReportModal(false)}>
								{pageText.Cancel}
							</button>
							<button
								type="button"
								className={styles2.modalWarningBtn}
								disabled={false}
								onClick={() => {
									deleteReport(selectedReportId);
									setShowDeleteReportModal(false);
								}}
							>
								{pageText.Delete}
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}
			{showResetReportModal && (
				<ModalWrapper closeModal={() => setShowResetReportModal(false)}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
							<FiRotateCcw className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowResetReportModal(false)} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>{pageText.ResetReport}</h2>
							<p className={styles2.modalText}>{pageText.AreYouSureYouWantToResetThisSessionReportAndRegenerateNewData}</p>
							<p className={styles2.modalNote}>{pageText.NoteABackupOfTheSessionPayloadJSONWillBeDownloadedForYourRecords}</p>
						</div>
						<div className={styles2.modalBtnContainer}>
							{isResetLoading ? (
								<div className={styles2.modalSpinnerContainer}>
									<LoadingSpinner height={54} width={54} thickness={5} color="#00a9af" />
								</div>
							) : (
								<>
									<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowResetReportModal(false)}>
										{pageText.Cancel}
									</button>
									<button type="button" className={styles2.modalWarningBtn} disabled={false} onClick={() => resetReport()}>
										{pageText.Reset}
									</button>
								</>
							)}
						</div>
					</div>
				</ModalWrapper>
			)}
			<ShareModal
				show={showShareModal}
				onHide={() => setShowShareModal(false)}
				instage_id={localUser?.id}
				OnReportSubmitted={setReportSubmitted}
				tenantId={tenantId}
			/>

			<div className={s.navHeader} style={navHeaderStyle}>
				<div
					className={s.navHeaderContainer}
					style={{ maxWidth: window.location.href.includes("simulation") || window.location.href.includes("report") ? "none" : "80rem" }}
				>
					<div className={s.homeBtnContainer}>
						<div
							className={s.homeBtn}
							role="button"
							tabIndex={0}
							onKeyDown={() => {
								setShowSidebar(false);
								setActiveAdminLink("");
								if (location.pathname.includes("preview")) {
									handleNavigate("http://www.instage.io", true);
								} else {
									navigateHome();
								}
							}}
							onClick={() => {
								setShowSidebar(false);
								setActiveAdminLink("");
								if (location.pathname.includes("preview")) {
									handleNavigate("http://www.instage.io", true);
								} else {
									navigateHome();
								}
							}}
						>
							<TenantLogo tenantId={tenantId} isMobileView={isMobileView} />
						</div>
						{activeSessionData !== null && !location.pathname.includes("preview") && (
							<button aria-label="Back" type="button" title="Back" className={s.headerBtn} onClick={() => setShowBackModal(true)}>
								<FiCornerUpLeft title="Back" className={s.headerBtnIcon} />
							</button>
						)}
					</div>

					{!location.pathname.includes("setup") && location.pathname.includes("preview") && (
						<div className={s.previewHeaderContainer}>
							<p className={s.previewTitle}>Self-Introduction Preview</p>
						</div>
					)}

					{isDropdownOpen && (
						<div ref={dropdownRef} className={s.headerDropdown}>
							{isMobileView && activeDropdownTitle !== "Mobile" && (
								<div className={s.headerDropdownTitle}>
									<FiCornerUpLeft
										title="Back"
										type="button"
										className={s.headerDropdownIcon}
										onClick={(e) => {
											e.stopPropagation();
											toggleDropdowns("Mobile");
										}}
									/>
								</div>
							)}
							{visibleContent}
						</div>
					)}
					<div className={s.headerBtnContainer}>
						{user &&
							ongoingSessions?.length > 0 &&
							!["simulation", "report", "preview"].some((path) => window.location.href.includes(path)) && (
								<button
									type="button"
									className={s.ongoingBtn}
									onClick={() => {
										handleNavigate(
											`/simulation/${ongoingSetupIds[ongoingSessions[0].setup_type]}/${ongoingSessions[0].simulation_fk}/${
												ongoingSessions[0].session_id
											}`,
											true
										);

										// navigate(
										// 	`/simulation/${ongoingSetupIds[ongoingSessions[0].setup_type]}/${ongoingSessions[0].simulation_fk}/${
										// 		ongoingSessions[0].session_id
										// 	}`
										// );
									}}
								>
									{window.innerWidth > 1000 ? `${pageText.ContinueSession}` : `${pageText.ContinueSession}`}
								</button>
							)}
						{isReport && activeReport && user && (
							<>
								{instageUser && window.innerWidth > 1000 ? (
									<button type="button" className={s.headerBtnLarge} onClick={() => setShowResetReportModal(true)}>
										<FiRotateCcw className={s.headerBtnIcon} />
										{pageText.Reset}
									</button>
								) : instageUser ? (
									<FiRotateCcw className={s.headerBtnIcon} onClick={() => setShowResetReportModal(true)} />
								) : null}
								{window.innerWidth > 1000 ? (
									<button type="button" className={s.headerBtnLarge} onClick={() => tryAgain()}>
										<FiRepeat className={s.headerBtnIcon} />
										{pageText.Retry}
									</button>
								) : (
									<FiRepeat className={s.headerBtnIcon} onClick={() => tryAgain()} />
								)}
							</>
						)}

						{getLtik() && getLmsToken() && isReport && (
							<button
								type="button"
								className={s.headerBtnLarge}
								disabled={activeReport?.submitted || lmsAlreadySubmitted}
								onClick={() => submitGrade(activeReport.overall_score, activeReport.outcome, activeReport.summary_feedback)}
							>
								{activeReport?.submitted ? "Grade Submitted" : lmsAlreadySubmitted ? "Already Submitted" : "Submit Grade"}
							</button>
						)}

						{!location.pathname.includes("preview") &&
							!location.pathname.includes("guest-setup") &&
							(location.pathname.includes("setup") ||
								(isReport && activeReport && localUser && activeReport.instage_id === localUser.id && !getLtik())) && (
								<>
									{window.innerWidth > 1000 ? (
										<button type="button" className={s.headerBtnLarge} onClick={() => setShowShareModal(true)}>
											<FiShare className={s.headerBtnIcon} />
											{/* {isReport && reportSubmitted === "loading" && (
                                            <div style={{ height: "40px", marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                <LoadingSpinner height={22} width={22} thickness={3} color="white" />
                                            </div>
                                        )} */}
											{pageText.Share}
										</button>
									) : (
										<FiShare className={s.headerBtnIcon} onClick={() => setShowShareModal(true)} />
									)}
								</>
							)}
						{location.pathname.includes("preview") && (
							<>
								<button type="button" className={s.ongoingBtn} onClick={() => setShowPostPreviewModal(true)}>
									{pageText.LearnMore}
								</button>
							</>
						)}
						{!isAuthenticated && (
							<button
								aria-label="Audio & Video"
								type="button"
								className={s.headerDropdownBtn}
								style={{ height: "40px", width: "160px", justifyContent: "center", alignItems: "center", padding: "10px" }}
								onClick={() => {
									setAudioVideoModalOpen(true);
								}}
							>
								<IoSettingsSharp />
								{pageText.AudioAndVideo}
							</button>
						)}
						{isMobileView && isAuthenticated && (
							<>
								{(window.location.pathname.includes("simulation") || window.location.pathname.includes("report")) && (
									<MdGeneratingTokens
										aria-label="Transcript"
										className={`${s.headerBtnMobile} ${showSidebar ? s.headerBtnMobileActive : ""}`}
										onClick={() => setShowSidebar(!showSidebar)}
									/>
								)}
								<FiMenu
									aria-label="Menu"
									className={`${s.headerBtnMobile} ${
										isDropdownOpen && activeDropdownTitle === "Mobile" ? s.headerBtnMobileActive : ""
									}`}
									onClick={(e) => {
										e.stopPropagation();
										toggleDropdowns("Mobile");
									}}
								/>
							</>
						)}
						{!isMobileView &&
							isAuthenticated &&
							desktopNavButtons.map(({ title, icon }) => {
								// console.log("localUser: ", localUser.roles[0].key);
								if (
									(title === `${pageText.desktopNavButtonsAdmin}` && !adminAccess) ||
									(title === `${pageText.desktopNavButtonsAdmin}` && localUser.roles[0].key === "Faculty")
								) {
									return null;
								}
								if (title === `${pageText.desktopNavButtonsSessions}` && (!sessionList || sessionList.length === 0)) {
									return null;
								}
								return (
									<button
										key={title}
										type="button"
										title={title}
										aria-label={title}
										className={`${s.headerBtn} ${activeDropdownTitle === title ? s.headerBtnActive : ""}`}
										onClick={(e) => {
											if (title === `${pageText.desktopNavButtonsHome}`) {
												navigateHome();
											} else {
												e.stopPropagation();
												toggleDropdowns(title);
											}
										}}
									>
										{icon}
										{isMobileView && <span className={s.headerBtnText}>{title}</span>}
									</button>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
}

function MobileDropdownContent({ toggleDropdowns, navigateHome, sessionList }) {
	const handleMobileNavClick = (e, title) => {
		e.stopPropagation();
		toggleDropdowns(title);
	};

	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		desktopNavButtonsHome: "Home",
		desktopNavButtonsSessions: "Sessions",
		desktopNavButtonsReports: "Reports",
		desktopNavButtonsAdmin: "Admin",
		desktopNavButtonsAccount: "Account",
		desktopNavButtonsMobile: "Mobile"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};

		translateText();
	}, [userLanguage, sessionList]); // Depend on userLanguage to trigger re-translation

	return (
		<div className={s.headerDropdownBtnList}>
			<button type="button" className={s.headerDropdownBtn} onClick={() => navigateHome()}>
				<IoHomeSharp />
				{pageText.desktopNavButtonsHome}
			</button>
			{sessionList && sessionList.length > 0 && (
				<button
					type="button"
					className={s.headerDropdownBtn}
					onClick={(e) => handleMobileNavClick(e, `${pageText.desktopNavButtonsSessions}`)}
				>
					<RiListCheck />
					{pageText.desktopNavButtonsSessions}
				</button>
			)}
			<button type="button" className={s.headerDropdownBtn} onClick={(e) => handleMobileNavClick(e, `${pageText.desktopNavButtonsReports}`)}>
				<RiBarChartFill />
				{pageText.desktopNavButtonsReports}
			</button>
			{(localUser.roles[0].key === "Admin" || localUser.roles[0].key === "Owner" || localUser.roles[0].key === "InStageAdmin") && (
				<button type="button" className={s.headerDropdownBtn} onClick={(e) => handleMobileNavClick(e, `${pageText.desktopNavButtonsAdmin}`)}>
					<IoSettingsSharp />
					{pageText.desktopNavButtonsAdmin}
				</button>
			)}
			<button type="button" className={s.headerDropdownBtn} onClick={(e) => handleMobileNavClick(e, `${pageText.desktopNavButtonsAccount}`)}>
				<FaUser />
				{pageText.desktopNavButtonsAccount}
			</button>
		</div>
	);
}

function SessionsDropdownContent({ sessionList, setSelectedSessionId, setShowDeleteSessionModal, setIsDropdownOpen, resumeAssistAccess }) {
	const navigate = useNavigate();

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);
	const [dynamicPageText, setDynamicPageText] = useState([...sessionList]);

	// Static Translation
	const LocalLanguageDict = {
		YourSessions: "Your Sessions"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	// dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!sessionList.length) return; // Early exit if sessionList is empty

			const propertiesToTranslate = ["setup_name", "setup_type", "assignment_name"]; // "setup_type" too
			let updatedSessionList = [...sessionList];
			// eslint-disable-next-line no-restricted-syntax
			for (const property of propertiesToTranslate) {
				const texts = updatedSessionList.map((item) => item[property]);
				if (!texts.length) continue; // Skip if texts are empty

				try {
					const translatedTexts = await translateDictText(texts, userLanguage);
					updatedSessionList = updatedSessionList.map((item, index) => ({
						...item,
						[property]: translatedTexts[index] || item[property] // Use translated text if available, otherwise use original
					}));
				} catch (error) {
					console.error(`Error translating ${property}:`, error);
				}
			}

			setDynamicPageText(updatedSessionList);
		}

		performTranslations();
	}, [sessionList, userLanguage, translateDictText]);

	return (
		<div
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => {
				if (event.key === "Enter" || event.key === " ") {
					event.stopPropagation();
				}
			}}
			role="button"
			tabIndex={0}
		>
			<p className={s.headerDropdownTitle}>{`${pageText.YourSessions}`}:</p>
			<div className={s.headerDropdownBtnList}>
				{dynamicPageText?.length > 0 &&
					dynamicPageText.map((session, index) => {
						let typeValue = session.setup_type;
						if (session.setup_type === "interview" && resumeAssistAccess) {
							typeValue = "Job Prep";
						}
						if (session.setup_type === "pitch") {
							typeValue = "Elevator Pitch";
						}
						const sessionUrl = `/setup/${sessionList[index].setup_type}`;
						// console.log("session: ", session.assignment_name);
						return (
							<div
								key={index}
								className={s.headerDropdownBtn}
								style={{ justifyContent: "space-between" }}
								onClick={() => {
									navigate(`${sessionUrl}/${session.simulation_id}`);
									setIsDropdownOpen(false);
								}}
								onKeyDown={(event) => {
									if (event.key === "Enter" || event.key === " ") {
										navigate(`${sessionUrl}/${session.simulation_id}`);
										setIsDropdownOpen(false);
									}
								}}
								role="button"
								tabIndex={0}
							>
								<div className={s.headerDropdownBtnTextContainer}>
									<p className={s.headerDropdownBtnTitle}>{session.setup_name}</p>

									{session.assignment_name && (
										<p className={s.headerDropdownBtnText} style={{ color: "#00a9af" }}>
											{session.assignment_name.length > 30
												? `${session.assignment_name.substring(0, 30)}...`
												: session.assignment_name}
										</p>
									)}

									<p className={s.headerDropdownBtnText}>{typeValue.charAt(0).toUpperCase() + typeValue.slice(1)}</p>
									<p className={s.headerDropdownBtnText}>
										{pageText.Created}{" "}
										{new Date(session.created_date).toLocaleDateString(`${userLanguage}-US`, {
											year: "numeric",
											month: "long",
											day: "numeric"
										})}
									</p>
								</div>
								<div
									className={s.headerDropdownBtnIconContainer}
									onClick={(e) => {
										e.stopPropagation();
										setSelectedSessionId(session.simulation_id);
										setShowDeleteSessionModal(true);
									}}
									onKeyDown={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											event.stopPropagation();
											setSelectedSessionId(session.simulation_id);
											setShowDeleteSessionModal(true);
										}
									}}
									role="button"
									tabIndex={0}
									aria-label="Delete"
								>
									<FiTrash2 />
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}

function ReportsDropdownContent({
	fullSessionHistory,
	groupedSessions,
	activeReport,
	setIsDropdownOpen,
	setSelectedReportId,
	setShowDeleteReportModal
}) {
	const navigate = useNavigate();

	const convertTime = (seconds) => {
		// convert seconds to mm:ss format
		const minutes = Math.floor(seconds / 60);
		let secondsLeft = seconds % 60;
		if (secondsLeft < 10) {
			secondsLeft = `0${secondsLeft}`;
		}
		return `${minutes}:${secondsLeft}`;
	};

	const { userLanguage, translateDictText, performTranslation, tenantMetaData } = useContext(AppContext);
	const [dynamicPageText, setDynamicPageText] = useState({ ...groupedSessions });

	// console.log("groupedSessions: ", groupedSessions);

	// Static Translation
	const LocalLanguageDict = {
		YourSessions: "Your Sessions",
		Created: "Created",
		SimulationReports: "Simulation Reports",
		Duration: "Duration",
		Incomplete: "Incomplete"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Reports Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	// dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!Object.keys(groupedSessions).length) return; // Early exit if groupedSessions is empty

			const propertiesToTranslate = ["setup_name", "setup_type", "outcome", "assignment_name"];
			let updatedGroupedSessions = { ...groupedSessions };
			let textsToTranslate = new Set();

			// Collect all unique texts to translate
			// eslint-disable-next-line
			for (const date in updatedGroupedSessions) {
				if (updatedGroupedSessions.hasOwnProperty(date)) {
					const sessions = updatedGroupedSessions[date];
					sessions.forEach((session) => {
						propertiesToTranslate.forEach((property) => {
							const value = session[property];
							if (value && !textsToTranslate.has(value)) {
								textsToTranslate.add(value);
							}
						});
					});
				}
			}

			// Convert Set to Array for API call
			textsToTranslate = Array.from(textsToTranslate);

			try {
				const translatedTexts = await translateDictText(textsToTranslate, userLanguage);
				// Create a map of original text to translated text
				const translationMap = textsToTranslate.reduce((acc, text, index) => {
					acc[text] = translatedTexts[index] || text; // Use translated text if available, otherwise use original
					return acc;
				}, {});

				// Apply translations to the grouped sessions
				// eslint-disable-next-line
				for (const date in updatedGroupedSessions) {
					if (updatedGroupedSessions.hasOwnProperty(date)) {
						updatedGroupedSessions[date] = updatedGroupedSessions[date].map((session) => {
							propertiesToTranslate.forEach((property) => {
								if (session[property]) {
									session[property] = translationMap[session[property]];
								}
							});
							// console.log(session);
							return session;
						});
					}
				}

				setDynamicPageText(updatedGroupedSessions);
			} catch (error) {
				console.error("Error translating texts:", error);
			}
		}

		performTranslations();
	}, [groupedSessions, userLanguage, translateDictText]);

	return (
		<div
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => {
				if (event.key === "Enter" || event.key === " ") {
					event.stopPropagation();
				}
			}}
			role="button"
			tabIndex={0}
		>
			<p className={s.headerDropdownTitle}>{pageText.SimulationReports}:</p>
			{fullSessionHistory && fullSessionHistory.length > 0 ? (
				Object.entries(dynamicPageText).map(([date, sessions], index) => (
					<React.Fragment key={index}>
						<p className={s.headerDropdownSubtitle}>{new Date(date).toLocaleString(userLanguage, { month: "long", day: "numeric" })}</p>
						<div className={s.headerDropdownBtnList}>
							{sessions.map((session, index) => {
								const session_id = session.gpt_session_id ? session.gpt_session_id : session.session_id;
								const real_session_id = session.gpt_session_id ? session.gpt_session_id : `i_${session.session_id}`;
								const outcome = session.outcome ? `${session.outcome.split(" ")[0]}` : `${pageText.Incomplete}`;
								const duration = session.duration ? convertTime(Math.round(session.duration)) : convertTime(Math.round(0));
								const startTime = new Date(session.start_time).toLocaleTimeString(userLanguage, {
									hour: "2-digit",
									minute: "2-digit",
									hour12: true
								});

								const dateObject = new Date(session.start_time);
								const day = dateObject.getDate();
								const month = dateObject.toLocaleString(userLanguage, { month: "short" });
								let dayWithSuffix = day;

								if (day % 10 === 1 && day !== 11) {
									dayWithSuffix = `${day}st`;
								} else if (day % 10 === 2 && day !== 12) {
									dayWithSuffix = `${day}nd`;
								} else if (day % 10 === 3 && day !== 13) {
									dayWithSuffix = `${day}rd`;
								} else {
									dayWithSuffix = `${day}th`;
								}

								let formattedDate = `${month} ${dayWithSuffix}`;
								let showCommentIcon = false;
								if (session.comments) {
									showCommentIcon = true;
								}

								return (
									<div
										key={index}
										className={
											activeReport?.session_id === session_id
												? `${s.headerDropdownBtn} ${s.activeHeaderDropdownBtn}`
												: s.headerDropdownBtn
										}
										style={{ justifyContent: "space-between" }}
										onClick={() => {
											setIsDropdownOpen(false);
											navigate(`/report/${obfuscate(real_session_id)}`);
										}}
										onKeyDown={(event) => {
											if (event.key === "Enter" || event.key === " ") {
												setIsDropdownOpen(false);
												navigate(`/report/${obfuscate(real_session_id)}`);
											}
										}}
										role="button"
										tabIndex={0}
									>
										<div className={s.headerDropdownBtnTextContainer}>
											<p className={s.headerDropdownBtnTitle}>
												{session.setup_type?.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase())}:{" "}
												{session.setup_name ? session.setup_name : session.job_title}
											</p>
											{session.assignment_name && (
												<p
													className={s.headerDropdownBtnText}
													style={{
														color: outcome.includes("Incomplete")
															? "#16013e"
															: outcome.includes("Unsuccessful")
															? "#ee4188"
															: "#00a9af"
													}}
												>
													{session.assignment_name.length > 30
														? `${session.assignment_name.substring(0, 30)}...`
														: session.assignment_name}
												</p>
											)}
											<span className={s.headerDropdownBtnText}>
												<span>
													<div>
														<span
															style={{
																color: outcome.includes("Incomplete")
																	? "#16013e"
																	: outcome.includes("Unsuccessful")
																	? "#ee4188"
																	: "#00a9af"
															}}
														>
															{outcome}
														</span>
													</div>
													{pageText.Duration}: {duration}
												</span>
												<span>
													{startTime}
													<br />
													{formattedDate}
												</span>
											</span>
										</div>
										<div
											className={s.headerDropdownBtnIconContainer}
											onClick={(e) => {
												e.stopPropagation();
												setSelectedReportId(session_id);
												setShowDeleteReportModal(true);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter" || event.key === " ") {
													event.stopPropagation();
													setSelectedReportId(session_id);
													setShowDeleteReportModal(true);
												}
											}}
											role="button"
											tabIndex={0}
										>
											<FiTrash2 />
											{/* {showCommentIcon && <FiMessageSquare/>} */}
										</div>
									</div>
								);
							})}
						</div>
					</React.Fragment>
				))
			) : (
				<></>
			)}
		</div>
	);
}

function AdminDropdownContent({ adminLinkList, setShowSidebar, setActiveAdminLink, setIsDropdownOpen, tenantMetaData }) {
	const { axiosLimitedPost } = useAxiosLimited();

	const navigate = useNavigate();

	const { userLanguage, translateDictText, performTranslation, localUser, handleNavigate } = useContext(AppContext);

	const adminLinkValues = Object.keys(adminLinkList);

	const [pageText, setPageText] = useState({
		...adminLinkValues
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}
		// console.log("localUser: ", localUser);
		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	async function manageBilling() {
		const data = {
			customerId: tenantMetaData.stripeId,
			locale: "en"
		};
		const key = process.env.REACT_APP_BACKEND_KEY;
		const config = {};
		config.headers = { Authorization: `Bearer ${key}` };
		const resp = await axiosLimitedPost(`${url}/api/stripe/create-customer-portal-session`, data, 1, {});
		const parsedData = JSON.parse(decryptWithAES(resp.data));

		handleNavigate(parsedData.url, true);
	}

	return (
		<div
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => {
				if (event.key === "Enter" || event.key === " ") {
					event.stopPropagation();
				}
			}}
			role="button"
			tabIndex={0}
		>
			<div className={s.headerDropdownBtnList}>
				{Object.entries(adminLinkList).map(([linkName, linkDetails], index) => (
					<button
						key={index}
						className={s.headerDropdownBtn}
						onClick={() => {
							if (linkDetails.billing) {
								manageBilling();
							} else if (linkName === "Manage Account") {
								handleNavigate("#/admin-box/users", true);
								AdminPortal.show();
							} else {
								setShowSidebar(false);
								setActiveAdminLink(linkName);

								handleNavigate("/admin");
							}
							setIsDropdownOpen(false);
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter" || event.key === " ") {
								if (linkDetails.billing) {
									manageBilling();
								} else if (linkName === "Manage Account") {
									handleNavigate("#/admin-box/users", true);
									AdminPortal.show();
								} else {
									setShowSidebar(false);
									setActiveAdminLink(linkName);
									handleNavigate("/admin");
								}
								setIsDropdownOpen(false);
							}
						}}
						type="button"
					>
						{linkDetails.icon}
						{pageText[index]}
					</button>
				))}
			</div>
		</div>
	);
}

function AccountDropdownContent({ setAudioVideoModalOpen, setLanguageModalOpen }) {
	const navigate = useNavigate();
	const multiLanguageFlag = useFeatureFlagEnabled("multi-language-support");

	const { axiosLimitedPost } = useAxiosLimited();

	const { tenantId, localUser, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		AudioAndVideo: "Audio and Video",
		Language: "Language",
		Profile: "Profile",
		Logout: "Logout"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	return (
		<div
			onClick={(event) => event.stopPropagation()}
			onKeyDown={(event) => {
				if (event.key === "Enter" || event.key === " ") {
					event.stopPropagation();
				}
			}}
			role="button"
			tabIndex={0}
		>
			<div className={s.headerDropdownBtnList}>
				<button aria-label="Profile" type="button" className={s.headerDropdownBtn} onClick={() => AdminPortal.show()}>
					<FiEdit />
					{pageText.Profile}
				</button>
				<button aria-label="Logout" type="button" className={s.headerDropdownBtn} onClick={() => navigate("/account/logout")}>
					<FiLogOut />
					{pageText.Logout}
				</button>
				<button
					aria-label="Audio & Video"
					type="button"
					className={s.headerDropdownBtn}
					onClick={() => {
						setAudioVideoModalOpen(true);
					}}
				>
					<IoSettingsSharp />
					{pageText.AudioAndVideo}
				</button>
				{multiLanguageFlag && (
					<button
						aria-label="Language"
						type="button"
						className={s.headerDropdownBtn}
						onClick={() => {
							setLanguageModalOpen(true);
						}}
					>
						<GrLanguage />
						{pageText.Language}
					</button>
				)}
			</div>
		</div>
	);
}
