import { useRef, useCallback, useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";

import { useAxiosLimited } from "./axiosRetry.js";

import useStopwatch from "./useStopwatch.js";
import { useUploadManager } from "./useUploadManager.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export const useAudioRecorder = ({ mixerRef, audioContext, saveVideo, activeSessionDataRef }) => {
	const { registerUpload, progress } = useUploadManager();

	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const posthog = usePostHog();

	const { startStopWatch, stopStopWatch, pauseStopWatch, getElapsedTime, getElapsedTimeFromTimestamp, getRemainingTime } = useStopwatch(
		activeSessionDataRef?.current?.setup_output?.target_time
	);

	const [status, setStatus] = useState("idle"); // Status of the recorder
	const [videoStatus, setVideoStatus] = useState("idle"); // Status of the recorder
	const videoStatusRef = useRef({ active: false, paused: false });
	const audioStatusRef = useRef({ active: false, paused: false });
	const sampleRateRef = useRef(null);

	// if (!audioContextProp) {
	//	audioContextProp = new (window.AudioContext || window.webkitAudioContext)();
	// } else {
	//	audioContextProp = audioContextProp.current;
	// }

	// const audioContext = useRef(null);
	const audioBitsRef = useRef([]);
	const scriptNodeRef = useRef({
		paused: false,
		node: null,
		source: null,
		client_id: null,
		instage_id: null,
		session_id: null,
		startRecording: false,
		fileName: 0
	});
	const AUDIO_SLICE_LENGTH = 5;

	const videoUploadCountRef = useRef(0);
	const videoMediaRecorderRef = useRef(null);
	const videoChunksRef = useRef([]);
	const isVideoRecordingRef = useRef(false);

	const audioUploadCountRef = useRef(0);
	const audioMediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);
	const isAudioRecordingRef = useRef(false);

	const combinedAudioStreamRef = useRef(null);
	const combinedStreamRef = useRef(null);

	const soloStreamRef = useRef(null);

	// use effect
	// useEffect(() => {
	// 	console.log("useEffect audioContextProp audio recorder");
	// 	if (audioContextProp && audioContextProp) {
	// 		audioContext.current = audioContextProp;
	// 		// Initialize the mixer node
	// 		if (audioContext.current && !mixerRef.current) {
	// 			mixerRef.current = audioContext.current.createGain();
	// 			sampleRateRef.current = audioContext.current.sampleRate;
	// 			// mixerRef.current.connect(audioContext.current.destination);
	// 		}
	// 	}
	// }, [audioContextProp]);

	// Function to add a new audio source to the mixer
	const addAudioSourceToMixer = (audioSource) => {
		if (audioSource && mixerRef.current) {
			audioSource.connect(mixerRef.current);
			// Disconnect the source when it finishes playing
			// eslint-disable-next-line
			audioSource.onended = () => {
				audioSource.disconnect(mixerRef.current);
			};
		}
	};

	function calculateFullFileLength(audio_files) {
		const result = { length: 0, parts: -1 };
		if (audio_files && audio_files.length > 0) {
			// eslint-disable-next-line

			audio_files = audio_files.filter((file) => file.includes("/audio"));
			// console.log(audio_files)
			let lastIndex = -1;
			const sampleRates = audio_files.map((file) => {
				// console.log(file);
				const split0 = file.split("/");
				const shortName = split0[split0.length - 1];
				const split = shortName.split("_");
				const index = split[0];
				lastIndex = parseInt(index, 10);
				const rate = split[2].split(".")[0];
				// filledOutAudioFiles[index] = file
				return rate;
			});

			result.parts = lastIndex + 1;
			result.length = result.parts !== -1 ? result.parts * AUDIO_SLICE_LENGTH : 0;
		}

		return result;
	}

	const startRecording = useCallback((stream, activeSessionData) => {
		// console.log("startRecording",activeSessionData.audio_files)
		if (!audioContext.current) {
			console.error("startRecording Audio context not available");
			return;
		}

		const calculations = calculateFullFileLength(activeSessionData.audio_files);
		// console.log(calculations )
		let resumeTime = 0;
		if (calculations.parts > 0) {
			resumeTime = (calculations.length + AUDIO_SLICE_LENGTH) * 1000;
		}
		startStopWatch(resumeTime);

		if (!MediaRecorder.isTypeSupported("audio/webm; codecs=opus")) {
			console.error("Opus codec not supported");
		} else {
			console.log("Opus codec supported");
		}

		scriptNodeRef.current.instage_id = activeSessionData.instage_id;
		scriptNodeRef.current.session_id = activeSessionData.session_id;

		scriptNodeRef.current.client_id = activeSessionData.client_id;

		scriptNodeRef.current.paused = false;
		console.log(audioContext.current.sampleRate);
		sampleRateRef.current = audioContext.current.sampleRate;
		setStatus("recording");
		if (scriptNodeRef.current.startRecording === true) {
			return;
		}

		scriptNodeRef.current.fileName = calculations.parts + 1;
		const source = audioContext.current.createMediaStreamSource(stream);
		scriptNodeRef.current.source = source;
		if (mixerRef.current) {
			source.connect(mixerRef.current);
		}
		soloStreamRef.current = stream;
		// processStream(source);

		startRecordAudio(soloStreamRef.current);

		if (saveVideo.current) {
			startRecordVideo(soloStreamRef.current);
		}
	}, []);

	async function startRecordAudio(stream) {
		let combinedStream;
		let localStream = stream;
		if (!localStream) {
			localStream = soloStreamRef.current;
		}
		if (!combinedAudioStreamRef.current) {
			const mediaStreamDestination = audioContext.current.createMediaStreamDestination();
			mixerRef.current.connect(mediaStreamDestination);

			combinedAudioStreamRef.current = mediaStreamDestination.stream;
		}
		if (!combinedStream) {
			combinedStream = combinedAudioStreamRef.current;
		}

		const mediaRecorder = new MediaRecorder(combinedStream, { audioBitsPerSecond: 16 * 1000 });

		audioMediaRecorderRef.current = mediaRecorder;

		let sliceCount = 0;
		const slicesPerClip = 5; // Number of 1 second slices per 30-second clip

		audioStatusRef.current.active = true;

		mediaRecorder.ondataavailable = function (e) {
			audioChunksRef.current.push(e.data);
			sliceCount += 1;
			if (sliceCount >= slicesPerClip) {
				// Stop and restart the recording every 30 seconds
				sliceCount = 0;
				mediaRecorder.stop(); // This will trigger the onstop event
				// The recorder is restarted in the onstop event
			}
		};

		mediaRecorder.onstop = async function (e) {
			console.log("onstop", mediaRecorder.mimeType);
			const blob = new Blob(audioChunksRef.current, { type: mediaRecorder.mimeType });
			audioChunksRef.current = [];
			try {
				registerUpload(
					uploadToS3,
					[
						blob,
						`${sampleRateRef.current}`,
						scriptNodeRef.current.fileName,
						scriptNodeRef.current.instage_id,
						scriptNodeRef.current.session_id,
						scriptNodeRef.current.client_id
					],
					blob.size
				);
				scriptNodeRef.current.fileName += 1;
			} catch (error) {
				console.error(error);
				posthog?.capture("instage_video_chunk_fail", {
					instage_id: scriptNodeRef.current.instage_id,
					session_id: scriptNodeRef.current.session_id,
					client_id: scriptNodeRef.current.client_id
				});
			}

			//  const url = URL.createObjectURL(blob);
			//     const link = document.createElement('a');
			//     link.href = url;
			//     link.download = 'recording5.webm';
			//     link.click();
			audioUploadCountRef.current += 1;
			if (isAudioRecordingRef.current) {
				// Start a new recording if still recording
				audioMediaRecorderRef.current.start(1000); // Start new recording for next 30 seconds
			}
		};
		mediaRecorder.start(1000); // Start recording with a 1 second time slice
		isAudioRecordingRef.current = true;

		// await new Promise(r => setTimeout(r, 30000)); // Record for 10 seconds
		// mediaRecorder.stop();
	}
	async function startRecordVideo(stream) {
		let combinedStream;
		let localStream = stream;
		if (!localStream) {
			localStream = soloStreamRef.current;
		}
		if (!combinedStreamRef.current) {
			const mediaStreamDestination = audioContext.current.createMediaStreamDestination();
			mixerRef.current.connect(mediaStreamDestination);
			const mixedAudioStream = mediaStreamDestination.stream;

			const audioTrack = mixedAudioStream.getAudioTracks()[0];
			combinedStream = new MediaStream([...localStream.getVideoTracks(), audioTrack]);
			combinedStreamRef.current = combinedStream;
		}
		if (!combinedStream) {
			combinedStream = combinedStreamRef.current;
		}

		const mediaRecorder = new MediaRecorder(combinedStream, { videoBitsPerSecond: 500 * 1000 });

		videoMediaRecorderRef.current = mediaRecorder;

		let sliceCount = 0;
		const slicesPerClip = 5; // Number of 1 second slices per 30-second clip
		setVideoStatus("recording");
		videoStatusRef.current.active = true;
		mediaRecorder.ondataavailable = function (e) {
			videoChunksRef.current.push(e.data);
			sliceCount += 1;
			if (sliceCount >= slicesPerClip) {
				// Stop and restart the recording every 30 seconds
				sliceCount = 0;
				mediaRecorder.stop(); // This will trigger the onstop event
				// The recorder is restarted in the onstop event
			}
		};

		mediaRecorder.onstop = async function (e) {
			const blob = new Blob(videoChunksRef.current, { type: "video/webm" });
			videoChunksRef.current = [];
			try {
				registerUpload(
					uploadVideoToS3,
					[
						blob,
						videoUploadCountRef.current,
						scriptNodeRef.current.instage_id,
						scriptNodeRef.current.session_id,
						scriptNodeRef.current.client_id
					],
					blob.size
				);
			} catch (error) {
				console.error(error);
				posthog?.capture("instage_video_chunk_fail", {
					instage_id: scriptNodeRef.current.instage_id,
					session_id: scriptNodeRef.current.session_id,
					client_id: scriptNodeRef.current.client_id
				});
			}

			//  const url = URL.createObjectURL(blob);
			//     const link = document.createElement('a');
			//     link.href = url;
			//     link.download = 'recording5.webm';
			//     link.click();
			videoUploadCountRef.current += 1;
			if (isVideoRecordingRef.current) {
				// Start a new recording if still recording
				videoMediaRecorderRef.current.start(1000); // Start new recording for next 30 seconds
			}
		};
		mediaRecorder.start(1000); // Start recording with a 1 second time slice
		isVideoRecordingRef.current = true;

		// await new Promise(r => setTimeout(r, 30000)); // Record for 10 seconds
		// mediaRecorder.stop();
	}
	const stopRecordAudio = () => {
		console.log("stopRecordAudio");
		if (audioMediaRecorderRef.current && isAudioRecordingRef.current) {
			isAudioRecordingRef.current = false;
			audioMediaRecorderRef.current.stop();
		}
		audioStatusRef.current.active = false;
	};
	const stopRecordVideo = () => {
		console.log("stopRecordVideo");
		if (videoMediaRecorderRef.current && isVideoRecordingRef.current) {
			isVideoRecordingRef.current = false;
			videoMediaRecorderRef.current.stop();
		}
		setVideoStatus("off");
		videoStatusRef.current.active = false;
	};

	const finalizeRecording = () => {
		console.log("finalizeRecording");

		stopRecordVideo();
		stopRecordAudio();
		// Any additional cleanup can be added here
	};

	const pauseRecording = useCallback(() => {
		console.log("pauseRecording");

		pauseStopWatch();
		scriptNodeRef.current.paused = true;
		videoStatusRef.current.paused = true;
		audioStatusRef.current.paused = true;

		stopRecordVideo();
		stopRecordAudio();
		setStatus("paused");
		setVideoStatus("paused");
		videoStatusRef.current.active = true;
		audioStatusRef.current.active = true;
	}, []);

	const resumeRecording = useCallback(() => {
		startStopWatch();
		scriptNodeRef.current.paused = false;
		videoStatusRef.current.paused = false;
		audioStatusRef.current.paused = false;
		setStatus("recording");
		setVideoStatus("recording");

		console.log("resumeRecording", videoStatusRef.current.active);
		if (videoStatusRef.current.active === true) {
			startRecordVideo(combinedStreamRef.current);
		}

		if (audioStatusRef.current.active === true) {
			startRecordAudio(combinedAudioStreamRef.current);
		}
	}, []);

	const stopRecording = useCallback(() => {
		console.log("stopRecording");

		stopStopWatch();
		finalizeRecording();
		return new Promise((resolve, reject) => {
			setStatus("stopped");
			if (scriptNodeRef.current) {
				if (scriptNodeRef.current.node) {
					scriptNodeRef.current.node.port.onmessage = undefined;
					scriptNodeRef.current.node.disconnect();
					scriptNodeRef.current.node = undefined;
				}
				if (scriptNodeRef.current.source) {
					scriptNodeRef.current.source.disconnect();
					scriptNodeRef.current.source = undefined;
				}
			}
			scriptNodeRef.current.startRecording = false;
			resolve();
		});
	}, []);

	async function processStream(source) {
		console.log("processStream", audioContext.state);
		try {
			const workletUrl = `${url}/my-worklet-processor.js`;

			await audioContext.current.audioWorklet.addModule(workletUrl);
		} catch (err) {
			console.error("Error adding audio worklet module:", err);
		}

		// Create a source from the incoming stream
		// const source = audioContext.current.createMediaStreamSource(stream);
		// const mediaStreamDestination = audioContext.current.createMediaStreamDestination();

		// const source2 = audioContext.current.createMediaStreamSource(mediaStreamDestination.stream);

		// Create a new worklet node
		const myWorkletNode = new AudioWorkletNode(audioContext.current, "my-worklet-processor");

		// Reset scriptNodeRef.current for new recording

		scriptNodeRef.current.node = myWorkletNode;

		// Connect the source to the mixer
		if (mixerRef.current) {
			// source.connect(mixerRef.current);
			// source2.connect(mixerRef.current);
			// TODO ADD POSTHOG EVENT
			// Connect the mixer to the worklet node
			mixerRef.current.connect(myWorkletNode);
		} else {
			console.error("Mixer not available");
			// If mixer is not available, connect source directly to the worklet node
			source.connect(myWorkletNode);
			// TODO ADD POSTHOG EVENT
			// source2.connect(myWorkletNode);
		}

		// Connect the worklet node to the AudioContext's destination
		// myWorkletNode.connect(audioContext.current.destination);

		myWorkletNode.port.onmessage = (event) => {
			if (scriptNodeRef.current.paused === true) {
				return;
			}
			const channelData = event.data;
			// Now, channelData is a Float32Array containing the PCM data
			// You can process, store, or send this data as needed
			if (channelData) {
				audioBitsRef.current.push(...channelData);
				const { sampleRate } = audioContext.current;
				const seconds = AUDIO_SLICE_LENGTH;

				const sampleCount = sampleRate * seconds;
				// console.log(audioBitsRef.current.length,sampleCount)
				if (audioBitsRef.current.length > sampleCount) {
					const firstHalf = audioBitsRef.current.slice(0, sampleCount);
					const secondHalf = audioBitsRef.current.slice(sampleCount);

					const samples = new Float32Array(firstHalf.length);
					audioBitsRef.current = secondHalf;
					const offset = 0;
					samples.set(firstHalf, offset);

					if (scriptNodeRef.current.instage_id !== undefined) {
						const blob = new Blob([samples.buffer], { type: "application/octet-stream" });
						try {
							registerUpload(
								uploadToS3,
								[
									blob,
									sampleRateRef.current,
									scriptNodeRef.current.fileName,
									scriptNodeRef.current.instage_id,
									scriptNodeRef.current.session_id,
									scriptNodeRef.current.client_id
								],
								blob.size
							);
						} catch (e) {
							console.error(e);
							posthog?.capture("instage_audio_fail", {
								message: e.message,
								instage_id: scriptNodeRef.current.instage_id,
								session_id: scriptNodeRef.current.session_id,
								client_id: scriptNodeRef.current.client_id
							});
						}
					} else {
						// const wav = encodeWAV(samples, audioContext.current);
						// const blob = new Blob([wav], { type: "audio/wav" });
						// downloadBlob(blob, `audio${scriptNodeRef.current.fileName}.wav`);
					}

					scriptNodeRef.current.fileName += 1;
					audioBitsRef.current = secondHalf;
				}
			}
		};
	}

	function downloadBlob(blob, fileName = "recorded_audio.mp3") {
		const audioUrl = URL.createObjectURL(blob);
		const downloadLink = document.createElement("a");
		downloadLink.href = audioUrl;
		downloadLink.download = fileName;
		downloadLink.click();
		// Revoke the URL after the download
		URL.revokeObjectURL(audioUrl);
	}

	const lastUploadTimeRef = useRef(Date.now());

	useEffect(() => {
		if (status !== "recording") {
			return () => {};
		}
		const intervalId = setInterval(() => {
			const currentTime = Date.now();
			const timeSinceLastUpload = (currentTime - lastUploadTimeRef.current) / 1000;
			if (timeSinceLastUpload > 6) {
				// It has been longer than 6 seconds since the last upload
				console.error("More than 6 seconds have passed since the last upload.");
				// You can add any action you want to be triggered here
			}
		}, 1000); // Check every second

		return () => clearInterval(intervalId); // Clear interval on component unmount
	}, [status]);
	/**
	 *
	 * @param {*} blob
	 * @param {*} fileNameVar a suffix to the filename
	 * @param {*} file number
	 * @param {*} instage_id
	 * @param {*} session_id
	 * @returns filename
	 */
	async function uploadToS3(wavBlob, fileNameVar, transcriptId, instage_id, session_id, client_id) {
		// if (!saveAudio.current)
		// {
		//   return;
		// }
		lastUploadTimeRef.current = Date.now();
		console.log("uploadToS3", wavBlob, wavBlob.type);

		const mimeType = wavBlob.type ? wavBlob.type.split(";")[0] : "application/octet-stream";
		// const wavArray = new Uint8Array(wavBuffer);
		// console.log(wavArray)
		const CHUNK_SIZE = 768 * 1024; // Size of chunks in bytes
		const totalChunks = Math.ceil(wavBlob.size / CHUNK_SIZE);

		const responseCount = transcriptId;

		try {
			const initResponse = await axiosLimitedPost(
				`${url}/api/upload-init`,
				{
					client_id,
					instage_id,
					session_id,
					totalChunks,
					responseCount,
					fileNameVar,
					type: mimeType
				},
				1
			);

			//  console.log("totalChunks", totalChunks)
		} catch (error) {
			console.error(`/api/upload-init${error}`);
			posthog?.capture("instage_audio_fail_init", {
				message: error.message,
				instage_id: scriptNodeRef.current.instage_id,
				session_id: scriptNodeRef.current.session_id,
				client_id: scriptNodeRef.current.client_id
			});
			throw `/api/upload-init${error}`;
		}
		// Initialize upload
		// Upload chunks
		for (let i = 0; i < totalChunks; i++) {
			const start = i * CHUNK_SIZE;
			const end = Math.min(wavBlob.size, start + CHUNK_SIZE);
			// const chunkArray = wavArray.slice(start, end);
			const chunkBlob = wavBlob.slice(start, end);

			// const chunkBlob = new Blob([chunkArray], { type: 'application/octet-stream' });
			const formData = new FormData();
			formData.append("audio", chunkBlob, "audio.webm");
			formData.append("instage_id", instage_id);
			formData.append("session_id", session_id);
			formData.append("responseCount", responseCount);
			formData.append("type", mimeType);

			formData.append("client_id", client_id);

			formData.append("fileNameVar", sampleRateRef.current);
			formData.append("index", i);
			// console.log("/api/upload-chunk")
			await axiosLimitedPost(`${url}/api/upload-chunk`, formData, 3, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
		}

		// Finalize upload
		const finalizeData = {
			instage_id,
			session_id,
			client_id,
			responseCount,
			fileNameVar: sampleRateRef.current,
			type: mimeType
		};

		return new Promise((resolve, reject) => {
			// console.log("/api/upload-finalize")
			axiosLimitedPost(`${url}/api/upload-finalize`, finalizeData, 1)
				.then((data) => {
					resolve(data.data.Key);
				})
				.catch((error) => {
					posthog?.capture("instage_audio_fail_finalize", {
						message: error.message,
						instage_id: scriptNodeRef.current.instage_id,
						session_id: scriptNodeRef.current.session_id,
						client_id: scriptNodeRef.current.client_id
					});
					throw `/api/upload-finalize"${error}`;
					reject(error);
				});
		});
	}
	async function uploadVideoToS3(videoBlob, transcriptId, instage_id, session_id, client_id) {
		const CHUNK_SIZE = 768 * 1024; // Size of chunks in bytes
		const totalChunks = Math.ceil(videoBlob.size / CHUNK_SIZE);

		const responseCount = transcriptId;
		const mimeType = videoBlob.type ? videoBlob.type.split(";")[0] : "application/octet-stream";

		try {
			const initResponse = await axiosLimitedPost(
				`${url}/api/upload-init`,
				{
					client_id,
					instage_id,
					session_id,
					totalChunks,
					responseCount,
					fileNameVar: sampleRateRef.current,
					type: mimeType
				},
				1
			);

			//  console.log("totalChunks", totalChunks)
		} catch (error) {
			console.error(`/api/upload-init${error}`);
			throw `/api/upload-init${error}`;
		}
		// Initialize upload
		// Upload chunks
		for (let i = 0; i < totalChunks; i++) {
			const start = i * CHUNK_SIZE;
			const end = Math.min(videoBlob.size, start + CHUNK_SIZE);
			// const chunkArray = wavArray.slice(start, end);
			const chunkBlob = videoBlob.slice(start, end);

			// const chunkBlob = new Blob([chunkArray], { type: 'application/octet-stream' });
			const formData = new FormData();
			formData.append("audio", chunkBlob, "video.webm");
			formData.append("instage_id", instage_id);
			formData.append("session_id", session_id);
			formData.append("client_id", client_id);

			formData.append("responseCount", responseCount);
			formData.append("type", mimeType);

			// formData.append("fileNameVar", sampleRateRef.current);
			formData.append("index", i);
			// console.log("/api/upload-chunk")
			const startTime = new Date();
			await axiosLimitedPost(`${url}/api/upload-chunk`, formData, 3, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			const endTime = new Date();
			const uploadTime = (endTime - startTime) / 1000;
			// posthog?.capture("instage_video_chunk_upload", {
			// 	instage_id,
			// 	session_id,
			// 	client_id,
			// 	uploadTime,
			// 	responseCount,
			// 	index: i
			// });
			// console.error("upload time", responseCount, i, uploadTime);
		}

		// Finalize upload
		const finalizeData = {
			client_id,
			instage_id,
			session_id,
			responseCount,
			// fileNameVar:sampleRateRef.current,
			type: mimeType
		};

		return new Promise((resolve, reject) => {
			// console.log("/api/upload-finalize")
			axiosLimitedPost(`${url}/api/upload-finalize`, finalizeData, 1)
				.then((data) => {
					resolve(data.data.Key);
				})
				.catch((error) => {
					throw `/api/upload-finalize"${error}`;

					reject(error);
				});
		});
	}

	useEffect(
		() => () => {
			try {
				stopRecording();
			} catch (e) {
				console.error(e);
			}
			try {
				stopRecordVideo();
			} catch (e) {
				console.error(e);
			}
			try {
				stopRecordAudio();
			} catch (e) {
				console.error(e);
			}
		},
		[]
	);
	return {
		startRecording,
		stopRecording,
		pauseRecording,
		resumeRecording,
		stopRecordVideo,
		startRecordVideo,
		addAudioSourceToMixer,
		getElapsedTime,
		getElapsedTimeFromTimestamp,
		getRemainingTime,
		status,
		videoStatus,
		progress
	};
};

function encodeWAV(samples, audioContext) {
	if (!audioContext) {
		audioContext = new (window.AudioContext || window.webkitAudioContext)();
	}

	const buffer = new ArrayBuffer(44 + samples.length * 2);
	const view = new DataView(buffer);

	writeUTFBytes(view, 0, "RIFF");
	view.setUint32(4, 32 + samples.length * 2, true);
	writeUTFBytes(view, 8, "WAVE");
	writeUTFBytes(view, 12, "fmt ");
	view.setUint32(16, 16, true);
	view.setUint16(20, 1, true); // PCM format.
	view.setUint16(22, 1, true); // Mono.
	view.setUint32(24, audioContext.sampleRate, true);
	view.setUint32(28, audioContext.sampleRate * 2, true);
	view.setUint16(32, 2, true);
	view.setUint16(34, 16, true);
	writeUTFBytes(view, 36, "data");
	view.setUint32(40, samples.length * 2, true);
	floatTo16BitPCM(view, 44, samples);

	return view;
}

function writeUTFBytes(view, offset, string) {
	for (let i = 0; i < string.length; i++) {
		view.setUint8(offset + i, string.charCodeAt(i));
	}
}

function floatTo16BitPCM(output, offset, input) {
	for (let i = 0; i < input.length; i++, offset += 2) {
		const s = Math.max(-1, Math.min(1, input[i]));
		output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
	}
}
