import React, { useState, useEffect, useCallback } from "react";
import { jsPDF } from "jspdf";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
import styles from "../../styleModules/resumeCreator.module.css";
import mammoth from "mammoth";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import TemplateSelector from "./TemplateSelector";
import { renderAsync } from "docx-preview";
import JSZip from "jszip";
import { PiSparkleBold } from "react-icons/pi";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { FaRegTrashAlt } from "react-icons/fa";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

// eslint-disable-next-line
const ResumeCreator = () => {
	const posthog = usePostHog();
	const [activeSection, setActiveSection] = useState("contact");
	const resumeCreatorFlag = useFeatureFlagEnabled("resume-creator");
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [resumeCreatorRewriteSections, setResumeCreatorRewriteSections] = useState({
		summary: "",
		experience: {},
		extraCurricular: {}
	});
	const { axiosLimitedPost } = useAxiosLimited();
	const [resumeData, setResumeData] = useState({
		contact: {
			firstName: "",
			lastName: "",
			email: "",
			address: "",
			phone: "",
			linkedinURL: ""
		},
		summary: "",
		experience: [
			{
				id: Date.now(),
				jobTitle: "",
				companyName: "",
				city: "",
				stateOrProv: "",
				startMonth: "",
				startYear: "",
				endMonth: "",
				endYear: "",
				roles: []
			}
		],
		education: [
			{
				id: Date.now(),
				school: "",
				degree: "",
				program: "",
				graduatingYear: ""
			}
		],
		extraCurricular: [
			{
				id: Date.now(),
				activity: "",
				orgName: "",
				startMonth: "",
				startYear: "",
				endMonth: "",
				endYear: "",
				tasks: []
			}
		],
		skills: {
			technical: [],
			coreCompetencies: []
		}
	});
	const [isPreviewFullScreen, setIsPreviewFullScreen] = useState(false);
	const [templateContent, setTemplateContent] = useState(null);
	const [error, setError] = useState(null);

	const sections = ["contact", "summary", "experience", "education", "extraCurricular", "skills"];

	const debounce = (func, delay) => {
		let timeoutId;
		return (...args) => {
			if (timeoutId) clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	const generatePreview = useCallback(
		debounce(async () => {
			if (!templateContent) return;
			if (!resumeCreatorFlag) return;

			try {
				const zip = new JSZip(templateContent);
				const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

				const data = {
					firstName: resumeData.contact.firstName,
					lastName: resumeData.contact.lastName,
					email: resumeData.contact.email,
					phone: resumeData.contact.phone,
					address: resumeData.contact.address,
					linkedinURL: resumeData.contact.linkedinURL,
					summary: resumeData.summary,
					technical1: resumeData.skills.technical.join(", "),
					Competencies1: resumeData.skills.coreCompetencies.join(", "),
					educations: resumeData.education.map((edu) => ({
						school: edu.school,
						degree: edu.degree,
						program: edu.program,
						graduatingYear: edu.graduatingYear
					})),
					experiences: resumeData.experience.map((exp) => ({
						JobTitle: exp.jobTitle,
						companyName: exp.companyName,
						city: exp.city,
						stateOrProv: exp.stateOrProv,
						startMonth: exp.startMonth,
						startYear: exp.startYear,
						endMonth: exp.endMonth,
						endYear: exp.endYear,
						roles: exp.roles
					})),
					extraCurriculars: resumeData.extraCurricular.map((activity) => ({
						activity: activity.activity,
						OrgName: activity.orgName,
						startMonth: activity.startMonth,
						startYear: activity.startYear,
						endMonth: activity.endMonth,
						endYear: activity.endYear,
						tasks: activity.tasks
					}))
				};

				doc.setData(data);
				doc.render();

				const blob = doc.getZip().generate({
					type: "blob",
					mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				});

				const arrayBuffer = await blob.arrayBuffer();

				// Get the preview container
				const previewContainer = document.getElementById("docxPreview");

				// Clear the previous content
				previewContainer.innerHTML = "";

				// Render the DOCX file
				await renderAsync(arrayBuffer, previewContainer, previewContainer, {
					className: "docx-preview",
					inWrapper: false,
					ignoreWidth: true,
					ignoreHeight: true,
					renderHeaders: false,
					renderFooters: false,
					useBase64URL: true
				});
			} catch (err) {
				console.error("Error generating preview:", err);
			}
		}, 500),
		[templateContent, resumeCreatorFlag, resumeData]
	);

	const rewriteSection = (section, sectionInput, index = null, subIndex = null) => {
		const sendToPromptLayer = async () => {
			const data = {
				sectionTitle: section,
				sectionData: sectionInput
			};

			try {
				const resp = await axiosLimitedPost(`${url}/api/resume-assist/resumeCreatorRewriteSection`, data, 1, {}, 30000);
				setResumeCreatorRewriteSections((prevSections) => {
					if (section === "experience" || section === "extraCurricular") {
						return {
							...prevSections,
							[section]: {
								...prevSections[section],
								[index]: {
									...prevSections[section][index],
									[subIndex]: resp.data
								}
							}
						};
					}
					return {
						...prevSections,
						[section]: resp.data
					};
				});
			} catch (err) {
				console.log(err);
			}
		};

		return sendToPromptLayer();
	};

	const handleInputChange = (section, field, value, index = 0, subIndex = null) => {
		setResumeData((prevData) => {
			if (section === "summary") {
				return { ...prevData, summary: value };
			}
			if (Array.isArray(prevData[section])) {
				const newArray = [...prevData[section]];
				if (subIndex !== null) {
					newArray[index] = {
						...newArray[index],
						[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
					};
				} else {
					newArray[index] = { ...newArray[index], [field]: value };
				}
				return { ...prevData, [section]: newArray };
			} else if (section === "skills") {
				return {
					...prevData,
					skills: {
						...prevData.skills,
						[field]: prevData.skills[field].map((item, i) => (i === index ? value : item))
					}
				};
			} else if (typeof prevData[section] === "object") {
				return { ...prevData, [section]: { ...prevData[section], [field]: value } };
			}
			return prevData;
		});
		generatePreview();
	};

	const addItem = (section) => {
		setResumeData((prevData) => ({
			...prevData,
			[section]: [
				...prevData[section],
				section === "experience"
					? {
							id: Date.now(),
							jobTitle: "",
							companyName: "",
							city: "",
							stateOrProv: "",
							startMonth: "",
							startYear: "",
							endMonth: "",
							endYear: "",
							roles: []
					  }
					: section === "education"
					? {
							id: Date.now(),
							school: "",
							degree: "",
							program: "",
							graduatingYear: ""
					  }
					: section === "extraCurricular"
					? {
							id: Date.now(),
							activity: "",
							orgName: "",
							startMonth: "",
							startYear: "",
							endMonth: "",
							endYear: "",
							tasks: []
					  }
					: ""
			]
		}));
	};

	const addRole = (experienceIndex) => {
		setResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles.push("");
			return { ...prevData, experience: newExperience };
		});
	};

	const removeRole = (experienceIndex, roleIndex) => {
		setResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles = newExperience[experienceIndex].roles.filter((_, index) => index !== roleIndex);
			return { ...prevData, experience: newExperience };
		});
	};

	const removeSkill = (skillType, skillIndex) => {
		setResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: prevData.skills[skillType].filter((_, index) => index !== skillIndex)
			}
		}));
	};

	const removeTask = (activityIndex, taskIndex) => {
		setResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			newExtraCurricular[activityIndex].tasks = newExtraCurricular[activityIndex].tasks.filter((_, index) => index !== taskIndex);
			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const removeItem = (section, index) => {
		setResumeData((prevData) => ({
			...prevData,
			[section]: prevData[section].filter((_, i) => i !== index)
		}));
	};

	const renderContactForm = () => (
		<div className={styles.formGroup}>
			<input
				className={styles.input}
				placeholder="First Name"
				value={resumeData.contact.firstName}
				onChange={(e) => handleInputChange("contact", "firstName", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Last Name"
				value={resumeData.contact.lastName}
				onChange={(e) => handleInputChange("contact", "lastName", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Email Address"
				type="email"
				value={resumeData.contact.email}
				onChange={(e) => handleInputChange("contact", "email", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Address"
				value={resumeData.contact.address}
				onChange={(e) => handleInputChange("contact", "address", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="Phone Number"
				type="tel"
				value={resumeData.contact.phone}
				onChange={(e) => handleInputChange("contact", "phone", e.target.value)}
			/>
			<input
				className={styles.input}
				placeholder="LinkedIn URL"
				value={resumeData.contact.linkedinURL}
				onChange={(e) => handleInputChange("contact", "linkedinURL", e.target.value)}
			/>
		</div>
	);

	const renderExperienceForm = () => (
		<div>
			{resumeData.experience.map((exp, index) => (
				<div key={exp.id || index} className={styles.experienceItem}>
					<input
						className={styles.input}
						placeholder="Job Title"
						value={exp.jobTitle || ""}
						onChange={(e) => handleInputChange("experience", "jobTitle", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Company Name"
						value={exp.companyName || ""}
						onChange={(e) => handleInputChange("experience", "companyName", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="City"
						value={exp.city || ""}
						onChange={(e) => handleInputChange("experience", "city", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="State/Province"
						value={exp.stateOrProv || ""}
						onChange={(e) => handleInputChange("experience", "stateOrProv", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Month"
						value={exp.startMonth || ""}
						onChange={(e) => handleInputChange("experience", "startMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Year"
						value={exp.startYear || ""}
						onChange={(e) => handleInputChange("experience", "startYear", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Month"
						value={exp.endMonth || ""}
						onChange={(e) => handleInputChange("experience", "endMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Year"
						value={exp.endYear || ""}
						onChange={(e) => handleInputChange("experience", "endYear", e.target.value, index)}
					/>
					<div className={styles.indentedField}>
						{(exp.roles || []).map((role, roleIndex) => (
							<div key={`role-${exp.id}-${roleIndex}`}>
								<div className={styles.roleItem}>
									<input
										className={styles.input}
										placeholder={`Role ${roleIndex + 1}`}
										value={role}
										onChange={(e) => handleInputChange("experience", "roles", e.target.value, index, roleIndex)}
									/>
									<div className={styles.buttonGroup}>
										<button
											type="button"
											className={styles.removeButtonSmall}
											onClick={() => removeRole(index, roleIndex)}
											title="Remove Role"
										>
											<FaRegTrashAlt />
										</button>
										<button
											type="button"
											className={styles.rewriteButtonSmall}
											onClick={() => rewriteSection("experience", role, index, roleIndex)}
											title="AI-Rewrite"
										>
											<PiSparkleBold />
										</button>
									</div>
								</div>
								{resumeCreatorRewriteSections.experience?.[index]?.[roleIndex] && (
									<textarea
										className={styles.rewriteTextSmall}
										value={resumeCreatorRewriteSections.experience[index][roleIndex]}
										onChange={(e) =>
											setResumeCreatorRewriteSections((prev) => ({
												...prev,
												experience: {
													...prev.experience,
													[index]: {
														...prev.experience[index],
														[roleIndex]: e.target.value
													}
												}
											}))
										}
									/>
								)}
							</div>
						))}
						<button type="button" className={styles.button} onClick={() => addRole(index)}>
							Add Role
						</button>
					</div>
					<button type="button" className={styles.removeButton} onClick={() => removeItem("experience", index)}>
						Remove Experience
					</button>
					<hr />
				</div>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("experience")}>
				Add Experience
			</button>
		</div>
	);

	const renderSkillsForm = () => (
		<div>
			<h3>Technical Skills</h3>
			<div className={styles.indentedField}>
				{resumeData.skills.technical.map((skill, index) => (
					<div key={`technical-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Technical Skill ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "technical", e.target.value, index)}
						/>
						<button type="button" className={styles.removeButton} onClick={() => removeSkill("technical", index)}>
							Remove
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("technical")}>
					Add Technical Skill
				</button>
			</div>
			<h3>Core Competencies</h3>
			<div className={styles.indentedField}>
				{resumeData.skills.coreCompetencies.map((skill, index) => (
					<div key={`competency-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Core Competency ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "coreCompetencies", e.target.value, index)}
						/>
						<button type="button" className={styles.removeButton} onClick={() => removeSkill("coreCompetencies", index)}>
							Remove
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("coreCompetencies")}>
					Add Core Competency
				</button>
			</div>
		</div>
	);

	const addSkill = (skillType) => {
		setResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: [...prevData.skills[skillType], ""]
			}
		}));
	};

	const renderEducationForm = () => (
		<div>
			{resumeData.education.map((edu, index) => (
				<div key={edu.id || index} className={styles.educationItem}>
					<input
						className={styles.input}
						placeholder="School"
						value={edu.school || ""}
						onChange={(e) => handleInputChange("education", "school", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Degree"
						value={edu.degree || ""}
						onChange={(e) => handleInputChange("education", "degree", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Program"
						value={edu.program || ""}
						onChange={(e) => handleInputChange("education", "program", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Graduating Year"
						value={edu.graduatingYear || ""}
						onChange={(e) => handleInputChange("education", "graduatingYear", e.target.value, index)}
					/>
					<button type="button" className={styles.removeButton} onClick={() => removeItem("education", index)}>
						Remove Education
					</button>
					<hr />
				</div>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("education")}>
				Add Education
			</button>
		</div>
	);

	const renderExtraCurricularForm = () => (
		<div>
			{resumeData.extraCurricular.map((activity, index) => (
				<div key={activity.id} className={styles.extraCurricularItem}>
					<input
						className={styles.input}
						placeholder="Activity"
						value={activity.activity}
						onChange={(e) => handleInputChange("extraCurricular", "activity", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Organization Name"
						value={activity.orgName}
						onChange={(e) => handleInputChange("extraCurricular", "orgName", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Month"
						value={activity.startMonth}
						onChange={(e) => handleInputChange("extraCurricular", "startMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="Start Year"
						value={activity.startYear}
						onChange={(e) => handleInputChange("extraCurricular", "startYear", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Month"
						value={activity.endMonth}
						onChange={(e) => handleInputChange("extraCurricular", "endMonth", e.target.value, index)}
					/>
					<input
						className={styles.input}
						placeholder="End Year"
						value={activity.endYear}
						onChange={(e) => handleInputChange("extraCurricular", "endYear", e.target.value, index)}
					/>
					<div className={styles.indentedField}>
						{activity.tasks.map((task, taskIndex) => (
							<div key={`task-${activity.id}-${taskIndex}`}>
								<div className={styles.taskItem}>
									<input
										className={styles.input}
										placeholder={`Task ${taskIndex + 1}`}
										value={task}
										onChange={(e) => handleInputChange("extraCurricular", "tasks", e.target.value, index, taskIndex)}
									/>
									<div className={styles.buttonGroup}>
										<button
											type="button"
											className={styles.removeButtonSmall}
											onClick={() => removeTask(index, taskIndex)}
											title="Remove Task"
										>
											<FaRegTrashAlt />
										</button>
										<button
											type="button"
											className={styles.rewriteButtonSmall}
											onClick={() => rewriteSection("extraCurricular", task, index, taskIndex)}
											title="AI-Rewrite"
										>
											<PiSparkleBold />
										</button>
									</div>
								</div>
								{resumeCreatorRewriteSections.extraCurricular?.[index]?.[taskIndex] && (
									<textarea
										className={styles.rewriteTextSmall}
										value={resumeCreatorRewriteSections.extraCurricular[index][taskIndex]}
										onChange={(e) =>
											setResumeCreatorRewriteSections((prev) => ({
												...prev,
												extraCurricular: {
													...prev.extraCurricular,
													[index]: {
														...prev.extraCurricular[index],
														[taskIndex]: e.target.value
													}
												}
											}))
										}
									/>
								)}
							</div>
						))}
						<button type="button" className={styles.button} onClick={() => addTask(index)}>
							Add Task
						</button>
					</div>
					<button type="button" className={styles.removeButton} onClick={() => removeItem("extraCurricular", index)}>
						Remove Extra-Curricular
					</button>
					<hr />
				</div>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("extraCurricular")}>
				Add Extra-Curricular Activity
			</button>
		</div>
	);

	const addTask = (activityIndex) => {
		setResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			newExtraCurricular[activityIndex] = {
				...newExtraCurricular[activityIndex],
				tasks: [...newExtraCurricular[activityIndex].tasks, ""]
			};
			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const downloadDocx = async () => {
		if (!templateContent) return;

		try {
			posthog?.capture("instage_download_resume");
		} catch (event_error) {
			console.error(event_error);
		}

		try {
			const zip = new PizZip(templateContent);
			console.log("templateContent", templateContent);
			const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

			const data = {
				firstName: resumeData.contact.firstName,
				lastName: resumeData.contact.lastName,
				email: resumeData.contact.email,
				phone: resumeData.contact.phone,
				address: resumeData.contact.address,
				linkedinURL: resumeData.contact.linkedinURL,
				summary: resumeData.summary,
				technical1: resumeData.skills.technical.join(", "),
				Competencies1: resumeData.skills.coreCompetencies.join(", "),
				educations: resumeData.education.map((edu) => ({
					school: edu.school,
					degree: edu.degree,
					program: edu.program,
					graduatingYear: edu.graduatingYear
				})),
				experiences: resumeData.experience.map((exp) => ({
					JobTitle: exp.jobTitle,
					companyName: exp.companyName,
					city: exp.city,
					stateOrProv: exp.stateOrProv,
					startMonth: exp.startMonth,
					startYear: exp.startYear,
					endMonth: exp.endMonth,
					endYear: exp.endYear,
					roles: exp.roles
				})),
				extraCurriculars: resumeData.extraCurricular.map((activity) => ({
					activity: activity.activity,
					OrgName: activity.orgName,
					startMonth: activity.startMonth,
					startYear: activity.startYear,
					endMonth: activity.endMonth,
					endYear: activity.endYear,
					tasks: activity.tasks
				}))
			};

			doc.setData(data);

			doc.render();

			const blob = doc.getZip().generate({
				type: "blob",
				mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			});

			saveAs(blob, "resume.docx");
		} catch (err) {
			console.error("Error generating DOCX:", err);
		}
	};

	const isSectionFilled = (section) => {
		switch (section) {
			case "contact":
				return Object.values(resumeData.contact).every((value) => value.trim() !== "");
			case "summary":
				return resumeData.summary.trim() !== "";
			case "experience":
				return resumeData.experience.every(
					(exp) =>
						exp.jobTitle.trim() !== "" &&
						exp.companyName.trim() !== "" &&
						exp.city.trim() !== "" &&
						exp.stateOrProv.trim() !== "" &&
						exp.startMonth.trim() !== "" &&
						exp.startYear.trim() !== "" &&
						exp.endMonth.trim() !== "" &&
						exp.endYear.trim() !== "" &&
						exp.roles.every((role) => role.trim() !== "")
				);
			case "skills":
				return (
					resumeData.skills.technical.every((skill) => skill.trim() !== "") &&
					resumeData.skills.coreCompetencies.every((skill) => skill.trim() !== "")
				);
			case "education":
				return resumeData.education.every(
					(edu) => edu.school.trim() !== "" && edu.degree.trim() !== "" && edu.program.trim() !== "" && edu.graduatingYear.trim() !== ""
				);
			case "extraCurricular":
				return resumeData.extraCurricular.every(
					(activity) =>
						activity.activity.trim() !== "" &&
						activity.orgName.trim() !== "" &&
						activity.startMonth.trim() !== "" &&
						activity.startYear.trim() !== "" &&
						activity.endMonth.trim() !== "" &&
						activity.endYear.trim() !== "" &&
						activity.tasks.every((task) => task.trim() !== "")
				);
			default:
				return false;
		}
	};

	const handleTemplateSelect = async (template) => {
		setSelectedTemplate(template);
		try {
			const response = await fetch(template.url);
			// const response = await fetch("templates/TwoColumnResumeTemplate8.docx");
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const arrayBuffer = await response.arrayBuffer();
			setTemplateContent(arrayBuffer);
		} catch (err) {
			console.error("Error fetching template:", err.message);
			setError(`Failed to fetch template: ${err.message}`);
		}
	};

	useEffect(() => {
		generatePreview();
	}, [resumeData, templateContent]);

	if (!resumeCreatorFlag) return <div className={styles.centered}>Feature Not Accessible</div>;

	if (!selectedTemplate) {
		return <TemplateSelector onSelectTemplate={handleTemplateSelect} />;
	}

	return (
		<div className={styles.mainContainer}>
			<div className={styles.container}>
				{error && <div className={styles.error}>{error}</div>}
				<div className={styles.sideNav}>
					{sections.map((section, index) => (
						<button
							type="button"
							key={section}
							className={`${styles.navButton} ${activeSection === section ? styles.activeButton : ""} ${
								isSectionFilled(section) ? styles.filledButton : styles.emptyButton
							}`}
							onClick={() => setActiveSection(section)}
						>
							<span className={styles.stepNumber}>{index + 1}</span>
							{section.charAt(0).toUpperCase() + section.slice(1)}
						</button>
					))}
				</div>
				<div className={styles.viewContainer}>
					<div className={styles.formContainer}>
						<h1 className={styles.title}>Resume Creator</h1>
						<div className={styles.formContent}>
							{activeSection === "contact" && renderContactForm()}
							{activeSection === "summary" && (
								<>
									<textarea
										className={styles.textarea}
										placeholder="Professional Summary"
										value={resumeData.summary}
										onChange={(e) => handleInputChange("summary", null, e.target.value)}
									/>
									<div>
										<button
											type="button"
											className={styles.button}
											label="Rewrite"
											onClick={() => rewriteSection("summary", resumeData.summary)}
										>
											<PiSparkleBold style={{ marginRight: "10px" }} />
											Rewrite
										</button>
										{resumeCreatorRewriteSections.summary && (
											<textarea
												className={styles.rewriteText}
												value={resumeCreatorRewriteSections.summary}
												onChange={(e) =>
													setResumeCreatorRewriteSections({
														...resumeCreatorRewriteSections,
														summary: e.target.value
													})
												}
											/>
										)}
									</div>
								</>
							)}
							{activeSection === "experience" && renderExperienceForm()}
							{activeSection === "skills" && renderSkillsForm()}
							{activeSection === "education" && renderEducationForm()}
							{activeSection === "extraCurricular" && renderExtraCurricularForm()}
						</div>
					</div>
					<div className={styles.previewContainer}>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "10px" }}>
							<h2 className={styles.subtitle}>Preview</h2>

							<h4 className={styles.headingMessage}>Preview may not look 100% accurate until exported.</h4>
							<button type="button" className={styles.button} style={{ marginLeft: "auto", marginTop: "auto" }} onClick={downloadDocx}>
								Download DOCX
							</button>
						</div>

						<div className={styles.resumePreview}>
							<div id="docxPreview" style={{ width: "100%", height: "100%", overflow: "hidden" }}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumeCreator;
